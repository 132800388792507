function createClassesService(http) {
  const create = async (course) => http.post(`/classes`, course);
  const update = async (course) => http.put(`/classes`, course);
  const list = async (start) => http.get(`/classes?limit=${start}`);
  const getById = async (_, id) => {
    const result = await http.get(`/classes?id=${id}`);
    return result.data ? result.data[0] : {};
  };
  const remove = async (id) => http.delete(`/classes?id=${id}`);
  const getByCourseId = async (id) => http.get(`/classes?course=${id}`);
  const getContentById = async (id) => http.get(`/content?class=${id}`);
  const getCommentsById = async (id) => http.get(`/comments?class=${id}`);
  const getMaterialById = async (id) => {
    const { data } = await http.get(`/extra?class=${id}`);

    const materials = {
      files: [],
      videos: [],
    };

    if (data) {
      data.forEach((item) => {
        if (item.file) {
          materials.files.push(item);
        } else {
          materials.videos.push(item);
        }
      });
    }
    return materials;
  };

  const setViewed = async (id) => http.post(`/views`, { id, type: 'class' });
  const setRating = async (id, rating) => http.post(`/ratings`, { class: id, rating });
  const addComment = async (comment) => http.post(`/comments`, comment);
  const addNote = async (note) => http.post(`/notes`, note);

  return {
    create,
    update,
    list,
    getById,
    remove,
    getByCourseId,
    getContentById,
    getCommentsById,
    getMaterialById,
    setViewed,
    setRating,
    addComment,
    addNote,
  };
}

export default createClassesService;
