import React from 'react';
import styled from 'styled-components';

import { Typography } from '~/components';

export const Container = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  min-height: 100vh;
  button {
    cursor: default;
  }
  img {
    max-width: 350px;
  }
`;

export const ErrorMessage = styled.div`
  width: 100%;
  padding: 1.4rem;
  border-radius: 5px;
  background: ${(props) => props.theme.palette.g1};
`;

export default class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true, error });
  }

  onChangeRouter = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Container>
          <div>
            <img src="/img/error-layout.jpg" alt="erro no apollo" />
            <Typography variant="h2" gutterBottom="1rem">
              Ops, algo saiu errado.
            </Typography>
            <Typography variant="body2" gutterBottom="26px">
              Desculpe, mas aconteceu algum erro. Nosso time já foi avisado e
              {' '}
              <br />
              {' '}
              vamos
              investigar. Se persistir, entre em contato com o nosso suporte
              {' '}
              <br />
              {' '}
              e informe o erro
              abaixo.
            </Typography>
            <ErrorMessage>
              <Typography variant="body3" color="g6" gutterBottom=".5rem">
                Detalhes do erro que podem ser úteis:
              </Typography>
              <Typography variant="body2" color="g6">
                {error?.message}
              </Typography>
            </ErrorMessage>
          </div>
        </Container>
      );
    }
    return children;
  }
}
