import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionList = styled.div`
  z-index: 2;

  position: absolute;
  top: calc(100% + 16px);
  right: calc(50% - 50px);

  width: 390px;
  border-radius: 5px;

  background-color: #fff;

  opacity: 0;
  visibility: hidden;

  box-shadow: 0px 2px 8px #00000029;
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;

  ::before {
    position: absolute;
    top: -8px;
    right: 32px;

    content: '';

    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent #ffffff;
  }

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  min-width: 120px;
  padding: 32px;

  cursor: pointer;
  border-radius: 30px;

  border: none;

  color: #000000;

  font-size: 14px;
  font-weight: 500;

  :first-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0.5px solid #e9eff1;
  }

  :not(:last-of-type) {
    margin-bottom: 14px;
  }

  span {
    text-align: center;
  }

  .flex-column {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }
`;
