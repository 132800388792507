export default function getCroppedImg(image, crop, format = 'image/jpeg', fileName) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = Math.ceil(crop.width * scaleX);
  canvas.height = Math.ceil(crop.height * scaleY);
  const ctx = canvas.getContext('2d');
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY,
  );
  return new Promise((resolve) => {
    canvas.toBlob(blob => {
      blob.name = fileName;
      resolve(blob);
    }, format, 1);
  });
}
