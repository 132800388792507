import styled from 'styled-components';

const Item = styled.div`
  margin-bottom: 16px;
  z-index: ${props => props.forward ? 1000002 : 1};
  @media (max-width: 768px) {
    margin: 8px 12px;
  }
`;
export default Item;
