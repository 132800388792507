import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '~/services/http';

const TYPE = 'classes';

export const createClass = createAsyncThunk(`${TYPE}/create`, async (data) => {
  const response = await api.post('/classes', { ...data });
  toast('Aula criada!', {
    autoClose: 3000,
    type: 'info',
  });
  return { ...data, id: response.id, born: new Date() };
});

export const fetchClasses = createAsyncThunk(`${TYPE}/fetch`, async (course) => {
  const response = await api.get(`/classes?course=${course}`);
  return response.data;
});

export const deleteClass = createAsyncThunk(`${TYPE}/delete`, async (id) => {
  const response = await api.delete(`/classes?id=${id}`);
  return response;
});

export const updateClass = createAsyncThunk(`${TYPE}/update`, async (data) => {
  const response = await api.put(`/classes`, { ...data });
  toast('Aula atualizada!', {
    autoClose: 3000,
    type: 'info',
  });
  return { ...data, id: response.id };
});

export const reorderClasses = createAsyncThunk(`${TYPE}/reorderClasses`, async (data) => {
  await api.post(`/massive`, {
    course: data.course,
    classes: data.order
  });
  return data;
});

const classesAdapter = createEntityAdapter({
  selectId: (c) => c.id,
  sortComparer: (a, b) => a.position - b.position,
});

export const classesSelector = classesAdapter.getSelectors((state) => state.classes);

function updateClassInState(state, payload) {
  let toUpdate = {...state.entities[payload.id]};
      const { properties, tags, ...rest } = payload;
      if (properties) {
        toUpdate.properties = { ...toUpdate.properties, ...properties };
      }
      if (tags) {
        toUpdate.tags = tags.filter((t) => Boolean(t.active));
      }
      toUpdate = { ...toUpdate, ...rest };
      state.entities[payload.id] = toUpdate;
}

const classSlice = createSlice({
  name: TYPE,
  initialState: classesAdapter.getInitialState({ loading: false }),
  reducers: {
    setClass: (state, { payload }) => {
      updateClassInState(state, payload);
    },
  },
  extraReducers: {
    [createClass.fulfilled]: (state, action) => {
      classesAdapter.addOne(state, action.payload);
    },
    [fetchClasses.fulfilled]: (state, action) => {
      classesAdapter.setAll(state, action.payload || []);
      state.loading = false;
    },
    [fetchClasses.rejected]: (state, { error }) => {
      if(error.message === "no data found") {
        classesAdapter.setAll(state, []);
      }
      state.loading = false;
    },
    [fetchClasses.pending]: (state) => {
      state.loading = true;
    },
    [updateClass.fulfilled]: (state, { payload }) => {
      updateClassInState(state, payload);
    },
    [reorderClasses.fulfilled]: (state, action) => {
      action.payload.order.forEach((id, index) => {
        state.entities[id].position = index + 1;
      });
      state.loading = false;
    },
    [deleteClass.fulfilled]: (state, action) => {
      classesAdapter.removeOne(state, action.payload.id);
      state.loading = false;
    },
  },
});

export default classSlice;
