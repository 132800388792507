import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  z-index: 999;
  height: 100vh;
  padding: 0 8px;
  transition: all 0.2s ease-in-out;
  display: flex;
  order: 1;
  flex-flow: column;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 74px;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.primary};
  align-items: center;
  .sidenav__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sidenav__content div {
    display: flex;
    flex-direction: column;
  }
  .sidenav__brand {
    width: 46px;
    margin: 26px 0;
    cursor: pointer;
  }
  .show__md {
    display: none !important;
  }
  @media (min-width: 768px) {
    .hide__md {
      display: none;
    }
    display: ${props => props.hiddenMd ? 'none' : 'flex'};
  }
  @media (max-width: 768px) {
    .show__md {
      display: flex !important;
    }
    position: fixed;
    top: auto;
    bottom: 0;
    height: auto;
    padding: 0;
    width: 100%;
    flex-flow: row;
    .sidenav__content {
      flex-flow: row;
      overflow: auto;
      background-color: rgba(0, 0, 0, 0.1);
      & > div {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .sidenav__brand,
      .hide__sm {
        display: none;
      }
    }
    .sidenav__brand {
      display: none;
    }
  }
`;

export default ({ children, brand, ...props }) => {
  return (
    <Container {...props}>
      {brand && <div className="sidenav__brand">{brand}</div>}
      <div className="sidenav__content">{children}</div>
    </Container>
  );
};
