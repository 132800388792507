import React from 'react'
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  user-select: none;
  width: 200px;
  height: 115px;
  color: ${(props) => props.theme.palette.g3};
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 3px solid ${(props) => props.theme.palette.g3};
  font-size: 14px;
  span {
    margin: 8px;
  }
  &:hover {
    color: ${(props) => props.theme.palette.primary};
    border: 3px solid ${(props) => props.theme.palette.primary};
  }
  ${(props) =>
   props.active &&
    css`
      color: ${props.theme.palette.primary};
      border: 3px solid ${props.theme.palette.primary};
    `}
`;

export default function CardButton({icon, children, ...props}) {
  return (
    <Container {...props}>
      <FontAwesomeIcon icon={icon} size="3x" />
      <span>{children}</span>
    </Container>
  )
}
