import React, { useState, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import api from '~/services/http';
import { formatDate, formatTime, formatContent } from '~/utils/format';

import { Container } from './styles';

export default function CreateAnnotationForm({ classId, playedSeconds, setNotes }) {
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      setLoading(true);

      const response = await api.post('notes', {
        class: classId,
        text: note,
        seconds: playedSeconds,
      });

      const newNote = {
        id: response.id,
        class: classId,
        seconds: playedSeconds,
        text: note,
        born: new Date(),
        text_formatted: formatContent(note),
        time_at: formatTime(playedSeconds),
        created_at: formatDate(new Date()),
      };

      setNote('');
      setNotes((prevNotes) => prevNotes.concat([newNote]));
      setLoading(false);
    },
    [note, classId, playedSeconds, setNotes],
  );

  return (
    <Container onSubmit={handleSubmit}>
      <div>
        <TextareaAutosize
          value={note}
          onChange={(event) => setNote(event.target.value)}
          placeholder="Anote aquilo que é importante nessa aula..."
        />
      </div>

      <div>
        <span>
          <FontAwesomeIcon icon={faLockAlt} />
          Anotações são privadas, apenas você pode ler.
        </span>

        <button type="submit" disabled={!note || loading}>
          {loading ? 'Anotando..' : 'Anotar'}
        </button>
      </div>
    </Container>
  );
}
