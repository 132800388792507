import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import api from '~/services/http';

export const fetchComments = createAsyncThunk('comments/fetchAll', async (options) => {
  const { classroom_id, page } = options;

  const response = await api.get(`classes/${classroom_id}/comments?page=${page}&sort_by=desc`);

  return response.data;
});

export const createComment = createAsyncThunk('comments/create', async (data) => {
  const { classroom_id } = data;

  const response = await api.post(`classes/${classroom_id}/comments`, data);

  return { id: response.id };
});

export default createSlice({
  name: 'comments',
  initialState: {
    entities: [],
    loading: true,
  },
  extraReducers: {
    [fetchComments.fulfilled]: (state, action) => {
      state.entities = [...state.entities, ...action.payload];
      state.loading = false;
    },
  },
});
