import React from 'react'
import styled, { css } from 'styled-components';

import Typography from '../Typography';

const LabelInfo = styled.div`
  .label {
    color: ${({ theme }) => theme.palette.g5};
    margin-bottom: 4px;
  }

  ${props => props.onClick && css`
    cursor: pointer;
  `}

  ${props => props.hasBorder && css`
    padding: 12px;
    border-radius: 5px;
    border: 1px solid ${props.theme.palette.primary};
  `}

  ${props => props.borderOnlyXs && css `
    @media (min-width: 768px){
      border: none;
      padding: 0px;
    }
  `}

  @media (max-width: 767px){
    svg{
      font-size: 15px;
    }
    p{
      &:first-of-type{
        margin-bottom: 8px;
      }
      &:last-of-type{
        display: grid;
        font-size: 14px;
        margin-bottom: 0px;
        grid-template-columns: 25px calc(100% - 25px);
      }
    }
  }
`;

const Icon = styled.span`
  padding-right: 8px;
`;

export default ({ children, label, icon, infoColor, variant = 'body1',...props }) => {
  return (
    <LabelInfo {...props}>
      <Typography variant="body3" className="label">{label}</Typography>
      <Typography variant={variant} color={infoColor} gutterBottom="16px">
        { icon &&
          <Icon>{icon}</Icon>}
        <span>{children}</span>
      </Typography>
    </LabelInfo>
  )
}
