import React from 'react'
import styled, { css } from 'styled-components';

const NavBar = styled.div`
  width: 100%;
  z-index: 999  ;
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: ${props => props.padding || '0 26px'};
  background: #fff;
  box-shadow: 0px 1px 1px #0000001A;
  .is-back {
    padding: 8px 16px;

    background-color: transparent;

    transition: background 0.2s ease 0s, color 0.2s ease 0s, box-shadow 0.2s ease 0s;

    :hover {
      opacity: 0.8;

      cursor: pointer;
    }

    &.is-back {
      padding: 0;
    }
  }
  ${props => props.fixed && css`
    top: 0;
    left: 0;
  `}
  ${props => props.fixedBottom && css`
    position: fixed;
    top: initial;
    bottom: 0;
    box-shadow: 0px 0px 10px #0000001a;
  `}
  ${props => props.noShadow && css`
    box-shadow: none;
  `}
  ${props => props.noHeight && css`
    height: auto;
  `}
  @media (max-width: 768px){
    padding: ${props => props.paddingXs || '23px 20px'};
  }
`;

export default ({children, fixed = false , fixedBottom = false, noShadow,...props}) => {
  return (
    <NavBar fixed={fixed} fixedBottom={fixedBottom} noShadow={noShadow} {...props}>
      {children}
    </NavBar>
  )
}
