import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import api from '../../services/http';

import { defaultSchool } from '~/constants/school';
import { parseChartData, groupProperties, getSiteUrl } from '~/utils';

const TYPE = 'school';

export const fetchSchool = createAsyncThunk(`${TYPE}/fetch`, async () => {
  const response = await api.get(`/schools?domain=${getSiteUrl()}`);
  if (response.properties.color_primary) {
    localStorage.setItem(
      '@APOLLO:THEME_COLOR',
      JSON.stringify({
        primary: response.properties.color_primary,
        secondary: response.properties.color_secondary,
      }),
    );
  }

  if (response.properties.brand_menu) {
    localStorage.setItem(
      '@APOLLO:THEME_BRAND',
      JSON.stringify({
        default: response.properties.brand_default,
        login: response.properties.brand_login,
        menu: response.properties.brand_menu,
        mono: response.properties.brand_mono,
      }),
    );
  }

  return response;
});

export const updateSchool = createAsyncThunk(`${TYPE}/update`, async (school) => {
  await api.put(`/schools`, school);
  return school;
});

export const fetchMetrics = createAsyncThunk(`${TYPE}/fetchMetrics`, async () => {
  const { metrics } = await api.get(`/metrics`);
  return {
    ...metrics,
    chart_churn: parseChartData(metrics.chart_churn, 'users'),
    chart_users: parseChartData(metrics.chart_users, 'users'),
    chart_comments: parseChartData(metrics.chart_comments, 'comments'),
  };
});

export const metrics = {
  nps: 0,
  total_users: 0,
  rating_votes: 0,
  rating_average: 0,
  rating_passives: 0,
  total_graduated: 0,
  rating_promoters: 0,
  total_enabled: 0,
  total_comments: 0,
  total_disabled: 0,
  rating_detractors: 0,
  percentual_churn: 0,
  percentual_promoters: 0,
  percentual_detractors: 0,
  percentual_graduated: 0,
  total_notes: 0,
  chart_churn: {
    labels: [],
    data: [],
  },
  chart_users: {
    labels: [],
    data: [],
  },
  chart_comments: {
    labels: [],
    data: [],
  },
};

function setSchoolApl(apl) {
  localStorage.setItem('apl', apl);
  window.apl_install = apl;
}

const slice = createSlice({
  name: TYPE,
  initialState: { data: defaultSchool, metrics, loading: true },
  reducers: {
    updateTheme: (state, { payload }) => {
      state.data.color.primary = payload.primary;
    },
  },
  extraReducers: {
    [fetchSchool.fulfilled]: (state, action) => {
      setSchoolApl(action.payload.apl || action.payload.data.apl_install);
      state.loading = false;
      state.data = {
        id: action.payload.id,
        title: action.payload.title,
        ...action.payload.data,
        ...defaultSchool,
        ...action.payload.properties,
        ...groupProperties(action.payload.properties),
      };
    },
    [fetchMetrics.fulfilled]: (state, action) => {
      state.metrics = action.payload;
    },
  },
});

export default slice;
