import styled, { css } from 'styled-components';

import { isTooLight } from '~/utils/checkTheme';

const modifiers = {
  xs: css`
    height: 1.8rem;
    min-width: 90px;

    font-size: 13px;
  `,

  sm: css`
    height: 1.8rem;
    min-width: 90px;

    font-size: 14px;
  `,

  md: css``,
};

export const Container = styled.button`
  ${({ theme, block, size, color, shadow, contrast, outline, loading, disabled, unstyled }) => css`
    min-width: 120px;

    height: 2.8rem;
    padding: 0 1rem;

    border-radius: 3px;
    border-radius: ${theme.shape.borderRadius}px;

    outline: none;
    line-height: inherit;

    color: ${color === 'white' ? '#78909C' : '#fff'};
    background-color: ${theme.palette[color] || color || theme.palette[color || 'primary']};

    font-size: inherit;
    text-decoration: none;

    transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;

    cursor: pointer;
    box-shadow: 0 0 3px #00000021;

    ${block &&
    css`
      width: 100%;
    `}

    ${disabled &&
    css`
      filter: brightness(0.9)

      cursor: not-allowed;
    `}

    ${contrast &&
    css`
      color: ${isTooLight(color || theme.palette[color || 'primary']) ? '#000' : '#fff'};
    `}

    ${outline &&
    css`
      border: 1px solid ${theme.palette[color] || color || theme.palette[color || 'primary']};

      color: ${theme.palette[color] || (color === 'white' ? '#78909C' : color)};
      background-color: transparent;
    `}

    ${shadow &&
    css`
      box-shadow: 0 2px 6px #00000021;
    `}

    ${unstyled &&
    css`
      width: initial;
      min-width: initial;
      height: initial;
      padding: initial;
      box-shadow: none;
      background: transparent;
    `}

    ${size && modifiers[size || 'md']}

    ${loading &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}

    @media (max-width: 360px) {
      font-size: 11px;
    }
  `}
`;
