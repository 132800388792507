import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import SuperRoutes from './super.routes';

import { useAuth } from '../contexts/auth';
import useAdmin from '~/hooks/useAuth';

import Analytics from './Analytics';
import ScrollToTop from './ScrollToTop';

const Routes = () => {
  const { signed } = useAuth();
  const { user } = useAdmin();

  function renderRoutes() {
    if (signed && !user) {
      return <AppRoutes />;
    } if (!signed && user) {
      return <SuperRoutes />;
    } 
      return <AuthRoutes />;
    
  }

  return (
    <BrowserRouter>
      <Analytics />
      <ScrollToTop />
      {renderRoutes()}
    </BrowserRouter>
  );
};

export default Routes;
