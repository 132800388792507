import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: flex;
    justify-content: center;

    min-width: 100px;
    padding: 6px 8px;

    outline: none;

    cursor: pointer;
    border-radius: 30px;

    border: none;

    color: ${(props) => props.color};
    background: ${(props) => transparentize(0.8, props.color)};

    :hover {
      background: ${(props) => transparentize(0.6, props.color)};
    }

    font-size: 12px;
    font-weight: 600;


    @media (min-width: 1500px) {
      min-width: 120px;

      font-size: 14px;
    }

    svg {
      color: #cccccc;
      transition: fill 0.2s ease 0s;
    }
  }
`;

export const OptionList = styled.div`
  z-index: 2;

  position: absolute;
  top: calc(100% + 16px);
  right: calc(50% - 50px);

  width: 150px;
  padding: 15px;
  border-radius: 5px;

  background: #fff;

  opacity: 0;
  visibility: hidden;

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;

  ::before {
    position: absolute;
    top: -8px;
    right: 32px;

    content: '';

    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent #ffffff;
  }

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  ${(props) =>
    props.isDropup &&
    css`
      top: unset;
      bottom: calc(100% + 16px);

      ::before {
        top: unset;
        bottom: -8px;
        transform: rotate(180deg);
      }
    `}
`;

export const Option = styled.div`
  display: flex;
  align-items: center;

  min-width: 120px;
  padding: 4px 8px;

  cursor: pointer;
  border-radius: 30px;

  border: none;

  color: ${(props) => props.color};
  background: ${(props) => transparentize(0.8, props.color)};

  font-size: 14px;
  font-weight: 600;

  :hover {
    background: ${(props) => transparentize(0.6, props.color)};
  }

  :not(:last-of-type) {
    margin-bottom: 14px;
  }

  span {
    width: 100%;

    text-align: center;
  }
`;
