import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: ${props => props.theme.pxToRem(42 * props.size)};
  width: ${props => props.theme.pxToRem(32 * props.size)};
  color: #fff;
  background-color: ${props => props.color};
  border-radius: 2px;
  span:before,
  span:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    border-color: transparent;
    border-style: solid;
    border-radius: 2px;
  }
  span:after {
    border-width: 8px;
    border-right-color: #fff;
    border-top-color: #fff;
  }
  .fileicon_text {
    font-size: ${props => props.theme.pxToRem(10 * props.size)};
    margin-bottom: 4px;
  }
`;

export default function FileIcon({file = '', size = 1,...props}) {
  const icon = String(file).split(".").reverse()[0]
  const colors = {
    pdf: '#E57373',
    docx: '#42A5F5',
    xls: '#81C784',
    mp3: '#7C4DFF'
  }
  return (
    <Container color={colors[icon] || '#78909C'} size={size} {...props}>
      <span />
      <div className="fileicon_text">
        .
        {icon.toUpperCase()}
      </div>
    </Container>
  );
}
