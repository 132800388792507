import React, { useState, useLayoutEffect, Suspense } from 'react';

import { hotjar } from 'react-hotjar';
import jwt_decode from "jwt-decode";
import PageLoading from '../components/PageLoading';
import { useAuth } from '../contexts/auth';

import loadBeacon from '~/utils/loadBeacon';

const StudentRoutes = React.lazy(() => import('./student.routes'));
const AdminRoutes = React.lazy(() => import('./admin.routes'));

export default function AppRoutes() {
  const [didLoad, setDidLoad] = useState(false);

  const { token } = useAuth();


  const userDecoded = jwt_decode(token);
  useLayoutEffect(() => {
    if (!didLoad && !userDecoded.student && process.env.NODE_ENV === 'production') {
      setDidLoad(true);
      loadBeacon();
    }
  }, [didLoad, userDecoded.student]);

  if (process.env.NODE_ENV === 'production') {
    if (!userDecoded.student) {
      hotjar.initialize(2076183, 6);
    }

    if (userDecoded.student) {
      hotjar.initialize(2253224, 6);
    }
  }

  return (
    <Suspense fallback={<PageLoading />}>
      {userDecoded.student === true ? <StudentRoutes /> : <AdminRoutes />}
    </Suspense>
  );
}
