import React, { useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

import { Container } from './styles';

const SortableColumnHeader = ({ title, onOrderChange, initialOrder }) => {
  const handleButtonClick = useCallback(() => {
    const sortOrder = initialOrder === 'asc' ? 'desc' : 'asc';

    onOrderChange(sortOrder);
  }, [initialOrder, onOrderChange]);

  return (
    <Container>
      <button type="button" onClick={handleButtonClick}>
        {title} 
        {' '}
        <FontAwesomeIcon icon={faSort} />
      </button>
    </Container>
  );
};

export default SortableColumnHeader;
