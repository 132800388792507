import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Form as UnForm } from '@unform/web';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { faTint, faCog } from '@fortawesome/pro-regular-svg-icons';

import { useAuth } from '~/contexts/auth';
import { useStyle } from '~/contexts/signIn';
import { updateObjectProperty } from '~/utils';

import Upload from './UploadOption';
import Navbar from './EditableNavbar';
import Editable from './EditableOptions';
import Promotion from './Promotion';

import Brand from '~/layouts/Brand';
import { Can, Switch, Colors, Input, Button, Typography, PageLoading } from '~/components';

import {
  Container,
  Column,
  Options,
  Option,
  BrandContainer,
  FormContainer,
  Header,
  Footer,
} from './styles';

export default function SignIn() {
  const [editable, setEditable] = useState(window.location.pathname === '/school/settings/signin');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { signIn, error, errorData } = useAuth();
  const { school, styles, setStyles } = useStyle();

  async function handleSubmit() {
    signIn({ school: school.id, mail: email, pwd: password });
  }

  function handleBackgroundColorChange(color) {
    const newStyles = updateObjectProperty(styles.leftColumn, 'styles.bgColor', color);

    setStyles({ type: 'LEFT_COLUMN_STYLES', payload: newStyles });
  }

  function handleBackgroundImageChange(image) {
    const newStyles = updateObjectProperty(styles.leftColumn, 'styles.bgImage', image);

    setStyles({ type: 'LEFT_COLUMN_STYLES', payload: newStyles });
  }

  function handleBackgroundFileChange(file) {
    const newStyles = updateObjectProperty(styles.leftColumn, 'styles.bgFile', file);

    setStyles({ type: 'LEFT_COLUMN_STYLES', payload: newStyles });
  }

  function handleBrandColorChange(color) {
    const newStyles = updateObjectProperty(styles.leftColumn.logo, 'styles.fontColor', color);

    setStyles({ type: 'LEFT_COLUMN_LOGO_STYLES', payload: newStyles });
  }

  function handleBrandBackgroundImageChange(image) {
    const newStyles = updateObjectProperty(styles.leftColumn.logo, 'styles.bgImage', image);

    setStyles({ type: 'LEFT_COLUMN_LOGO_STYLES', payload: newStyles });
  }

  function handleBrandBackgroundFileChange(file) {
    const newStyles = updateObjectProperty(styles.leftColumn.logo, 'styles.bgFile', file);

    setStyles({ type: 'LEFT_COLUMN_LOGO_STYLES', payload: newStyles });
  }

  function handleTextareaChange(event) {
    const { name, value } = event.target;
    const newStyles = updateObjectProperty(styles.leftColumn.form, name, value);

    setStyles({ type: 'LEFT_COLUMN_FORM_STYLES', payload: newStyles });
  }

  function handleFormColorChange(color) {
    const newStyles = updateObjectProperty(styles.leftColumn.form, 'styles.fontColor', color);

    setStyles({ type: 'LEFT_COLUMN_FORM_STYLES', payload: newStyles });
  }

  function handleFormBackgroundColorChange(color) {
    const newStyles = updateObjectProperty(styles.leftColumn.form, 'styles.bgColor', color);

    setStyles({ type: 'LEFT_COLUMN_FORM_STYLES', payload: newStyles });
  }

  function handleFormShadowChange(visibility) {
    const newStyles = updateObjectProperty(styles.leftColumn.form, 'styles.shadow', visibility);

    setStyles({ type: 'LEFT_COLUMN_FORM_STYLES', payload: newStyles });
  }

  function handleFormPaddingChange(visibility) {
    const newStyles = updateObjectProperty(styles.leftColumn.form, 'styles.padding', visibility);

    setStyles({ type: 'LEFT_COLUMN_FORM_STYLES', payload: newStyles });
  }

  if (styles.loading) {
    return <PageLoading />;
  }

  return (
    <UnForm
      onSubmit={window.location.pathname === '/school/settings/signin' ? console.log : handleSubmit}
    >
      <Can renderable={editable}>
        <Navbar setEditable={setEditable} />
      </Can>

      <Container
        columns={styles?.rightColumn.visible ? '1fr 1fr' : '1fr'}
        styles={styles?.leftColumn.styles}
      >
        <Column styles={styles?.leftColumn.styles}>
          <Can renderable={editable}>
            <Options style={{ top: 80, right: 24 }}>
              <Editable icon={faTint}>
                <Colors
                  label="Fundo"
                  name="background"
                  color={styles.leftColumn.styles.bgColor}
                  onSelect={handleBackgroundColorChange}
                  disableUseEffect
                  required
                />
              </Editable>

              <Upload
                onChange={handleBackgroundFileChange}
                onPreviewChange={handleBackgroundImageChange}
                defaultValue={styles.leftColumn.styles.bgImage}
                style={{ marginLeft: 8 }}
              />
            </Options>
          </Can>

          <BrandContainer styles={styles?.leftColumn.logo.styles}>
            <Can renderable={!!styles?.leftColumn.logo.styles.bgImage}>
              <img src={styles?.leftColumn.logo.styles.bgImage} loading="lazy" alt="logo" />
            </Can>

            <Can renderable={!styles?.leftColumn.logo.styles.bgImage}>
              <Brand color={styles?.leftColumn.logo.styles.fontColor} />
            </Can>

            <Can renderable={editable}>
              <Options style={{ right: -96 }}>
                <Editable icon={faTint}>
                  <Colors
                    name="brand.color"
                    color={styles?.leftColumn.logo.styles.fontColor}
                    label="Texto"
                    onSelect={handleBrandColorChange}
                    disableUseEffect
                    required
                  />
                </Editable>

                <Upload
                  onChange={handleBrandBackgroundFileChange}
                  onPreviewChange={handleBrandBackgroundImageChange}
                  defaultValue={styles?.leftColumn.logo.styles.bgImage}
                  style={{ marginLeft: 8 }}
                />
              </Options>
            </Can>
          </BrandContainer>

          <FormContainer styles={styles?.leftColumn.form.styles}>
            <Header styles={styles?.leftColumn.form.styles}>
              <Can renderable={!editable}>
                <>
                  <h1>{styles?.leftColumn.form.title}</h1>
                  <p>{styles?.leftColumn.form.subtitle}</p>
                </>
              </Can>

              <Can renderable={editable}>
                <div style={{ marginLeft: -8, marginRight: -8 }}>
                  <TextareaAutosize
                    name="title"
                    onChange={handleTextareaChange}
                    defaultValue={styles?.leftColumn.form.title}
                    className="school-textarea"
                    placeholder="Título"
                  />

                  <TextareaAutosize
                    name="subtitle"
                    onChange={handleTextareaChange}
                    defaultValue={styles?.leftColumn.form.subtitle}
                    className="school-textarea school-input"
                    placeholder="Descrição"
                  />
                </div>
              </Can>
            </Header>

            <Input
              type="email"
              name="email"
              label="E-mail"
              onChange={(event) => setEmail(event.target.value)}
              required
              fullWidth
            />

            <Input
              type="password"
              name="password"
              label="Senha"
              onChange={(event) => setPassword(event.target.value)}
              required
              fullWidth
            />

            <div className="login__error">
              <Typography variant="body3" color="error">
                {error && errorData.msg}
              </Typography>
            </div>

            <Button type="submit" color={styles?.leftColumn.form.styles.fontColor} contrast block>
              Acessar
            </Button>

            <Footer styles={styles?.leftColumn.form.styles}>
              <Link to="/password">Esqueci a senha</Link>

              <Can renderable={styles?.leftColumn.form.anchor.visible}>
                <a href={styles?.leftColumn.form.anchor.content} target="_blank" rel="noreferrer">
                  Ainda não sou aluno
                </a>
              </Can>
            </Footer>

            <Can renderable={editable}>
              <Options>
                <Editable icon={faCog}>
                  <Colors
                    name="form.background"
                    color={styles?.leftColumn.form.styles.bgColor}
                    label="Fundo"
                    onSelect={handleFormBackgroundColorChange}
                    disableUseEffect
                    required
                  />

                  <Colors
                    name="form.color"
                    color={styles?.leftColumn.form.styles.fontColor}
                    label="Texto"
                    onSelect={handleFormColorChange}
                    disableUseEffect
                    required
                  />

                  <Option>
                    <span>Sombra</span>

                    <Switch
                      checked={styles?.leftColumn.form.styles.shadow}
                      onChange={handleFormShadowChange}
                    />
                  </Option>

                  <Option>
                    <span>Margem</span>

                    <Switch
                      checked={styles?.leftColumn.form.styles.padding}
                      onChange={handleFormPaddingChange}
                    />
                  </Option>
                </Editable>
              </Options>
            </Can>
          </FormContainer>
        </Column>

        <Can renderable={styles?.rightColumn.visible}>
          <Promotion editable={editable} />
        </Can>
      </Container>
    </UnForm>
  );
}
