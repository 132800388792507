import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useField } from '@unform/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export const TextAreaWarraper = styled.div`
  width: 100%;
  position: relative;
`;

export const TextAreaInput = styled(TextareaAutosize)`
  width: 100%;
  padding: 0px;
  font-size: ${props => props.size}px;
  resize: none;
  border: 1px solid #b0bec5;
  border-radius: 4px;
  padding: 8px 0;
  margin-bottom: 16px;
  outline: none;
  background: transparent;
  ${(props) =>
    !props.noBorder &&
    css`
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #ECEFF1;
    `}
    ${(props) =>
      props.simple &&
      css`
        color: #778A96;
        border-bottom: none;
      `}
`;

export const TextAreaBottom = styled.div`
  font-size: 12px;
  position: absolute;
  right: 16px;
  bottom: 36px;
  span {
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary};
  }
`;

export default function TextAreaComponent({ textBottom, ...props }) {
  return (
    <TextAreaWarraper>
      <TextAreaInput {...props} />
      <TextAreaBottom>{textBottom}</TextAreaBottom>
    </TextAreaWarraper>
  );
}

export function TextArea({ name, size = 16, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue = "", registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value"
    });
  }, [fieldName, registerField]);
  return (
    <TextAreaInput ref={inputRef} size={size} defaultValue={defaultValue} {...rest} />
  );
}
