import React, { createContext, useCallback, useState } from 'react';

import api from '../services/api';
import apollo from '../services/http/apollo.api'

export const AuthContext = createContext({});

export const AdminProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@Apollo:v2:token');
    const user = localStorage.getItem('@Apollo:v2:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      apollo.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async (credentials) => {
    const response = await api.post('sessions', credentials);

    const { token, user } = response.data;

    localStorage.setItem('@Apollo:v2:token', token);
    localStorage.setItem('@Apollo:v2:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;
    apollo.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Apollo:v2:token');
    localStorage.removeItem('@Apollo:v2:user');

    setData({});
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@Apollo:v2:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};
