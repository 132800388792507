import styled from 'styled-components';

export const Container = styled.div`
  input {
    display: none;
  }
`;

export const Button = styled.button`
  width: 40px;
  height: 40px;

  background-color: #ffffff;
  border-radius: 4px;

  box-shadow: 0 1px 6px rgb(0 0 0 / 10%);

  & + button {
    margin-left: 8px;
  }

  svg {
    color: #78909c;
    font-size: 16px;
  }

  :hover {
    cursor: pointer;

    svg {
      opacity: 0.8;
    }
  }
`;
