import React from 'react';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import getCroppedImg from './cropImage';
import 'react-image-crop/dist/ReactCrop.css';

import ModalBase from '../Modal';
import Button from '../Button';

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 300px;
  margin-bottom: 16px;
  background: ${(props) => props.theme.palette.g1};
  text-align: center;
  img {
    max-height: 70vh;
  }
`;

const Modal = styled(ModalBase)``;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function ImageCrop({ image = null, format, show, aspect, name, onCrop, onCancel }) {
  const imgRef = React.useRef(null);
  const [crop, setCrop] = React.useState({ aspect, unit: '%', width: 50, height: 50 });

  const handleShowCroppedImage = React.useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const croppedImage = await getCroppedImg(imgRef.current, crop, format, name);
        onCrop(croppedImage, imgRef.current.src);
      } catch (error) {
        console.error(error);
      }
    },
    [format, onCrop, crop],
  );

  const onLoad = React.useCallback(
    (img) => {
      if (aspect === 1) {
        setCrop({ aspect, unit: 'px', height: img.height / aspect / 2, x: 0, y: 0 });
      }
      imgRef.current = img;
      return false;
    },
    [crop, aspect, imgRef, setCrop],
  );
  if (typeof name === 'string') {
    const teste = name.split('.');
    if (
      teste[teste.length - 1] !== 'jpeg' &&
      teste[teste.length - 1] !== 'jpg' &&
      teste[teste.length - 1] !== 'png'
    ) {
      return (
        <Modal
          title="Arquivo não suportado. Por favor selecione uma imagem"
          show={show}
          onClose={onCancel}
        >
          <Button onClick={onCancel} color="error" size="sm">
            Voltar
          </Button>
        </Modal>
      );
    }
  }
  return (
    <Modal title="Ajustar imagem" show={show} onClose={onCancel}>
      <Container>
        <ReactCrop src={image} crop={crop} onChange={(c) => setCrop(c)} onImageLoaded={onLoad} />
      </Container>
      <Actions>
        <Button onClick={onCancel} color="error" size="sm">
          Cancelar
        </Button>
        <Button onClick={handleShowCroppedImage} size="sm">
          Salvar
        </Button>
      </Actions>
    </Modal>
  );
}
