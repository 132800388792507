import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'apollo-datav2.0.2',
      storage,
      whitelist: [ 'courses', 'modules', 'classes', 'school', 'class', 'ui', 'tags', 'course', 'classroom', 'comments'],
    },
    reducers
  );

  return persistedReducer;
};
