/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Typography from '../Typography';
import CourseCover from '../CourseCover';
import Rating from '../Rating';
import MenuContext from '../MenuContext';

import dayjs from '~/utils/day';

const colorBg = 'white';
const colorBorder = '#CFD8DC';
const colorBgHeader = '#F5F7F8';
const mainPadding = 28;

const Container = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  height: 100%;
  border: 1px solid ${colorBorder};
  background-color: ${colorBg};
  &:hover {
    border-color: #b6c1c5;
  }
  &:before {
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    content: ' ';
    position: absolute;
    background-color: ${(props) => props.color};
  }
  .overview-menu {
    z-index: 50;
    top: 10px;
    right: 10px;
    position: absolute;
    .menu-context {
      z-index: 200;
      position: absolute;
      top: 20px;
      right: 20px;
      .MuiButton-text {
        padding: 0px;
        min-width: initial;
      }
    }
  }
  .overview-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
  }
  .overview-header {
    display: flex;
    flex-wrap: wrap;
    padding: ${mainPadding}px;
    background-color: ${colorBgHeader};
    cursor: pointer;
    .overview-header-cover {
      min-width: 100px;
      > div {
        position: absolute;
        width: 80px;
        .course-cover {
          max-width: 100%;
          height: 107px;
          h3 {
            font-size: 11px;
            line-height: 14px;
          }
        }
      }
    }
    .overview-header-infos {
      width: calc(100% - 125px);
      h3 {
        color: #37474f;
        margin-bottom: 10px;
      }
      p {
        color: #37474f;
        font-size: 12px;
      }
    }
  }
  .overview-content {
    cursor: pointer;
    padding: ${mainPadding}px;
    .overview-content-stats {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 5px;
      article {
        flex: 1;
        padding-left: 25px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-right: 1px solid ${colorBgHeader};
        &:first-of-type {
          padding-left: 0px;
        }
        p {
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
  }
  .overview-footer {
  }

  @media (max-width: 320px) {
    .overview-header{
      padding: 10px 28px;
      .overview-header-cover{
        display: none;
      }
    }
    .overview-menu{
      .menu-context{
        top: 6px;
        right: 6px;
      }
    }
    .overview-content{
      .overview-content-stats{
        article{
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
    }
  }
  @media (min-width: 321px) and (max-width: 360px) {}
  @media (min-width: 361px) and (max-width: 768px) {}

`;

const CardFooter = styled.div`
  width: 100%;
  display: flex;
  padding: ${mainPadding}px;
  justify-content: space-between;
`;

const Icon = styled.div`
  display: grid;
  place-items: center;
  color: ${(props) => props.theme.palette[props.color]};
  width: 36px;
  height: 36px;
  background: ${(props) => transparentize(0.9, props.theme.palette[props.color])};
  border-radius: 50%;
`;

export default function CardCourseOverview({ course, onRemove,...props }) {
  const history = useHistory();

  const handleClickCover = (id) => {
    history.push(`/course/${id}`);
  };

  const options = [
    {
      label: 'Editar',
      function: () => {
        history.push(`/course/${course.id}/settings/main`);
      },
    },
    {
      label: 'Editar conteúdo',
      function: () => {
        history.push(`/course/${course.id}/content/main`);
      },
    },
    {
      label: 'Excluir',
      function: () => {
        onRemove()
      },
    },
  ];

  return (
    <Container color={course.properties?.color_primary} {...props}>
      <div className="overview-menu">
        <MenuContext label="" icon options={options} />
      </div>
      <div
        className="overview-cover"
        onClick={() => {
          handleClickCover(course.id);
        }}
      />
      <div className="overview-header">
        <div className="overview-header-cover">
          <CourseCover
            title={course.name}
            color={course.properties?.color_primary}
            coverImage={course.properties?.cover || course.properties?.brand_mono}
            coverPosition={course.properties?.cover_position}
          />
        </div>
        <div className="overview-header-infos">
          <Typography variant="h3">{course.name}</Typography>
          <Typography variant="body1">Criado {dayjs(course.born).from()}.</Typography>
        </div>
      </div>
      <div className="overview-content">
        <div className="overview-content-stats">
          <article>
            <Typography variant="h3">{course.users || 0}</Typography>
            <Typography variant="body1">Alunos</Typography>
          </article>
          {/* <article>
            <Typography variant="h3">{course.comments}</Typography>
            <Typography variant="body1">Comentários</Typography>
          </article> */}
          <article>
            <Typography variant="h3">{course.classes}</Typography>
            <Typography variant="body1">Aulas</Typography>
          </article>
        </div>
      </div>
      <CardFooter className="overview-footer">
        <div>
          <Rating initialRating={course.rating} readonly />
        </div>
        <div>
          {course.status === 0 && (
            <Icon color="error">
              <FontAwesomeIcon icon={faLock} />
            </Icon>
          )}
          {course.status === 2 && (
            <Icon color="g5">
              <FontAwesomeIcon icon={faEyeSlash} />
            </Icon>
          )}
        </div>
      </CardFooter>
    </Container>
  );
}
