import React from 'react';
import styled from 'styled-components';

import Typography from '../Typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .list__title {
    padding: 26px;
  }
`;

export default function List({ children, title, ...props }) {
  return (
    <Container {...props}>
      {title && (
        <div className="list__title">
          <Typography variant="h3">{title}</Typography>
        </div>
      )}
      {children}
    </Container>
  );
}
