import React from 'react';
import styled from 'styled-components';

import SectionTitle from '../SectionTitle';
import ItemStudent from '../ItemStudent';
import Button from '../Button';
import Link from '../Link';

export const Container = styled.div`
  .list-items-headers{
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% - 225px) 120px 120px;
    gap: 5px;
    margin: 15px 0px 15px 0px;
    border-bottom: 1px solid #ECEFF1;
    > div{
      color: #607D8B;
      font-size: 12px;
      padding-bottom: 13px;
    }
  }
  .list-items-items{
    margin-top: 20px;
  }
  .list-items-link{
    margin-top: 30px;
  }
`;

const getItems = (items = []) => {
  return items.map(el => {
    return(
      <ItemStudent avatar={el.mail} name={el.name} registration={el.born} stats={0} />
    );
  });
}

export default function ListClasses({
  title,
  items,
  onClickViewAll,
  onClickAddUser,
  ...props
}){
  return (
    <Container title={title} items={items} {...props}>
      <SectionTitle title={title} border>
        <Button onClick={onClickAddUser} color="white" shadow size="xs">Adicionar aluno</Button>
      </SectionTitle>
      <div className="list-items-items">
        {getItems(items)}
      </div>
      <div className="list-items-link">
        <Link onClick={onClickViewAll}>Ver todos os alunos</Link>
      </div>
    </Container>
  );
}
