import React, { useState, useCallback, useMemo, useRef } from 'react';

import { TextField } from '@material-ui/core';

import { Switch } from '~/components';

import { Container, OptionList, Option } from './styles';

function SubscribeOptions({
  isButtonVisible,
  setIsButtonVisible,
  setCourseProperties,
  courseProperties,
}) {
  const dropdownRef = useRef(null);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isPhoneRequired, setIsPhoneRequired] = useState(
    () => {
      const phoneProperty = courseProperties.find((property) => property.prop_key === 'phone_required')

      return phoneProperty && phoneProperty.prop_value === 'true'
    }
  );

  const handleToggleDropdown = useCallback(() => {
    setIsDropdownVisible((prevIsDropdownVisible) => !prevIsDropdownVisible);
  }, []);

  const handleSubmit = useCallback((data) => {
    setCourseProperties((prevState) => {
      const findProp = prevState.find((property) => property.prop_key === 'phone_required');

      return findProp
        ? prevState.map((property) => (property.prop_key === 'phone_required' ? data : property))
        : [...prevState, data];
    });

    setIsPhoneRequired((prevState) => !prevState);
  }, []);

  const handleChange = useCallback(() => {
    setIsButtonVisible((prevState) => !prevState);
  }, []);

  const handleInputChange = useCallback((data) => {
    setCourseProperties((prevState) => {
      const findProp = prevState.find((property) => property.prop_key === 'page_redirect');

      return findProp
        ? prevState.map((property) => (property.prop_key === 'page_redirect' ? data : property))
        : [...prevState, data];
    });
  }, []);

  const pageRedirectLink = useMemo(() => {
    const defaultLink = courseProperties.find((property) => property.prop_key === 'page_redirect');

    return defaultLink && defaultLink.prop_value;
  }, [courseProperties]);

  return (
    <Container ref={dropdownRef}>
      <button type="button" onClick={handleToggleDropdown}>
        Opções
      </button>

      <OptionList visible={isDropdownVisible}>
        <Option>
          <span>Solicitar telefone</span>

          <Switch
            checked={isPhoneRequired}
            onChange={() =>
              handleSubmit({ prop_key: 'phone_required', prop_value: !isPhoneRequired })}
          />
        </Option>

        <Option>
          <div className="flex-column">
            <div className="justify-between">
              <span>Exibir saiba mais</span>

              <Switch checked={isButtonVisible} onChange={handleChange} />
            </div>

            {isButtonVisible && (
              <TextField
                onChange={(event) =>
                  event.target.value &&
                  handleInputChange({ prop_key: 'page_redirect', prop_value: event.target.value })}
                label="Link"
                defaultValue={pageRedirectLink}
                fullWidth
              />
            )}
          </div>
        </Option>
      </OptionList>
    </Container>
  );
}

export default SubscribeOptions;
