import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const optionsModifiers = {
  top: css`
    bottom: calc(100% + 16px);

    ::before {
      position: absolute;
      bottom: -8px;
      right: 42px;

      transform: rotate(180deg);
    }
  `,

  bottom: css`
    top: calc(100% + 16px);

    ::before {
      position: absolute;
      top: -8px;
      right: 42px;
    }
  `,
};

export const OptionList = styled.div`
  ${({ visible, position }) => css`
    z-index: 2;

    position: absolute;
    right: calc(50% - 50px);

    min-width: 310px;

    padding: 24px;
    border-radius: 5px;

    background-color: #fff;

    opacity: 0;
    visibility: hidden;

    box-shadow: 0px 2px 8px #00000029;
    transition: top 0.2s ease 0s, opacity 0.2s ease 0s, visibility 0.2s ease 0s;

    ::before {
      content: '';

      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0px 8px 8px;
      border-color: transparent transparent #ffffff;
    }

    ${visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}

    ${optionsModifiers[position || 'bottom']}
  `}
`;

export const Option = styled.button`
  width: 40px;
  height: 40px;

  background-color: #ffffff;
  border-radius: 4px;

  box-shadow: 0 1px 6px rgb(0 0 0 / 10%);

  & + button {
    margin-left: 8px;
  }

  svg {
    color: #78909c;
    font-size: 16px;
  }

  :hover {
    cursor: pointer;

    svg {
      opacity: 0.8;
    }
  }
`;
