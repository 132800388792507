import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import Input from '../Input';
import ButtonBase from '../Button';
import Typography from '../Typography';
import Skeleton from '../Skeleton';
import VideoPlayer from '../VideoPlayer';

export const Container = styled.div`
  width: 100%;
`;

export const Button = styled(ButtonBase)`
  margin-top: 16px;
`;

export const InputArea = styled.div`
  margin: 84px;
  @media (max-width: 768px){
    margin: 15px;
  }
`;

export const IconArea = styled.div`
  margin: 0 auto;
  margin-top: 64px;
`;

export const FooterArea = styled.div`
  width: 100%;
  bottom: 26px;
  display: flex;
  & > * {
    margin: 8px;
  }
`;

export const VideoSize = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  padding: 26px;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-end;
  background: ${(props) => props.theme.palette[props.color] || props.color};
`;

export const VideoPreview = styled.div`
  input {
    height: 0;
    width: 0;
    margin: 0;
  }
`;

export default function VideoInput({ color = 'g1', name = 'video', video = '', light = false }) {
  const [edit, setEdit] = useState(false);
  const [videoUrl, setVideo] = useState(video);

  const [show, setShow] = useState(false);
  const LabelPressEnter = styled.div`
    @-webkit-keyframes fadein-label {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
    @-moz-keyframes fadein-label {
      from { opacity: 0; }
      to   { opacity: 1; }
    }
    @keyframes fadein-label{
      from { opacity: 0; }
      to   { opacity: 1; }
    }
    position: relative;
    span{
      color: #949ea5;
      font-size: 14px;
      margin-top: -20px;
      margin-left: 0px;
      position: absolute;
      display: block;
      -webkit-animation: fadein-label .5s;
        -moz-animation: fadein-label .5s;
          -ms-animation: fadein-label .5s;
          -o-animation: fadein-label .5s;
              animation: fadein-label .5s;
    }
  `;

  function handleEdit(e) {
    e.preventDefault();
    setEdit(true);
  }

  function handleKeyPress(event) {
    if (event.charCode === 13) {
      setEdit(false);
    }
  }

  function handleBlur(event) {
    setEdit(!event.target.value.match(/(http|embed|iframe)/g));
  }

  React.useEffect(() => {
    setEdit(!video);
    setVideo(video);
  }, [video, setVideo]);

  return (
    <Container>
      {edit ? (
        <VideoSize color={color}>
          <IconArea>
            <FontAwesomeIcon icon={faYoutube} color="#CFD8DC" size="4x" />
          </IconArea>
          <InputArea>
            <Input
              value={videoUrl}
              label="Link do vídeo ou código de embed"
              onChange={(event) => {
                setVideo(event.target.value.trim());
              }}
              onBlur={(event)=>{
                handleBlur(event);
                setShow();
              }}
              onFocus={()=>{setShow(true)}}
              name={name}
              defaultValue={videoUrl}
              onKeyPress={handleKeyPress}
              multiline
            />
            {show && (
              <LabelPressEnter>
                <span>Aperte enter para salvar</span>
              </LabelPressEnter>
            )}
            <Typography variant="body2" gutterTop="10px">
              Pode ser um link dos players suportados ou código de embed.
            </Typography>
          </InputArea>
          <FooterArea>
            <Skeleton color="g2" width="42px" height="32px" />
            <Skeleton color="g2" height="32px" />
            <Skeleton color="g2" height="32px" width="42px" circle />
            <Skeleton color="g2" height="32px" width="42px" circle />
          </FooterArea>
        </VideoSize>
      ) : (
        <VideoPreview>
          <VideoPlayer url={videoUrl} controls light={light}>
            {video}
          </VideoPlayer>
          <Button onClick={handleEdit} color="white" size="sm">
            Alterar vídeo
          </Button>
          <Input name={name} value={videoUrl} simple />
        </VideoPreview>
      )}
    </Container>
  );
}
