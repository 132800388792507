import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TextField } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye } from '@fortawesome/pro-regular-svg-icons';

import api, { FileService } from '~/services/http';

import { useStyle } from '~/contexts/signIn';
import { updateObjectProperty } from '~/utils';
import { formatSchoolPropertiesTosave } from '~/utils/format';

import { Can, Switch, Button } from '~/components';

import Editable from '../EditableOptions';

import { Container, Option } from './styles';

export default function EditableNavbar({ setEditable }) {
  const history = useHistory();

  const { school, styles, setStyles } = useStyle();

  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);

      const files = [
        { id: 'leftColumn.styles.bgFile', file: styles.leftColumn.styles.bgFile },
        { id: 'rightColumn.styles.bgFile', file: styles.rightColumn.styles.bgFile },
        { id: 'leftColumn.logo.styles.bgFile', file: styles.leftColumn.logo.styles.bgFile },
      ];

      const data = styles;

      await files
        .filter((file) => !!file.file && file.file?.type !== undefined)
        .reduce((previousPromise, file) => {
          return previousPromise.then(() => {
            return FileService.uploadS3(file.file).then((uploaded) => {
              updateObjectProperty(data, file.id, uploaded);

              return uploaded;
            });
          });
        }, Promise.resolve());

      const properties = formatSchoolPropertiesTosave(data);

      await api.put('/schools', { id: school.id, properties });

      toast('Página de login customizada com sucesso!', {
        autoClose: 3000,
        type: 'info',
      });
    } catch (error) {
      toast('Erro ao customizar página de login!', {
        autoClose: 3000,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  function handleEditable() {
    handleSubmit().then(() => setEditable(false));
  }

  function handleGoBack() {
    history.goBack();
  }

  function handleReset() {
    setStyles({ type: 'RESET_STYLES', payload: {} });
  }

  function handleRegisterVisibilityChange(visibility) {
    const newStyles = updateObjectProperty(styles.leftColumn.form, 'anchor.visible', visibility);

    setStyles({ type: 'LEFT_COLUMN_FORM_STYLES', payload: newStyles });
  }

  function handleRegisterContentChange(event) {
    const { value } = event.target;
    const newStyles = updateObjectProperty(styles.leftColumn.form, 'anchor.content', value);

    setStyles({ type: 'LEFT_COLUMN_FORM_STYLES', payload: newStyles });
  }

  function handlePromotionVibilityChange(visibility) {
    const newStyles1 = updateObjectProperty(styles.rightColumn, 'visible', visibility);
    const newStyles2 = updateObjectProperty(
      styles.leftColumn.logo,
      'styles.textAlign',
      visibility ? 'left' : 'center',
    );

    setStyles({ type: 'RIGHT_COLUMN_STYLES', payload: newStyles1 });
    setStyles({ type: 'LEFT_COLUMN_LOGO_STYLES', payload: newStyles2 });
  }

  function handlePromotionContentChange(event) {
    const { value } = event.target;
    const newStyles = updateObjectProperty(styles.rightColumn.promo, 'anchor.content', value);

    setStyles({ type: 'RIGHT_COLUMN_PROMO_STYLES', payload: newStyles });
  }

  return (
    <Container>
      <button type="button" className="is-back" onClick={handleGoBack}>
        <FontAwesomeIcon icon={faArrowLeft} size="2x" />
      </button>

      <aside>
        <button type="button" onClick={handleEditable}>
          <FontAwesomeIcon icon={faEye} size="1x" />
        </button>

        <Editable>
          <Option>
            <div className="flex-column">
              <div className="justify-between">
                <span>Link para cadastro</span>

                <Switch
                  checked={styles.leftColumn.form.anchor.visible}
                  onChange={handleRegisterVisibilityChange}
                />
              </div>

              <Can renderable={styles.leftColumn.form.anchor.visible}>
                <TextField
                  label="Link"
                  onChange={handleRegisterContentChange}
                  defaultValue={styles.leftColumn.form.anchor.content}
                  fullWidth
                />
              </Can>
            </div>
          </Option>

          <Option>
            <div className="flex-column">
              <div className="justify-between">
                <span>Promoção lateral</span>

                <Switch
                  checked={styles.rightColumn.visible}
                  onChange={handlePromotionVibilityChange}
                />
              </div>

              <Can renderable={styles.rightColumn.visible}>
                <TextField
                  label="Link"
                  onChange={handlePromotionContentChange}
                  defaultValue={styles.rightColumn.promo.anchor.content}
                  fullWidth
                />
              </Can>
            </div>
          </Option>

          <Option>
            <Button
              style={{ height: 'auto', margin: 0 }}
              type="button"
              color="default"
              outline
              size="sm"
              onClick={handleReset}
            >
              Voltar para configurações padrão
            </Button>
          </Option>
        </Editable>

        <button type="button" className="is-primary" onClick={handleSubmit}>
          {loading ? 'Salvando..' : 'Salvar'}
        </button>
      </aside>
    </Container>
  );
}
