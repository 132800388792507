import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 826px;

  ${({ fetching }) =>
    fetching &&
    css`
      section {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}

  footer {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 80px 0;
  }
`;

export const ShowReplies = styled.button`
  color: #2bb0ee !important;
  font-weight: bold !important;
`;

export const Spinner = styled.span`
  width: 32px;
  height: 32px;

  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #607d8b;
  border-radius: 50%;

  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
