import React from 'react';
import styled from 'styled-components';

import IconButton from '../IconButton';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px;
  height: 120px;
  background: #fff;
`;

export default ({ children, brand, social, ...props }) => {
  return (
    <Footer {...props}>
      <div>{brand}</div>
      <div>{children}</div>
      <div>
        {social.map((link) => (
          <IconButton key={link.href} href={link.href} target="_blank" icon={link.icon} />
        ))}
      </div>
    </Footer>
  );
};
