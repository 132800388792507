import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import withPure from '../Pure';

const Wrapper = styled.div`
  width: ${(props) => props.width || 100}px;
  height: ${(props) => props.height}px;
  border-radius: 30px;
  background: ${(props) => transparentize(0.8, props.theme.palette[props.color] || props.color)};
  ${(props) =>
    props.outline &&
    css`
      border: 1px solid ${props.theme.palette[props.color] || props.color};
      background: transparent;
    `}
`;

const Progress = styled.div`
  width: ${(props) => props.progress}%;
  height: ${(props) => props.height}px;
  background: ${(props) => props.theme.palette[props.color] || props.color};
  border-radius: 30px;
  ${(props) =>
    props.outline &&
    css`
      height: ${props.height - 2}px;
    `}
`;

export default withPure(
  ({
    progress = 0,
    height = 16,
    width,
    color = '#fff',
    outline = false,
    background = 'transparent',
  }) => {
    return (
      <Wrapper
        color={color}
        height={height}
        outline={outline}
        background={background}
        width={width}
      >
        <Progress
          progress={progress > 100 ? 100 : progress}
          color={color}
          height={height}
          outline={outline}
        />
      </Wrapper>
    );
  },
);
