export const loginMassage = {
  200: 'Usuário autenticado.',
  404: 'E-mail não cadastrado.',
  500: 'Senha inválida.'
}

export const loginTexts = {
  resetSuccess: 'Email de recuperação enviado!',
  resetTitle: '',

}
