import React, { useState, useCallback, useMemo } from 'react';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import api from '~/services/http';
import { useAuth } from '~/contexts/auth';
import { formatDate } from '~/utils/format';

import { Container, Avatar } from './styles';

function CreateReplyForm({ user: owner, comment, onSubmit }) {
  const [loading, setLoading] = useState('');
  const [reply, setReply] = useState(() => (comment?.id && owner) ? `@${owner.name} ` : '');

  const { user } = useAuth();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      setLoading(true);

      const data = { comment: reply, ...(comment?.id && { answer_id: comment?.id }) };

      const response = await api.post(`/classes/${comment.class_id}/comments`, data);

      setLoading(false);

      setReply('');

      const { id, comment: newComment } = response;

      onSubmit({
        id,
        ...newComment,
        class_id: comment.class_id,
        answer: comment?.id ?? null,
        replies: [],
        created_at: formatDate(new Date()),
      });
    },
    [comment, reply],
  );

  const [firstName] = useMemo(() => String(user?.name).split(' '), [user]);

  return (
    <Container onSubmit={handleSubmit}>
      <div>
        <Avatar email={user.email} name={user.name} />

        <TextareaAutosize
          value={reply}
          onChange={(event) => setReply(event.target.value)}
          placeholder={`${firstName}, deixe um comentário sobre esta aula...`}
        />
      </div>

      <div>
        <button type="submit" disabled={!reply || loading}>
          {loading ? 'Publicando..' : 'Publicar'}
        </button>
      </div>
    </Container>
  );
}

export default CreateReplyForm;
