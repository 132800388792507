import styled from 'styled-components';

export const Container = styled.div``;

export const UploadProgress = styled.div`
  margin-bottom: 16px;
`;

export const Preview = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 16px;

  > div {
    display: flex;
    flex-direction: column;

    span,
    small {
      margin-left: 16px;

      font-family: 'Open Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
    }

    span {
      color: rgba(0, 0, 0, 0.87);
    }

    small {
      color: #78909c;
    }
  }

  > button {
    margin-left: auto;

    border: 0;
    background: none;
  }
`;
