import React, { useState, useEffect, useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTrash } from '@fortawesome/pro-regular-svg-icons';

import { Container, Button } from './styles';

export default function UploadOption({ onChange, onPreviewChange, defaultValue, ...props }) {
  const inputFileRef = useRef();

  const [selected, setSelected] = useState(false);

  const handleClick = useCallback(() => {
    inputFileRef.current.click();
  }, []);

  const handleChange = useCallback((event) => {
    const fileUploaded = event.target.files[0];

    if (!fileUploaded) {
      return;
    }

    if (onChange) onChange(fileUploaded);
    if (onPreviewChange) onPreviewChange(URL.createObjectURL(fileUploaded));

    setSelected(true);

    inputFileRef.current.value = '';
  }, []);

  const handleRemove = useCallback(() => {
    if (onChange) onChange(null);
    if (onPreviewChange) onPreviewChange(null);

    setSelected(false);
  }, []);

  useEffect(() => {
    setSelected(!!defaultValue)
  }, [defaultValue])

  return (
    <Container {...props}>
      <Button type="button" onClick={selected ? handleRemove : handleClick}>
        <FontAwesomeIcon icon={selected ? faTrash : faImage} />
      </Button>

      <input ref={inputFileRef} type="file" onChange={handleChange} accept="image/*" />
    </Container>
  );
}
