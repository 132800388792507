import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: table;
  position: relative;
  &:before {
    top: 50%;
    right: 8px;
    width: 15px;
    height: 15px;
    z-index: 10;
    content: ' ';
    position: absolute;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center center;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCEtLSBHZW5lcmF0b3I6IEdyYXZpdC5pbyAtLT48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHN0eWxlPSJpc29sYXRpb246aXNvbGF0ZSIgdmlld0JveD0iMCAwIDIwIDEyLjkxNyIgd2lkdGg9IjIwcHQiIGhlaWdodD0iMTIuOTE3cHQiPjxkZWZzPjxjbGlwUGF0aCBpZD0iX2NsaXBQYXRoX2tFOGljM3ZnNjZFV1E1ZVhSNVp2TWh6SThpN2RudWhIIj48cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMTIuOTE3Ii8+PC9jbGlwUGF0aD48L2RlZnM+PGcgY2xpcC1wYXRoPSJ1cmwoI19jbGlwUGF0aF9rRThpYzN2ZzY2RVdRNWVYUjVadk1oekk4aTdkbnVoSCkiPjxwYXRoIGQ9IiBNIDguOTQzIDEyLjQ3NiBMIDAuNDQxIDMuOTczIEMgLTAuMTQ3IDMuMzg1IC0wLjE0NyAyLjQzNSAwLjQ0MSAxLjg1NCBMIDEuODU0IDAuNDQxIEMgMi40NDEgLTAuMTQ3IDMuMzkyIC0wLjE0NyAzLjk3MyAwLjQ0MSBMIDEwIDYuNDY4IEwgMTYuMDI3IDAuNDQxIEMgMTYuNjE1IC0wLjE0NyAxNy41NjUgLTAuMTQ3IDE4LjE0NiAwLjQ0MSBMIDE5LjU1OSAxLjg1NCBDIDIwLjE0NyAyLjQ0MSAyMC4xNDcgMy4zOTIgMTkuNTU5IDMuOTczIEwgMTEuMDU3IDEyLjQ3NiBDIDEwLjQ4MSAxMy4wNjMgOS41MzEgMTMuMDYzIDguOTQzIDEyLjQ3NiBMIDguOTQzIDEyLjQ3NiBaICIgZmlsbD0icmdiKDEyMCwxNDQsMTU2KSIvPjwvZz48L3N2Zz4=');
    transform: translateY(-50%);
  }
  select {
    font-size: 14px;
    color: #78909c;
    background: transparent;
    position: relative;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding: 8px 30px 8px 10px;
  }
`;

export default function Dropdown({ options, ...props }) {
  return (
    <Container>
      <select {...props}>
        {options.map((el) => {
          return (
            <option key={el.value} value={el.value}>
              {el.label}
            </option>
          );
        })}
      </select>
    </Container>
  );
}
