import React from 'react';
import styled from 'styled-components';

import Chart from '~/components/Chart';
import Rating from '~/components/Rating';
import Progress from '~/components/Progress';
import CardMetric from '~/components/CardMetric';

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  position: relative;
`;

const Metrics = styled.div`
  padding: 26px;
  background: #f5f7f8;
  /* min-height: 50vh; */
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 26px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;

export default function MetricsComponent({
  comments = 0,
  students = 0,
  rating = 0,
  nps = 0,
  churn = 0,
  notes = 0,
  graduated = 0,
  totalDisabled = 0,
  ratingPromoters = 0,
  chartUsers,
  chartChurn,
  chartComments,
}) {
  return (
    <>
      <Metrics>
        <Container>
          <Grid>
            <CardMetric
              title={`${Number(rating).toFixed(1)} estrelas`}
              subtitle={`É a média deste curso, avaliado por ${ratingPromoters} alunos.`}
            >
              <div className="metric-tool">
                <Rating initialRating={rating} readonly />
              </div>
            </CardMetric>
            <CardMetric
              title={`${graduated}% de conclusão`}
              subtitle="É o percentual de alunos que concluem"
            >
              <div className="metric-tool">
                <Progress progress={graduated} height={6} color="success" />
              </div>
            </CardMetric>
            <CardMetric
              title={`${Math.round(nps)}% de NPS`}
              subtitle="Reflete o índice de satisfação dos estudantes com este curso."
            >
              <div className="metric-tool">
                <Progress progress={nps} height={6} color="success" />
              </div>
            </CardMetric>
            <CardMetric title={`${students} alunos`} subtitle="Total de alunos nesse curso">
              <div className="metric-graphic">
                <Chart data={chartUsers} />
              </div>
            </CardMetric>
            <CardMetric
              title={`${Math.round(churn)}% de churn`}
              subtitle={`Representa uma perda de ${totalDisabled} alunos desde o início.`}
            >
              <div className="metric-graphic">
                <Chart data={chartChurn} />
              </div>
            </CardMetric>
            <CardMetric
              title={`${comments} comentários`}
              subtitle={`Foram realizados, além de ${notes} anotações.`}
            >
              <div className="metric-graphic">
                <Chart data={chartComments} />
              </div>
            </CardMetric>
          </Grid>
        </Container>
      </Metrics>
    </>
  );
}
