import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 826px;

  input,
  button,
  textarea {
    font-family: 'Open Sans', sans-serif;
  }

  > header {
    margin-bottom: 40px;
  }

  > section {
    > div:first-child {
      margin-bottom: 40px;

      font-size: 14px;
    }
  }
`;

export const Content = styled.section`
  header {
    display: flex;
    justify-content: space-between;

    button {
      padding: 16px 0;

      background: none;

      color: #607d8b;
      font-size: 12px;

      :first-of-type svg {
        margin-left: 8px;
      }

      :last-of-type {
        span {
          visibility: hidden;

          position: absolute;
          z-index: 1;
          left: 50%;
          bottom: 100%;


          margin-left: -60px;
          padding: 5px 0;
          border-radius: 6px;

          width: 120px;

          color: #fff;
          background-color: #555;
          text-align: center;

          opacity: 0;
          transition: opacity 0.3s;

          ::after {
            position: absolute;
            top: 100%;
            left: 50%;

            content: '';

            margin-left: -5px;

            border-width: 5px;
            border-style: solid;
            border-color: #555 transparent transparent transparent;
          }
        }

        :hover span {
          visibility: visible;
          opacity: 1;
        }

        svg {
          margin-right: 8px;
        }
      }

      :disabled {
        cursor: not-allowed;
      }

      :hover {
        filter: brightness(0.9);

        cursor: pointer;
      }
    }
  }
`;

export const Annotation = styled.article`
  display: flex;

  padding: 24px 16px;

  border-top: 1px solid #eceff1;

  ${(props) =>
    props.selected &&
    css`
      padding-bottom: 16px;

      border-radius: 5px;
      background-color: #eceff180;
    `}

  > div {
    :first-of-type {
      time {
        display: block;

        width: 130px;

        color: #78909c;
        font-size: 12px;

        svg {
          margin-right: 8px;
        }
      }
    }

    :last-of-type {
      flex: 1;

      margin-left: 56px;

      textarea {
        width: 100%;

        resize: none;
        outline: none;

        background: transparent;

        white-space: pre-wrap;
      }

      p {
        font-size: 14px;

        white-space: pre-wrap;
      }

      > footer {
        display: flex;
        justify-content: space-between;

        margin-top: 32px;

        span {
          :first-of-type {
            color: #90a4ae;
            font-size: 12px;

            button {
              background: transparent;
              color: #90a4ae;
              font-size: 12px;

              :hover {
                filter: brightness(0.9);

                cursor: pointer;
              }
            }
          }

          :last-of-type {
            color: #ff5252;

            :hover {
              filter: brightness(0.9);

              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;
