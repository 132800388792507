import dayjs from 'dayjs';

import { getCloudinaryPath } from '~/utils';

export const { format: formatPercent } = new Intl.NumberFormat('en-US', {
  style: 'percent',
});

export const { format: formatDecimal } = new Intl.NumberFormat('pt-BR');

export function formatDate(date) {
  return dayjs(date).toNow(true);
}

export function formatTime(seconds) {
  const date = new Date(0);

  date.setSeconds(seconds);

  return date.toISOString().substr(14, 5);
}

export function formatContent(content) {
  return content
    .replace(/(?:\*\*)([^*]*)(?:\*\*)/gm, '<strong>$1</strong>')
    .replace(/(?:~)([^~]*)(?:~)/gm, '<strike>$1</strike>')
    .replace(/(?:`)([^`]*)(?:`)/gm, '<i>$1</i>')
    .trim()
    .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
}

export function formatSchoolProperties(properties = {}) {
  const propertiesBoolean = [
    'login_box_padding',
    'login_box_shadow',
    'login_has_cta_text',
    'login_has_promo',
    'login_non_students_visibility',
  ];

  const propertiesNullable = ['login_bg_image', 'login_box_bg_image', 'login_promo_bg_image'];

  const newProperties = Object.keys(properties)
    .filter((property) => String(property).match(/login/i))
    .map((property) => ({ key: property, value: properties[property] }))
    .reduce((object, property) => {
      if (!object[property.key]) object[property.key] = property.value;

      if (propertiesBoolean.includes(property.key)) {
        object[property.key] = property.value === 'true' || property.value === '1';
      }

      if (propertiesNullable.includes(property.key) && property.value === 'null') {
        object[property.key] = property.value === null;
      }

      return object;
    }, {});

  return {
    leftColumn: {
      visible: true,
      logo: {
        styles: {
          bgFile: null,
          bgImage: newProperties.login_box_bg_image
            ? getCloudinaryPath(newProperties.login_box_bg_image)
            : null,
          fontColor: newProperties.login_brand_color,
          textAlign: newProperties.login_has_promo ? 'left' : 'center',
        },
      },
      form: {
        title: newProperties.login_title,
        subtitle: newProperties.login_subtitle,
        anchor: {
          visible: newProperties.login_non_students_visibility,
          content: newProperties.login_non_students,
        },
        styles: {
          shadow: newProperties.login_box_shadow ?? true,
          padding: newProperties.login_box_padding ?? true,
          bgColor: newProperties.login_box_color,
          fontColor: newProperties.login_txt_color,
        },
      },
      styles: {
        bgFile: null,
        bgImage: newProperties.login_bg_image
          ? getCloudinaryPath(newProperties.login_bg_image)
          : null,
        bgColor: newProperties.login_bg_color,
      },
    },
    rightColumn: {
      visible: newProperties.login_has_promo,
      promo: {
        title: newProperties.login_promo_title,
        subtitle: newProperties.login_promo_subtitle,
        visible: newProperties.login_has_cta_text,
        anchor: {
          content: newProperties.login_promo_cta_url,
        },
        styles: {
          position: newProperties.login_promo_cta_position,
          fontColor: newProperties.login_promo_color,
        },
      },
      styles: {
        bgFile: null,
        bgImage: newProperties.login_promo_bg_image
          ? getCloudinaryPath(newProperties.login_promo_bg_image)
          : null,
        bgColor: newProperties.login_promo_bg_color,
      },
    },
  };
}

export function formatSchoolPropertiesTosave(properties = {}) {
  const newProperties = {
    ...(!properties.leftColumn.styles.bgImage ? { login_bg_image: null } : {}),
    ...(!properties.leftColumn.logo.styles.bgImage ? { login_box_bg_image: null } : {}),
    ...(!properties.rightColumn.styles.bgImage ? { login_promo_bg_image: null } : {}),

    ...(properties.leftColumn.styles.bgFile
      ? { login_bg_image: properties.leftColumn.styles.bgFile }
      : {}),
    ...(properties.leftColumn.logo.styles.bgFile
      ? { login_box_bg_image: properties.leftColumn.logo.styles.bgFile }
      : {}),
    ...(properties.rightColumn.styles.bgFile
      ? { login_promo_bg_image: properties.rightColumn.styles.bgFile }
      : {}),

    login_brand_color: properties.leftColumn.logo.styles.fontColor,
    login_title: properties.leftColumn.form.title,
    login_subtitle: properties.leftColumn.form.subtitle,
    login_non_students_visibility: properties.leftColumn.form.anchor.visible,
    login_non_students: properties.leftColumn.form.anchor.content,
    login_box_shadow: properties.leftColumn.form.styles.shadow,
    login_box_padding: properties.leftColumn.form.styles.padding,
    login_box_color: properties.leftColumn.form.styles.bgColor,
    login_txt_color: properties.leftColumn.form.styles.fontColor,
    login_bg_color: properties.leftColumn.styles.bgColor,

    login_has_promo: properties.rightColumn.visible,
    login_promo_title: properties.rightColumn.promo.title,
    login_promo_subtitle: properties.rightColumn.promo.subtitle,
    login_has_cta_text: properties.rightColumn.promo.visible,
    login_promo_cta_url: properties.rightColumn.promo.anchor.content,
    login_promo_cta_position: properties.rightColumn.promo.styles.position,
    login_promo_color: properties.rightColumn.promo.styles.fontColor,
    login_promo_bg_color: properties.rightColumn.styles.bgColor,
  };

  Object.keys(newProperties).forEach((key) =>
    newProperties[key] === undefined ? delete newProperties[key] : {},
  );

  return newProperties;
}

export function parseToParams(params = '') {
  const rawParams = params.replace('?', '').split('&');

  const extractedParams = rawParams.reduce((accu, curr) => {
    const [key, value] = curr.split('=');

    if (!accu[key]) {
      accu[key] = value;
    }

    accu[key] = value;

    return accu;
  }, {});

  return extractedParams;
}

export function parseFromParams(params = {}) {
  const joinParmas = Object.keys(params)
    .filter((param) => params[param] !== '' && !!params[param])
    .map((param) => `${param}=${params[param]}`)
    .join('&');

  return joinParmas;
}
