import React, { useState, useCallback, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import api from '~/services/http';
import { formatDate } from '~/utils/format';

import CreateReplyForm from './CreateReplyForm';
import CommentItem from './CommentItem';

import { Container, ShowReplies, Spinner } from './styles';

export default function Comments({ classId }) {
  const [comments, setComments] = useState([]);
  const [selectedComments, setSelectedComments] = useState([]);
  const [fetchMore, setFetchMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const handleScroll = useCallback(() => {
    const { scrollTop, offsetHeight } = document.documentElement;
    const { innerHeight } = window;

    if (innerHeight + scrollTop !== offsetHeight) {
      return;
    }

    setLoading(true);
    setPage((prevPage) => prevPage + 1);
  }, []);

  const hadleChangeComments = useCallback((newComment) => {
    setComments((prevComments) => [newComment, ...prevComments])
  }, []);

  const hadleChangeReplies = useCallback((newComment) => {
    setComments((prevComments) => {
      return prevComments.map((prevComment) => {
        const prevCommentsIds = [prevComment.id, ...prevComment.replies.map((reply) => reply.id)];

        const isComment = prevCommentsIds.some((commentId) => commentId === newComment.answer);

        return isComment
          ? { ...prevComment, replies: [...prevComment.replies, newComment] }
          : prevComment;
      });
    });
  }, []);

  const selectedComment = useCallback((commentId) => {
    setSelectedComments((prevCommentsIds) => [...prevCommentsIds, commentId]);
  }, []);

  const checkCommentIsSelected = useCallback(
    (commentId, replies = []) => replies.length > 0 && !selectedComments.includes(commentId),
    [selectedComments],
  );

  const formatComments = useCallback((commentsArray = []) => {
    return commentsArray.map(({ replies, ...comment }) => ({
      ...comment,
      replies: replies.map((reply) => ({
        ...reply,
        born: new Date(reply.born),
        created_at: formatDate(reply.born),
      })).sort((a, b) => new Date(a.born) - new Date(b.born)),
      created_at: formatDate(comment.born),
    }));
  }, []);

  useEffect(() => {
    setPage(1);
    setFetchMore(true);
  }, [classId]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!fetchMore) {
      setLoading(false);
      return;
    }

    api
      .get(`classes/${classId}/comments?page=${page}&per_page=3&sort_by=desc`)
      .then((response) => {
        const commentsFormatted = formatComments(response.data);

        setFetchMore(false);

        if (response.data.length === 0) {
          return;
        }

        setComments((prevComments) => {
          const newComments =
            page === 1 ? commentsFormatted : [...new Set([...prevComments, ...commentsFormatted])];

          const hasMore = newComments.length < response.total || response.data.length > 0;

          setFetchMore(hasMore);

          return newComments;
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [classId, page]);

  return (
    <Container>
      <header>
        <CreateReplyForm comment={{ class_id: classId }} onSubmit={hadleChangeComments} />
      </header>

      <section>
        {comments.length > 0 &&
          comments.map(({ owner, replies, ...comment }) => {
            const selected = checkCommentIsSelected(comment.id, replies);

            return (
              <CommentItem
                key={`comment-${comment.id}`}
                user={owner}
                data={comment}
                onSubmit={hadleChangeReplies}
              >
                {selected ? (
                  <ShowReplies type="button" onClick={() => selectedComment(comment.id)}>
                    <FontAwesomeIcon icon={faChevronDown} />
                    Ler respostas
                  </ShowReplies>
                ) : (
                  <>
                    {replies.map(({ owner: owner_reply, ...reply }) => (
                      <CommentItem
                        key={`reply-${reply.id}`}
                        user={owner_reply}
                        data={reply}
                        onSubmit={hadleChangeReplies}
                      />
                      ))}
                  </>
                )}
              </CommentItem>
            );
          })}

        {comments.length === 0 && (
          <p>Essa aula ainda não possui comentários. Seja o primeiro a comentar!</p>
        )}
      </section>

      <footer>{fetchMore && loading && <Spinner />}</footer>
    </Container>
  );
}
