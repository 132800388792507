import React, { useState, useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import useEventListener from '~/hooks/useEventListener';

import { Container, Content } from './styles';

function SchoolPhoneOptionCell({ phone }) {
  const dropdownRef = useRef(null);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleToggleDropdown = useCallback(() => {
    setIsDropdownVisible((prevIsDropdownVisible) => !prevIsDropdownVisible);
  }, []);

  const handleCloseDropdown = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    },
    [setIsDropdownVisible],
  );

  useEventListener('click', handleCloseDropdown, {
    enabled: isDropdownVisible,
  });

  return (
    <Container ref={dropdownRef} color="#546E7A">
      {phone && (
        <>
          <button type="button" onClick={handleToggleDropdown}>
            <FontAwesomeIcon icon={faPhone} size={10} />
          </button>

          <Content visible={isDropdownVisible}>
            <span>{phone}</span>
            <a
              rel="noopener noreferrer"
              href={`https://api.whatsapp.com/send?phone=${phone}`}
              target="_blank"
            >
              Ligar
            </a>
          </Content>
        </>
      )}
    </Container>
  );
}

export default SchoolPhoneOptionCell;
