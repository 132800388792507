import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '~/services/http';
import { convertProperties } from '~/utils';

const TYPE = 'subscriptions';
const ENDPOINT = '/subscriptions';

export const create = createAsyncThunk(`${TYPE}/create`, async (data) => {
  const response = await api.post(`${ENDPOINT}`, data);
  return { ...data, id: response.id };
});

export const fetch = createAsyncThunk(`${TYPE}/fetch`, async () => {
  const response = await api.get(`${ENDPOINT}`);
  return response;
});

export const remove = createAsyncThunk(`${TYPE}/delete`, async (id) => {
  const response = await api.delete(`${ENDPOINT}?id=${id}`);

  return response.id;
});

export const update = createAsyncThunk(`${TYPE}/update`, async (data) => {
  const response = await api.put(`${ENDPOINT}`, data);
  return { ...data, id: response.id };
});

export default createSlice({
  name: TYPE,
  initialState: { entities: { courses: [], classes: [] }, loading: true },
  reducers: {},
  extraReducers: {
    [create.fulfilled]: (state, action) => {
      state.entities.push(action.payload);
    },
    [fetch.fulfilled]: (state, action) => {
      const courses = action.payload.courses.map((course) => ({
        ...course,
        properties: convertProperties(course.options),
      })).filter(course => (course.status !== 0));
      state.entities = { courses };
      state.loading = false;
    },
    [update.fulfilled]: (state, action) => {
      const index = state.entities.findIndex((item) => item.id === action.payload.id);
      state.entities[index] = action.payload;
      state.loading = false;
    },
    [remove.fulfilled]: (state, action) => {
      const index = state.entities.findIndex((item) => item.id === Number(action.payload.id));
      if (index !== -1) state.entities.splice(index, 1);
      state.loading = false;
    },
  },
});