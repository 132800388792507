import { createSlice } from '@reduxjs/toolkit';

const TYPE = 'ui';

const slice = createSlice({
  name: TYPE,
  initialState: {
    modal: {
      help: false,
      courseForm: false,
      cardForm: false,
    },
  },
  reducers: {
    showHelpModal: (state) => {
      state.modal.help = true;
    },
    closeHelpModal: (state) => {
      state.modal.help = false;
    },
    showCourseFormModal: (state) => {
      state.modal.courseForm = true;
    },
    closeCourseFormModal: (state) => {
      state.modal.courseForm = false;
    },
    showCardFormModal: (state) => {
      state.modal.courseForm = true;
    },
    closeCardFormModal: (state) => {
      state.modal.cardForm = false;
    },
  },
});

export default slice;
