import React from 'react';
import styled, { css } from 'styled-components';

const Paper = styled.div`
  padding: ${(props) => props.theme.pxToRem(props.padding)};
  background: ${(props) => props.color || props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.text.primary};
  border-radius: ${(props) => props.radius || props.theme.shape.borderRadius}px;
  box-shadow: 0px 1px 6px rgb(0 0 0 / 0.1);
    ${(props) =>
    props.gutterBottom &&
    css`
      margin-bottom: ${props.theme.pxToRem(16)};
    `}
`;

export default ({ children, padding = 26,...props }) => {
  return <Paper padding={padding} {...props}>{children}</Paper>;
};
