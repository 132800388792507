import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Form as Unform } from '@unform/web';
import dayjs from 'dayjs';

import { mutate } from 'swr';

import Pagination from '@material-ui/lab/Pagination';

import api from '~/services/api';
import useRequest from '~/hooks/useRequest';

import Select from '~/components/unform/Select';
import { Modal, Button, Input, Typography, Avatar } from '~/components';

import Table from '~/pages/SuperDashboard/components/Table';
import DefaultColumnBody from '~/pages/SuperDashboard/components/Table/CustomCells/DefaultColumnBody';
import SortableColumnHeader from '~/pages/SuperDashboard/components/Table/CustomCells/SortableColumnHeader';

import SchoolFormModal from './SchoolFormModal';

import ShimmerTableBody from './ShimmerTableBody';
import SchoolOptionsCell from './SchoolOptionsCell';
import SchoolPhoneOptionCell from './SchoolPhoneOptionCell';
import SchoolPlanOptionsCell from './SchoolPlanOptionsCell';
import SchoolStatusOptionsCell from './SchoolStatusOptionsCell';

import {
  Container,
  ContentHeader,
  ContentBody,
  ContentFooter,
  ContainerButtons,
  CustomColumnContainer,
  CustomName,
  ModalHeader,
} from './styles';

function SchoolsList() {
  const formRef = useRef();
  const history = useHistory();

  const [schoolsStats, setSchoolsStats] = useState({
    total_trials: 0,
    total_actives: 0,
    total_blockeds: 0,
  });

  const [schoolToEdit, setSchoolToEdit] = useState(null);
  const [createNewSchool, setCreateNewSchool] = useState(false);

  const [filter, setFilter] = useState({});
  const [sortBy, setSortBy] = useState('');
  const [page, setPage] = useState(1);

  const { data, error, requestKey, response } = useRequest({
    url: '/colleges',
    params: {
      page,
      sort_by: sortBy,
      per_page: 25,
      ...filter,
    },
  });

  useEffect(() => {
    api.get('/colleges/stats').then((axiosResponse) => {
      setSchoolsStats({
        total_trials: axiosResponse.data?.total_trial || 0,
        total_actives: axiosResponse.data?.total_active || 0,
        total_blockeds: axiosResponse.data?.total_blocked || 0,
      });
    });
  }, []);

  const { total_trials, total_actives, total_blockeds } = useMemo(() => {
    return schoolsStats;
  }, [schoolsStats]);

  const { schools, total } = useMemo(() => {
    if (data) {
      return {
        schools: data.data,
        total: Math.ceil(Number(data.total) / 25),
      };
    }

    return { schools: undefined, total: 0 };
  }, [data]);

  const loading = useMemo(() => schools === undefined && !error, [schools, error]);

  const hasSchools = useMemo(() => !loading && schools !== undefined && !!schools.length, [
    schools,
    loading,
  ]);

  const schoolsFormatted = useMemo(() => {
    if (schools === undefined) return [];

    return schools.map((school) => ({
      ...school,
      uuid: uuid(),
      plan: school.plan ?? { id: 1, name: 'Trial' },
      status: school.status ?? { id: 1, name: 'Ativo' },
      created_at: dayjs(school.born).toNow(true),
    }));
  }, [schools]);

  const halfLengthSchoolsArray = useMemo(() => Math.ceil(schoolsFormatted.length / 2) - 1, [
    schoolsFormatted,
  ]);

  const schoolsPlans = useMemo(
    () => [
      { id: 1, name: 'Trial' },
      { id: 2, name: 'Essencial' },
      { id: 3, name: 'Pro' },
      { id: 4, name: 'Escala' },
      { id: 5, name: 'Custom' },
    ],
    [],
  );

  const schoolsStatus = useMemo(
    () => [
      { id: 1, name: 'Ativo' },
      { id: 0, name: 'Bloqueado' },
      { id: 2, name: 'Cancelado' },
    ],
    [],
  );

  const handleSubmit = useCallback((formData) => {
    const filterParams = {
      page: 1,
      ...(formData.name && { search: formData.name }),
      ...(formData.plan && { plan_id: formData.plan.id }),
      ...(formData.status && { status_id: formData.status.id }),
    };

    setFilter(filterParams);

    history.push({ search: new URLSearchParams(filterParams).toString() });
  }, []);

  const handleClearSubmit = useCallback(() => {
    formRef.current.reset();

    setFilter({});

    history.push({
      search: new URLSearchParams({}).toString(),
    });
  }, []);

  const successfullyEdited = useCallback(
    (school) => {
      mutate(
        requestKey,
        {
          ...response,
          data: {
            ...data,
            data: schools?.map((item) => (item.id === school.id ? { ...item, ...school } : item)),
          },
        },
        false,
      );
    },
    [requestKey, response, data, schools],
  );

  const handleCreateNewSchool = useCallback(() => {
    setCreateNewSchool(true);
  }, []);

  const handleEditSchool = useCallback((school) => {
    setSchoolToEdit(school);
  }, []);

  const handleCloseModal = useCallback(() => {
    setCreateNewSchool(false);

    setSchoolToEdit(null);
  }, []);

  return (
    <>
      <Container>
        <aside>
          <Typography variant="h5">Filtrar escolas:</Typography>

          <Unform ref={formRef} onSubmit={handleSubmit}>
            <Input name="name" placeholder="Nome, domínio ou e-mail" fullWidth />
            <Select
              isClearable
              name="status"
              placeholder="Qualquer status"
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              options={schoolsStatus}
              noOptionsMessage={() => 'Nenhum status cadastrado.'}
            />
            <Select
              isClearable
              name="plan"
              placeholder="Qualquer plano"
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              options={schoolsPlans}
              noOptionsMessage={() => 'Nenhum plano cadastrado.'}
            />
            <br />

            <ContainerButtons>
              <Button color="white" size="sm">
                Aplicar filtro
              </Button>

              <button type="button" onClick={handleClearSubmit}>
                Limpar
              </button>
            </ContainerButtons>
          </Unform>
        </aside>

        <section>
          <ContentHeader>
            <div>
              <Typography variant="h1">{`${total_actives} escolas ativas`}</Typography>

              <Typography color="g7" variant="body2">
                {`${total_trials} em trial, ${total_blockeds} bloqueadas`}
              </Typography>
            </div>

            <div>
              <Button onClick={handleCreateNewSchool} size="sm">
                Criar uma escola
              </Button>
            </div>
          </ContentHeader>

          <ContentBody>
            <Table>
              <thead>
                <tr>
                  <th>Escola</th>
                  <th>
                    <div />
                  </th>
                  <th>Cliente</th>
                  <SortableColumnHeader
                    title="Criação"
                    onOrderChange={setSortBy}
                    initialOrder={sortBy}
                  />
                  <th style={{ textAlign: 'center' }}>Plano</th>
                  <th style={{ textAlign: 'center' }}>Status</th>
                  <th>
                    <div />
                  </th>
                </tr>
              </thead>

              {loading && <ShimmerTableBody />}

              {hasSchools && (
                <tbody>
                  {schoolsFormatted?.map((school, index) => {
                    const isOptionsShowerUp =
                      halfLengthSchoolsArray > 2 && index > halfLengthSchoolsArray;

                    return (
                      <tr key={`table-line-${school.uuid}`}>
                        <DefaultColumnBody onClick={() => handleEditSchool(school)}>
                          <CustomColumnContainer>
                            <Avatar name={school.title} email={school.domain} size={38} />

                            <div>
                              <CustomName>{school.title}</CustomName>
                              <span>{school.domain}</span>
                            </div>
                          </CustomColumnContainer>
                        </DefaultColumnBody>

                        <DefaultColumnBody style={{ width: 40 }}>
                          <CustomColumnContainer>
                            {school.phone ? (
                              <SchoolPhoneOptionCell phone={school.phone} />
                            ) : (
                              <div style={{ margin: 0 }} />
                            )}
                          </CustomColumnContainer>
                        </DefaultColumnBody>

                        <DefaultColumnBody onClick={() => handleEditSchool(school)}>
                          <CustomColumnContainer noMargin>
                            <div>
                              <CustomName>{school.user.name}</CustomName>
                              <span>{school.user.email}</span>
                            </div>
                          </CustomColumnContainer>
                        </DefaultColumnBody>

                        <td>{school.created_at}</td>

                        <DefaultColumnBody>
                          <SchoolPlanOptionsCell school={school} isDropup={isOptionsShowerUp} />
                        </DefaultColumnBody>

                        <DefaultColumnBody>
                          <SchoolStatusOptionsCell school={school} isDropup={isOptionsShowerUp} />
                        </DefaultColumnBody>

                        <DefaultColumnBody>
                          <SchoolOptionsCell
                            onClick={() => handleEditSchool(school)}
                            isDropup={isOptionsShowerUp}
                          />
                        </DefaultColumnBody>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </ContentBody>

          <ContentFooter>
            <Pagination
              page={page}
              count={total}
              onChange={(_, currentPage) => setPage(currentPage)}
            />
          </ContentFooter>
        </section>
      </Container>

      <Modal
        show={createNewSchool || schoolToEdit}
        onClose={handleCloseModal}
        padding={0}
        noBorder
        contentHeader={
          schoolToEdit ? (
            <ModalHeader>
              <Avatar name={schoolToEdit.title} email={schoolToEdit.email} size={58} />

              <span>
                <Typography variant="h3">{schoolToEdit.title}</Typography>
                <Typography variant="body2">{schoolToEdit.created_at}</Typography>
              </span>
            </ModalHeader>
          ) : (
            <Typography variant="h3">Criar escola</Typography>
          )
        }
      >
        {createNewSchool && <SchoolFormModal onClose={handleCloseModal} />}
        {schoolToEdit && (
          <SchoolFormModal
            data={schoolToEdit}
            onSubmit={successfullyEdited}
            onClose={handleCloseModal}
          />
        )}
      </Modal>
    </>
  );
}

export default SchoolsList;
