import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api, { ClassesService } from '~/services/http';
import { convertProperties } from '~/utils';
import courseSlice from './course';

const TYPE = 'classroom';
const ENDPOINT = '/classes';

export const create = createAsyncThunk(`${TYPE}/create`, async (data) => {
  const response = await api.post(`${ENDPOINT}`, data);
  return { ...data, id: response.id };
});

export const fetchClass = createAsyncThunk(`${TYPE}/fetchClass`, async (id) => {
  const response = await api.get(`/content?class=${id}`);
  response.class.properties = convertProperties(response.class.options)
  return {...response };
});

export const remove = createAsyncThunk(`${TYPE}/delete`, async (id) => {
  const response = await api.delete(`${ENDPOINT}?id=${id}`);
  return response.id;
});

export const update = createAsyncThunk(`${TYPE}/update`, async (data) => {
  const response = await api.put(`${ENDPOINT}`, data);
  return { ...data, id: response.id };
});

export const sendRating = createAsyncThunk(`${TYPE}/sendRating`, async ({ id, rating }) => {
  ClassesService.setRating(id, rating);
  return rating;
});

export const sendView = createAsyncThunk(`${TYPE}/sendView`, async ({classId, moduleId}, { dispatch }) => {
  await ClassesService.setViewed(classId);
  dispatch(
    courseSlice.actions.setClassView({
      moduleId,
      classId
    }),
  );
  return new Date().toISOString();
});

export const removeView = createAsyncThunk(`${TYPE}/removeView`, async ({classId, moduleId}, { dispatch }) => {
  await api.post('/views', {
    unset: 1,
    id: classId,
    type: 'class',
  });
  dispatch(
    courseSlice.actions.setClassView({
      unset: 1,
      classId,
      moduleId
    }),
  );
  return new Date().toISOString();
});

export default createSlice({
  name: TYPE,
  initialState: { data: {}, loading: true },
  reducers: {},
  extraReducers: {
    [create.fulfilled]: (state, action) => {
      state.entities.push(action.payload);
    },
    [fetchClass.fulfilled]: (state, action) => {
      const data = action.payload;
      data.class.properties = convertProperties(data.class.options);
      state.data = data;
      state.loading = false;
    },
    [update.fulfilled]: (state, action) => {
      const index = state.entities.findIndex((item) => item.id === action.payload.id);
      state.entities[index] = action.payload;
      state.loading = false;
    },
    [remove.fulfilled]: (state, action) => {
      const index = state.entities.findIndex((item) => item.id === Number(action.payload.id));
      if (index !== -1) state.entities.splice(index, 1);
      state.loading = false;
    },
    [sendRating.fulfilled]: (state, action) => {
      state.data.class.rating = action.payload;
    },
    [sendView.fulfilled]: (state, action) => {
      state.data.class.watched = action.payload;
    },
    [removeView.fulfilled]: (state) => {
      if(state.data.class) {
        state.data.class.watched = false;
      }
    },
  },
});
