import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import api from '~/services/http';

const TYPE = 'tags';

export const fetchTags = createAsyncThunk(`${TYPE}/fetch`, async () => {
  const response = await api.get(`/tags`);
  return response.data;
});

export const createTag = createAsyncThunk(`${TYPE}/create`, async (tag) => {
  const response = await api.post(`/tags`, tag);
  return { id: response.id, tag: tag.tag_name };
});

const tagsAdapter = createEntityAdapter({
  selectId: (c) => c.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const tagsSelector = tagsAdapter.getSelectors((state) => state.tags);

const tagsSlice = createSlice({
  name: TYPE,
  initialState: tagsAdapter.getInitialState({ loading: false }),
  reducers: {},
  extraReducers: {
    [fetchTags.pending]: (state) => {
      state.loading = true;
    },
    [fetchTags.fulfilled]: (state, action) => {
      tagsAdapter.setAll(state, action.payload || []);
      state.loading = false;
    },
    [createTag.fulfilled]: (state, action) => {
      tagsAdapter.addOne(state, action.payload);
    },
  },
});

export default tagsSlice;
