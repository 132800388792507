import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '~/services/http';
import { convertProperties } from '~/utils';

function getClassesCounts(modules = []) {
  return modules.reduce(
    (acc, module) => {
      if (module.hidden) return acc;
      const classes = module.classes.filter((c) => !!c.watched);
      acc.completed += classes.length;
      acc.total += module.classes.length;
      return acc;
    },
    { total: 0, completed: 0 },
  );
}
function nextClasse(modules = []) {
  let next = null;
  if (modules.length > 0) {
    const [classes] = modules;
    // eslint-disable-next-line prefer-destructuring
    next = classes[0];
  }
  modules.find((module) => {
    const nClass = module.classes.find((c) => !c.watched);
    if (nClass) {
      next = nClass;
      next.module = module.id;
    }
    return nClass;
  });
  return next;
}

const TYPE = 'course';
const ENDPOINT = '/course';

export const create = createAsyncThunk(`${TYPE}/create`, async (data) => {
  const response = await api.post(`${ENDPOINT}`, data);
  return { ...data, id: response.id };
});

export const fetch = createAsyncThunk(`${TYPE}/fetch`, async (course) => {
  const response = await api.get(`${ENDPOINT}?id=${course}`);
  return response.data;
});

export const fetchContent = createAsyncThunk(`${TYPE}/fetchContent`, async (course) => {
  const response = await api.get(`/content?course=${course}`);
  return response;
});

export const remove = createAsyncThunk(`${TYPE}/delete`, async (id) => {
  const response = await api.delete(`${ENDPOINT}?id=${id}`);

  return response.id;
});

export const update = createAsyncThunk(`${TYPE}/update`, async (data) => {
  const response = await api.put(`${ENDPOINT}`, data);
  return { ...data, id: response.id };
});

export default createSlice({
  name: TYPE,
  initialState: { data: {}, loading: true },
  reducers: {
    setClassView: (state, action) => {
      const module = state.data.classesMap[action.payload.moduleId];
      module.forEach((c) => {
        if (c.id === action.payload.classId)
          c.watched = action.payload.unset === 1 ? false : new Date().toISOString();
      });
      if (action.payload.unset) {
        // eslint-disable-next-line
        state.data.course.classesCount.completed--;
      } else {
        // eslint-disable-next-line
        state.data.course.classesCount.completed++;
      }
      state.data.course.progress =
        (100 / state.data.course.classesCount.total) * state.data.course.classesCount.completed;
    },
    setOnboardingComplete: (state) => {
      state.data.onboarding[0].completed = new Date().toISOString();
    },
  },
  extraReducers: {
    [create.fulfilled]: (state, action) => {
      state.entities.push(action.payload);
    },
    [fetch.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [fetchContent.pending]: (state) => {
      state.loading = true;
    },
    [fetchContent.fulfilled]: (state, action) => {
      const data = { ...action.payload };
      data.course.properties = convertProperties(data.course.options);
      data.course.classesCount = getClassesCounts(data.modules);
      data.course.nextClass = nextClasse(data.modules);
      const classesMap = {};
      const moduleMap = {};
      data.modules.forEach((module) => {
        module.progress =
          (100 / module.classes.length) * module.classes.filter((c) => c.watched).length || 0;
        moduleMap[module.id] = module;
        classesMap[module.id] = module.classes;
      });
      data.classesMap = classesMap;
      data.moduleMap = moduleMap;
      state.data = data;
      state.loading = false;
      state.data.course.progress =
        (100 / state.data.course.classesCount.total) * state.data.course.classesCount.completed;
    },
    [update.fulfilled]: (state, { payload }) => {
      const index = state.entities.findIndex((item) => item.id === payload.id);
      state.entities[index] = { ...state.entities[index], ...payload };
      state.loading = false;
    },
    [remove.fulfilled]: (state, action) => {
      const index = state.entities.findIndex((item) => item.id === Number(action.payload.id));
      if (index !== -1) state.entities.splice(index, 1);
      state.loading = false;
    }
  },
});
