import { useContext } from 'react';

import { AuthContext } from '../contexts/admin';

export default function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within as AuthProvider');
  }

  return context;
}
