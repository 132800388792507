function createTagService(http) {
  const create = async (tag) => http.post(`/tags`, tag);
  const list = async (limit) => http.get(`/tags?limit=${limit}`);
  const update = async (tag) => http.put(`/tags`, tag);
  const remove = async (card) => http.delete(`/tags`, card);

  return {
    create,
    list,
    update,
    remove,
  };
}

export default createTagService;
