import React from 'react';
import styled, { css } from 'styled-components';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../Button';

export const StyledButton = styled(Button)`
  ${(props) =>
    props.loading &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}
`;

function ButtonLoading({ children, loading, ...rest }) {
  return (
    <StyledButton loading={loading} {...rest}>
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
    </StyledButton>
  );
}

export default ButtonLoading;
