import apollo from './apollo.api';
import cloudniray from './cloudinary.api';

import createTagService from './tags';
import createAuthService from './auth';
import createUserService from './users';
import createSchoolService from './school';
import createStudentService from './students';
import createCourseService from './courses';
import createClassesService from './classes';
import createModuleService from './modules';
import createFileServe from './files';

const TagService = createTagService(apollo);
const AuthService = createAuthService(apollo);
const UserService = createUserService(apollo);
const SchoolService = createSchoolService(apollo);
const CourseService = createCourseService(apollo);
const StudentService = createStudentService(apollo);
const ClassesService = createClassesService(apollo);
const ModuleService = createModuleService(apollo);

const FileService = createFileServe(cloudniray, apollo);

export { TagService, AuthService, UserService, SchoolService, CourseService, StudentService, ClassesService, ModuleService, FileService };

export default apollo;
