import React from 'react';
import styled from 'styled-components';

export const Link = styled(({ component, ...props }) => React.createElement(component, props))`
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.palette[props.color] || props.color};
  cursor: pointer;
  text-decoration: none;
`;

export default ({ children, href, color = 'primary', ...props }) => {
  return (
    <Link href={href} component={href ? 'a' : 'span'} color={color} {...props}>
      {children}
    </Link>
  );
};
