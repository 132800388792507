/* eslint-disable consistent-return */

const Snippets = {
  tags ({ id, events, dataLayer, dataLayerName, preview, auth }) {
    const gtm_auth = `&gtm_auth=${auth}`;
    const gtm_preview = `&gtm_preview=${preview}`;

    if (!id) {
      console.warn('[react-gtm]', 'GTM Id is required');
    }

    const iframe = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${id}${gtm_auth}${gtm_preview}&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(events).slice(
          1,
          -1,
        )}});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtm_auth}${gtm_preview}&gtm_cookies_win=x';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');`;

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

    return {
      iframe,
      script,
      dataLayerVar,
    };
  },
  dataLayer (dataLayer, dataLayerName) {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  },
};

export default {
  dataScript (dataLayer) {
    const script = document.createElement('script');
    script.innerHTML = dataLayer;
    return script;
  },
  gtm (args) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const documentScript = document.createElement('script');
      documentScript.innerHTML = snippets.script;
      return documentScript;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },
  initialize ({
    gtmId,
    events = {},
    dataLayer,
    dataLayerName = 'dataLayer',
    auth = '',
    preview = '',
  }) {
    const gtm = this.gtm({
      id: gtmId,
      events,
      dataLayer: dataLayer || undefined,
      dataLayerName,
      auth,
      preview,
    });
    if (dataLayer) document.head.appendChild(gtm.dataScript);
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
  dataLayer ({ dataLayer, dataLayerName = 'dataLayer' }) {
    if (window[dataLayerName]) return window[dataLayerName].push(dataLayer);
    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);
    document.head.insertBefore(dataScript, document.head.childNodes[0]);
  },
};
