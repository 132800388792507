import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import ButtonBase from '../Button';
import Modal from '../Modal';

export default function AlertDialog({
  show,
  title,
  description,
  children,
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  confirmButtonColor = 'error',
  onConfirm,
  onCancel,
}) {
  return (
    <div>
      <Modal title={title} show={show} onClose={onCancel} width="600" zIndex={1002}>
        <DialogContent>
          {children}
          {description && <DialogContentText>{description}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <ButtonBase onClick={onCancel} size="sm" color="default">
            {cancelText}
          </ButtonBase>
          <ButtonBase onClick={onConfirm} size="sm" color={confirmButtonColor} autoFocus>
            {confirmText}
          </ButtonBase>
        </DialogActions>
      </Modal>
    </div>
  );
}
