import styled from 'styled-components';

export const Container = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th,
  td {
    padding: 16px 8px;
    border-bottom: 1px solid rgba(224, 224, 224);

    font-size: 0.875rem;
    text-align: left;
  }

  thead {
    display: table-header-group;

    th {
      font-weight: 500;
    }
  }

  tbody {
    tr:hover {
      background-color: #F5F7F8;
    }

    td {
      color: rgba(0, 0, 0, 0.87);

      > div {
        display: flex;
        align-items: center;
      }
    }
  }
`;
