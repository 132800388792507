import React from 'react'
import { useField } from '@unform/core';

import SelectBase from '../Select';

export default function Select({ name, ...rest }) {
  const selectRef = React.useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      clearValue: (ref) => {
        ref.state.value = null
      },
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value;
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <SelectBase
      ref={selectRef}
      defaultValue={defaultValue}
      {...rest}
    />
  )
}
