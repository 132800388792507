function createStudentService(http) {
  const list = async (limit) => http.get(`/users?limit=${limit}`);
  const getSubscriptions = async () => http.get(`/subscriptions`);
  return {
    list,
    getSubscriptions,
  };
}

export default createStudentService;
