import React from 'react';

import AppBar from '~/components/AppBar';
import Typography from '~/components/Typography';

import SchoolsList from './SchoolsList';

import { Container, Content, BredCrumb, StyleBrand } from './styles';

function SuperDashboard() {
  return (
    <Container>
      <AppBar fixed>
        <BredCrumb color={null}>
          <StyleBrand color={null}>TE</StyleBrand>

          <span>
            <Typography color={null} variant="h6">
              Todas as Escolas
            </Typography>
          </span>
        </BredCrumb>
      </AppBar>

      <Content>
        <SchoolsList />
      </Content>
    </Container>
  );
}

export default SuperDashboard;
