/*eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Form } from '@unform/web';
import Gravatar from 'react-gravatar';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../Button';
import { TextArea } from '../../TextArea';
import Typography from '../../Typography';

import api from '~/services/api';
import uiSlice from '~/store/slices/ui';
import { useAuth } from '~/contexts/auth';

export const TextAreaWarraper = styled.div`
  width: 100%;
  position: relative;
`;

export const CommentText = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid #b0bec5;
`;

export const CommentArea = styled.div`
  display: flex;
  flex: 1;
`;

export const Avatar = styled.div`
  width: 38px;
  margin-left: 16px;
  img {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 170px 110px;
`;

const HelpHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 16px;
  .help__success {
    align-items: center;
  }
`;

const Success = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SuccessIcon = styled.div`
  font-size: 4rem;
  margin-bottom: 8px;
  color: ${(props) => props.theme.palette.success};
`;

export default function FormHelp({ school }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);

  const userFirstName = user.name.split(' ').shift();

  async function handleSubmit({ message }) {
    const token = JSON.parse(localStorage.getItem('user')).token;
    try {
      await api.post('/queue', {
        token,
        subject: `Suporte para aluno ${userFirstName}/${user.mail}`,
        message,
      });
      setSuccess(true);
    } catch {
      toast('Erro ao enviar.', {
        autoClose: 3000,
        type: 'error',
      });
    }
  }

  function handleClose() {
    dispatch(uiSlice.actions.closeHelpModal());
  }

  return (
    <div>
      {success ? (
        <Success>
          <SuccessIcon>
            <FontAwesomeIcon icon={faCheckCircle} />
          </SuccessIcon>
          <Typography gutterBottom="10px" variant="h3">
            {userFirstName}, sua mensagem foi enviada.
          </Typography>
          <Typography gutterBottom="20px" variant="body1">
            Em breve o nosso time entrará em contato.
          </Typography>
          <Button onClick={handleClose} size="sm" color="default" outline>
            Fechar
          </Button>
          <br />
          <br />
        </Success>
      ) : (
        <Form onSubmit={handleSubmit}>
          <HelpHeader>
            {/* <VdbAvatar /> */}
            <div className="help__success">
              <Typography color="g5" variant="body3">
                Time {school.title}
              </Typography>
              <Typography variant="body1">
                Oi {userFirstName}, como podemos te ajudar hoje?
              </Typography>
            </div>
          </HelpHeader>
          <CommentArea>
            <TextArea
              name="message"
              id=""
              cols="30"
              rows="7"
              placeholder="Escreva sua mensagem..."
              noBorder
            />
            <Avatar>
              <Gravatar email={user.mail} size={38} />
            </Avatar>
          </CommentArea>
          <Typography color="g5" gutterBottom="8px" variant="body3">
            Sua mensagem será enviada por e-mail e respondida em algumas horas.
          </Typography>
          <Buttons>
            <Button size="sm">Enviar mensagem</Button>
            <Button onClick={handleClose} size="sm" color="default" outline>
              Cancelar
            </Button>
          </Buttons>
        </Form>
      )}
    </div>
  );
}
