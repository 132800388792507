import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { getCloudinaryPath, getInitials } from '~/utils';

import { Container, Image, Logo } from './styles';

export default function Brand({ color, variant, ...props }) {
  const theme = useTheme();

  const [imageNotAvailable, setImageNotAvailable] = useState(false);

  const { title = 'Escola' } = useSelector((state) => state.school.data);

  const brandTitle = useMemo(() => (variant === 'menu' ? getInitials(title, true) : title), [
    title,
    variant,
  ]);

  return (
    <Container>
      {!imageNotAvailable ? (
        <Image
          {...props}
          variant={variant}
          src={getCloudinaryPath(theme.brand[variant || 'default'])}
          onError={() => setImageNotAvailable(true)}
          alt="logo"
        />
      ) : (
        <Logo color={color} variant={variant}>
          <span>{brandTitle}</span>
        </Logo>
      )}
    </Container>
  );
}
