import React, { useState, useReducer, useEffect, useContext, createContext } from 'react';

import api from '~/services/http';
import { getSiteUrl } from '~/utils';
import { formatSchoolProperties } from '~/utils/format';

export const SignInStyleContext = createContext({});

const defaultStyles = {
  loading: true,
  leftColumn: {
    visible: true,
    logo: {
      styles: {
        bgFile: null,
        bgImage: null,
        fontColor: '',
        textAlign: 'center',
      },
    },
    form: {
      title: 'Entrar',
      subtitle: 'Entre na sua área do aluno com seus dados de acesso.',
      anchor: {
        visible: false,
        content: '',
      },
      styles: {
        shadow: true,
        padding: true,
        bgColor: '',
        fontColor: '',
      },
    },
    styles: {
      bgFile: null,
      bgImage: null,
      bgColor: '',
    },
  },
  rightColumn: {
    visible: false,
    promo: {
      title: 'Título da promoção',
      subtitle: 'Descrição da promoção',
      visible: false,
      anchor: {
        content: '',
      },
      styles: {
        position: 'center',
        fontColor: '',
      },
    },
    styles: {
      bgFile: null,
      bgImage: null,
      bgColor: '',
    },
  },
};

function styleReducer(state, action) {
  switch (action.type) {
    case 'REQUEST_SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case 'RESET_STYLES':
      return {
        ...state,
        ...defaultStyles,
        loading: false,
      };

    case 'LEFT_COLUMN_STYLES':
      return {
        ...state,
        leftColumn: {
          ...state.leftColumn,
          ...action.payload,
        },
      };

    case 'LEFT_COLUMN_LOGO_STYLES':
      return {
        ...state,
        leftColumn: {
          ...state.leftColumn,
          logo: {
            ...state.leftColumn.logo,
            ...action.payload,
          },
        },
      };

    case 'LEFT_COLUMN_FORM_STYLES':
      return {
        ...state,
        leftColumn: {
          ...state.leftColumn,
          form: {
            ...state.leftColumn.form,
            ...action.payload,
          },
        },
      };

    case 'RIGHT_COLUMN_STYLES':
      return {
        ...state,
        rightColumn: {
          ...state.rightColumn,
          ...action.payload,
        },
      };

    case 'RIGHT_COLUMN_PROMO_STYLES':
      return {
        ...state,
        rightColumn: {
          ...state.rightColumn,
          promo: {
            ...state.rightColumn.promo,
            ...action.payload,
          },
        },
      };

    default:
      return state;
  }
}

export function SignInStyleProvider({ children }) {
  const [school, setSchool] = useState({});

  const [styles, setStyles] = useReducer(styleReducer, defaultStyles);

  useEffect(() => {
    async function getSchoolProperties() {
      const response = await api.get(`/schools?domain=${getSiteUrl()}`);

      const properties = formatSchoolProperties(response.properties);

      setSchool({
        id: response?.id || response?.data?.id,
        title: response?.title || response?.data?.title,
      });

      setStyles({ type: 'REQUEST_SUCCESS', payload: properties });
    }

    getSchoolProperties();
  }, []);

  return (
    <SignInStyleContext.Provider value={{ school, styles, setStyles }}>
      {children}
    </SignInStyleContext.Provider>
  );
}

export function useStyle() {
  const context = useContext(SignInStyleContext);
  return context;
}
