import React, { useState, useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import useEventListener from '~/hooks/useEventListener';

import { Container, OptionList, Option } from './styles';

function SchoolOptionsCell({ onClick, isDropup }) {
  const dropdownRef = useRef(null);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleToggleDropdown = useCallback(() => {
    setIsDropdownVisible((prevIsDropdownVisible) => !prevIsDropdownVisible);
  }, []);

  const handleCloseDropdown = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    },
    [setIsDropdownVisible],
  );

  const handleSubmit = useCallback(async () => {
    onClick();
    setIsDropdownVisible(false);
  }, [onClick]);

  useEventListener('click', handleCloseDropdown, {
    enabled: isDropdownVisible,
  });

  return (
    <Container ref={dropdownRef}>
      <button type="button" onClick={handleToggleDropdown}>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>

      <OptionList visible={isDropdownVisible} isDropup={isDropup}>
        <Option onClick={handleSubmit}>
          <span>Editar</span>
        </Option>
      </OptionList>
    </Container>
  );
}

export default SchoolOptionsCell;
