import styled, { css } from 'styled-components';
import { darken, transparentize } from 'polished';

export const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 !important;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;

    outline: none;

    cursor: pointer;
    border-radius: 30px;

    border: none;

    color: ${(props) => props.color};
    background: ${(props) => transparentize(0.8, props.color)};

    :hover {
      background: ${(props) => transparentize(0.6, props.color)};
    }

    font-size: 14px;
    font-weight: 600;

    svg {
      color: ${(props) => props.color};
      transition: fill 0.2s ease 0s;
    }
  }
`;

export const Content = styled.div`
  z-index: 2;

  position: absolute;
  top: calc(100% + 16px);
  right: calc(50% - 50px);

  display: flex;
  flex-direction: column;

  width: 150px;
  padding: 15px;
  border-radius: 5px;

  background: #fff;

  opacity: 0;
  visibility: hidden;

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 1.8rem;
    min-width: 90px;

    margin-top: 16px;
    padding: 0px 1rem;

    border-radius: 4px;
    line-height: inherit;

    outline: none;

    color: #546e7a;
    background: #fff;

    font-size: 14px;
    text-decoration: none;

    box-shadow: rgb(0 0 0 / 13%) 0px 0px 3px;
    cursor: pointer;

    transition: background 0.2s ease 0s, color 0.2s ease 0s, box-shadow 0.2s ease 0s;

    :hover {
      background: ${darken(0.05, '#fff')};
    }
  }

  ::before {
    position: absolute;
    top: -8px;
    right: 32px;

    content: '';

    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent #ffffff;
  }

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;
