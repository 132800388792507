import React from 'react';
import styled from 'styled-components';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheck } from '@fortawesome/free-solid-svg-icons'
// import { faComment,faStar } from '@fortawesome/free-regular-svg-icons'
import Typography from '../Typography';


import Avatar from '../Avatar';
import dayjs from '../../utils/day';


export const Container = styled.div`
  .item-student-row{
    width: 100%;
    display: grid;
    grid-template-columns: 50px calc(100% - 225px) 50px 50px 50px;
    gap: 5px;
    padding: 10px 0px;
    margin-bottom: 3px;
    .item-student-column-avatar{}
    .item-student-column-user{
      h5{
        font-weight: normal;
      }
      p{
        font-size: 14px;
        margin-top: 6px;
      }
    }
    .item-student-column-stats{
      padding-top: 11px;
      *[data-icon]{
        color: #90A4AE;
        margin-right: 6px;
      }
    }

    &.col-2{
      grid-template-columns: 50px calc(100% - 55px);
    }

    @media (max-width: 768px){
      .item-student-column-user{
        h5{
          font-size: 0.8rem;
        }
        p{
          font-size: 0.7rem;
        }
      }
    }
  }
`;

export default function ItemClass({
  avatar,
  name,
  registration,
  stats,
  ...props
}){
  return(
    <Container avatar={avatar} name={name} registration={registration} stats={stats} {...props}>
      <div className="item-student-row col-2">
        <div className="item-student-column-avatar">
          <Avatar email={avatar} name={name} />
        </div>
        <div className="item-student-column-user">
          <Typography variant="h5">{name}</Typography>
          <Typography variant="body1">
            Matriculado
            {' '}
            {dayjs(registration).from()}
          </Typography>
        </div>
        {/* <div className="item-student-column-stats">
          <Typography variant="body1">
            <FontAwesomeIcon icon={faCheck}  />
            {0}
          </Typography>
        </div>
        <div className="item-student-column-stats">
          <Typography variant="body1">
            <FontAwesomeIcon icon={faComment}  />
            {0}
          </Typography>
        </div>
        <div className="item-student-column-stats">
          <Typography variant="body1">
            <FontAwesomeIcon icon={faStar}  />
            {0}
          </Typography>
        </div> */}
      </div>
    </Container>
  );
}
