import useSWR from 'swr';

import api from '../services/api';

export default function useRequest(request, { initialData, ...config } = {}) {
  const requestKey = request && JSON.stringify(request);

  const { data: response, error, isValidating, revalidate, mutate } = useSWR(
    requestKey,
    () => api(request),
    {
      errorRetryCount: 3,
      errorRetryInterval: 5000,
      ...config,
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        config: request,
        headers: {},
        data: initialData,
      },
    },
  );

  return {
    data: response && response.data,
    requestKey: requestKey ?? '',
    response,
    error,
    isValidating,
    revalidate,
    mutate,
  };
}
