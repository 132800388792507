import React, { useEffect } from 'react';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { getCloudinaryPath } from '~/utils';

import Image from '../Image';
import AppBar from '../AppBar';
import IconButton from '../IconButton';

import { Container, CertificateBody, BackgroundImage } from './styles';

function formatDate() {
  return new Date().toLocaleDateString('pt-BR', { month: 'long', day: 'numeric', year: 'numeric' });
}

export default function Certificate({ school, course, user }) {
  const model = course?.properties?.certification_model ?? 'default';
  const text_align = course?.properties?.certification_text_align ?? 'default';

  const styles = ((course.legacy_id || text_align === 'center') && model !== 'default') ? {
    infoMarginTop: 50,
    footerMarginTop: 6,
  } : {
    infoMarginTop: 10,
    footerMarginTop: 38,
  }

  useEffect(() => {
    document.body.classList.add('hidden-beacon');
    return () => document.body.classList.remove('hidden-beacon');
  }, []);
  return (
    <Container>
      <AppBar noShadow className="print__hidden">
        <IconButton onClick={() => window.history.back()} icon={faArrowLeft} color="g7" />
      </AppBar>
      <CertificateBody
        styles={styles}
        model={model}
        color={course?.properties?.certification_txt_color}
        borderColor={course?.properties?.color_primary}
        background={course?.properties?.certification_bgImg}
        dateAlign={course?.properties?.certification_align || 'center'}
      >
        <div className="paper">
          {model === 'custom' && (
            <BackgroundImage>
              <img src={getCloudinaryPath(course?.properties?.certification_bgImg)} alt="teste" />
            </BackgroundImage>
          )}

          <div className="brand" />
          <div className="infos">
            {model === 'default' && <h3>Certificamos que o aluno</h3>}
            <h1>{user.name}</h1>
            {model === 'default' && (
              <>
                <h3>concluiu sua participação no curso:</h3>
                <h3>{course?.name}</h3>
              </>
            )}
          </div>
          <div className="signature">
            <Image src={course?.properties?.certification_signature} alt="" />
          </div>
          <div className="footer">
            {model === 'default' && (
              <div className="col time">
                Carga horária:
                {` ${course?.properties?.certification_hours} `}
              </div>
            )}
            {model === 'default' && (
              <div className="col">
                <Image src={school?.brand_default} alt="Logo da escola" />
              </div>
            )}
            <div className="col date">{formatDate()}</div>
          </div>
        </div>
      </CertificateBody>
    </Container>
  );
}
