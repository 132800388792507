import React from 'react';
import styled from 'styled-components';

import Typography from '../Typography';
import { getCloudinaryPath } from '~/utils';

const width = 208;

const Container = styled.div`
  .course-cover {
    max-width: ${width}px;
    height: ${width * 1.2}px;
    overflow: hidden;
    position: relative;
    background-color: ${(props) => props.color};
    border-radius: 5px;
    .cover-brand {
      content: ' ';
      top: 50%;
      left: 50%;
      z-index: 10;
      width: 80%;
      height: 24%;
      position: absolute;
      transform: translate(-50%, -50%);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(${(props) => getCloudinaryPath(props.coverImage)});
    }
    .cover-title {
      content: ' ';
      top: 50%;
      left: 50%;
      z-index: 10;
      width: 80%;
      position: absolute;
      transform: translate(-50%, -50%);
      h3 {
        color: white;
        line-height: 27px;
      }
    }
    .cover-overlay {
      content: ' ';
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 1;
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url(${(props) => getCloudinaryPath(props.coverImage)});
    }
  }
`;

export default function CourseCover({
  coverImage,
  title,
  overlay,
  coverPosition = 'filled',
  ...props
}) {
  return (
    <Container coverImage={coverImage} title={title} {...props}>
      <div className="course-cover">
        {!coverImage && (
          <div className="cover-title">
            <Typography variant="h3" gutterBottom="0px">
              {title}
            </Typography>
          </div>
        )}
        {coverImage && coverPosition === 'center' ? (
          <div className="cover-brand" />
        ) : (
          <div className="cover-overlay" />
        )}
      </div>
    </Container>
  );
}
