import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { createMuiTheme, ThemeProvider as ThemeProviderMui } from '@material-ui/core/styles';

import PageLoading from '../PageLoading';
import defaultTheme from '../../styles/themes/default';
import { fetchSchool } from '~/store/slices/school';

export default function ApolloTheme({ children }) {
  const dispatch = useDispatch();
  const {
    data: { color, brand },
    loading,
  } = useSelector((state) => state.school);

  useEffect(() => {
    dispatch(fetchSchool());
  }, [dispatch]);

  const newPalette = React.useMemo(() => {
    if (localStorage.getItem('@APOLLO:THEME_COLOR')) {
      return {
        ...defaultTheme.palette,
        ...JSON.parse(localStorage.getItem('@APOLLO:THEME_COLOR')),
      };
    }

    return { ...defaultTheme.palette, ...color };
  }, [color]);

  const newBrand = React.useMemo(() => {
    if (localStorage.getItem('@APOLLO:THEME_BRAND')) {
      return {
        ...defaultTheme.brand,
        ...JSON.parse(localStorage.getItem('@APOLLO:THEME_BRAND')),
      };
    }

    return { ...defaultTheme.brand, ...brand };
  }, [color]);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: newPalette.primary || '#000',
      },
      secondary: {
        main: newPalette.secondary || '#000',
      },
    },
    typography: {
      fontFamily: "'Open sans', sans-serif",
    },
  });

  return (
    <ThemeProviderMui theme={theme}>
      <ThemeProvider theme={{ ...defaultTheme, palette: newPalette, brand: newBrand }}>
        {loading ? <PageLoading /> : children}
      </ThemeProvider>
    </ThemeProviderMui>
  );
}
