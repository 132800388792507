function createModuleService(http) {
  const create = async (course) => http.post(`/modules`, course);
  const update = async (course) => http.put(`/modules`, course);
  const getByCourseId = async (id) => http.get(`/modules?course=${id}`);

  return {
    create,
    update,
    getByCourseId,
  };
}

export default createModuleService;
