import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useField } from '@unform/core';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.8rem;
`;

export const Color = styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 50%;
  background-color: ${(props) => props.color};

  &:hover {
    opacity: 1;
  }

  ${(props) =>
    props.selected &&
    css`
      opacity: 0.35;
    `};

  ${(props) =>
    props.selected &&
    props.selected === props.color &&
    css`
      opacity: 1;
    `};

  ${(props) =>
    props.color === '#FFFFFF' &&
    css`
      border: 0.2px solid #616161;
    `}
`;

export const Label = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const InputWarper = styled.div`
  overflow: hidden;
  width: 0px;
  height: 0px;
  border: none;
`;

const defaultColors = [
  '#AD1457',
  '#EC407A',
  '#AB47BC',
  '#7E57C2',
  '#5C6BC0',
  '#42A5F5',
  '#29B6F6',
  '#26C6DA',
  '#26A69A',
  '#66BB6A',
  '#9CCC65',
  '#C0CA33',
  '#FBC02D',
  '#FB8C00',
  '#F4511E',
  '#795548',
  '#616161',
  '#263238',
  '#ECEFF1',
  '#FFFFFF',
];

export default function Colors({ color, label, initialColor, name = 'color', onSelect, disableUseEffect }) {
  const [colors, setColors] = useState(defaultColors);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => color,
      setValue: () => {
        onSelect(color);
      },
      clearValue: () => onSelect(null),
    });
  }, [color, fieldName, registerField, colors, initialColor, onSelect]);

  function handleSelect(c) {
    onSelect(c);
  }

  useEffect(() => {
    if (!colors.includes(initialColor)) setColors(colors.concat(initialColor));
    if (!disableUseEffect) onSelect(initialColor || defaultValue);
  }, []);

  return (
    <Container>
      <Label>{label}</Label>
      {colors.map((c) => (
        <Color key={`color-${c}`} onClick={() => handleSelect(c)} color={c} selected={color} />
      ))}
    </Container>
  );
}
