import React, { useState } from 'react';
import ConfirmDialogComponent from '../components/ConfirmDialog';

function useConfirmDialog() {
  const [show, setShow] = useState();
  const [title, setTitle] = useState();
  const [color, setColor] = useState('error');
  const [description, setDescription] = useState();
  const [onConfirm, setOnConfirm] = useState();

  const ConfirmDialog = (
    <ConfirmDialogComponent
      title={title}
      confirmButtonColor={color}
      description={description}
      show={show}
      onConfirm={() => {
        setShow(false);
        onConfirm.resolve(true);
      }}
      onCancel={(e) => {
        e.preventDefault();
        setShow(false);
        onConfirm.reject();
      }}
    />
  );

  function Confirm(confirmTitle, confirmDescription, buttonColor = 'error') {
    setColor(buttonColor);
    setShow(true);
    setTitle(confirmTitle);
    setDescription(confirmDescription);
    return new Promise((resolve, reject) => {
      setOnConfirm(() => {
        return {
          resolve,
          reject,
        };
      });
    });
  }

  return {
    show,
    setShow,
    ConfirmDialog,
    Confirm,
  };
}

export default useConfirmDialog;
