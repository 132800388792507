import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children, error } = this.props;
    if (hasError) {
      return error || <span>Algo deu errado.</span>;
    }
    return children;
  }
}

export default ErrorBoundary;
