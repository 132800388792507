
import { datadogLogs } from '@datadog/browser-logs';

if (process.env.NODE_ENV === 'production') {
  datadogLogs.init({
    clientToken: 'puba878a6b34c57dcb213f76c4e44f01702',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
}
