import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  height: 100vh;
  width: 100%;
  font-size: 4em;
  z-index: 1;
  span {
    animation-name: ${animation};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

function PageLoading() {
  return (
    <Container>
      <div>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    </Container>
  );
}

export default PageLoading;
