/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

export default function (props) {
  return (
    <div>
      <Rating
        {...props}
        emptySymbol={(
          <FontAwesomeIcon color="#B0BEC5" icon={faStar} />
          )}
        fullSymbol={(
          <FontAwesomeIcon color="#FBC02D" icon={faStar} />
        )}
      />
    </div>
  );
}
