import React, { useState, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import LinearProgress from '@material-ui/core/LinearProgress';

import { FileService } from '../../services/http';
import useConfirmDialog from '~/hooks/useConfirmDialog';

import ImageUpload from '../ImageUpload';
import FileIcon from '~/components/FileIcon';

import { humanFileSize } from '~/utils';

import { Container, UploadProgress, Preview } from './styles';

export default function UploadFile({ onSubmit, data }) {
  const { Confirm, ConfirmDialog } = useConfirmDialog();

  const [progress, setProgress] = useState(0);
  const [preview, setPreview] = useState(data ?? null);

  const handleSubmit = useCallback(async ([file]) => {
    if (!file) return;

    try {
      const url = await FileService.uploadS3(file, (event) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      });

      setProgress(0);
      setPreview({ file: url, name: file.name, size: humanFileSize(file.size) });

      onSubmit({ file: url, name: file.name, size: humanFileSize(file.size) });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleRemoveSubmit = useCallback(async () => {
    await Confirm('Remover arquivo', 'Tem certeza que deseja remover esse arquivo?');
    setPreview(null);
  }, []);

  return (
    <Container>
      {progress > 0 && (
        <UploadProgress>
          <LinearProgress variant="determinate" value={progress} />
        </UploadProgress>
      )}

      {preview ? (
        <Preview>
          <FileIcon file={preview.file} />

          <div>
            <span>{preview.name}</span>
            <small>{preview.size}</small>
          </div>

          <button type="button" onClick={handleRemoveSubmit}>
            <FontAwesomeIcon icon={faTimes} color="#FF5252" />
          </button>
        </Preview>
      ) : (
        <ImageUpload onFile={handleSubmit} />
      )}

      {ConfirmDialog}
    </Container>
  );
}
