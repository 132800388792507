import {
  createAsyncThunk,
  createSlice,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from '~/services/http';

const TYPE = 'modules';

export const createModule = createAsyncThunk(`${TYPE}/create`, async (data) => {
  const response = await api.post('/modules', data);
  return { ...data, id: response.id };
});

export const fetchModules = createAsyncThunk(`${TYPE}/fetch`, async (course) => {
  const response = await api.get(`/modules?course=${course}`);
  return response.data;
});

export const deleteModule = createAsyncThunk(`${TYPE}/delete`, async (id) => {
  const response = await api.delete(`/modules?id=${id}`);
  return response;
});

export const updateModule = createAsyncThunk(`${TYPE}/update`, async (data) => {
  const response = await api.put(`/modules`, data);
  return { ...data, id: response.id };
});

export const reorderModules = createAsyncThunk(`${TYPE}/reorderModules`, async (data) => {
  api.post(`/massive`, {
    course: data.course,
    modules: data.order,
  });
  return data.order;
});

const modulesAdapter = createEntityAdapter({
  selectId: (c) => c.id,
  sortComparer: (a, b) => a.position - b.position,
});

export const modulesSelector = modulesAdapter.getSelectors((state) => state.modules);

const courseSlice = createSlice({
  name: TYPE,
  initialState: modulesAdapter.getInitialState({ loading: true }),
  reducers: {},
  extraReducers: {
    [createModule.fulfilled]: (state, { payload }) => {
      modulesAdapter.addOne(state, payload);
    },
    [fetchModules.fulfilled]: (state, { payload }) => {
      modulesAdapter.setAll(state, payload);
      state.loading = false;
    },
    [updateModule.fulfilled]: (state, { payload }) => {
      modulesAdapter.updateOne(state, payload);
      state.loading = false;
    },
    [reorderModules.fulfilled]: (state, { payload }) => {
      payload.forEach((id, index) => {
        state.entities[id].position = index + 1;
      });
    },
    [deleteModule.fulfilled]: (state, { payload }) => {
      modulesAdapter.removeOne(state, payload.id);
      state.loading = false;
    },
  },
});

export const visibleModulesSelector = createSelector(modulesSelector.selectAll, (modules) =>
  modules.filter((m) => !m.hidden),
);

export default courseSlice;
