import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

// components
import Modal from '../components/Modal';
import FormHelp from '../components/forms/FormHelp';

import uiSlice from '~/store/slices/ui';

const Container = styled.div`
  .wrapper {
    min-height: 100vh;
    display: flex;
    flex-flow: column nowrap;
  }
  .columns,
  .content {
    display: flex;
    flex: 1;
  }
  .main {
    display: flex;
    flex-flow: column;
    flex: 1;
    order: 2;
    position: relative;
  }
  @media (max-width: 768px) {
    .columns,
    .content {
      flex-flow: column;
    }
  }
`;

export default function MainLayout({ children }) {
  const dispatch = useDispatch();
  const showHelp = useSelector((state) => state.ui.modal.help);
  const school = useSelector((state) => state.school.data);

  function handleCloseHelp() {
    dispatch(uiSlice.actions.closeHelpModal());
  }

  useEffect(() => {
    document.title = school.title
  }, [school])

  return (
    <Container>
      <Modal title="Preciso de ajuda" onClose={handleCloseHelp} show={showHelp} zIndex={99999}>
        <FormHelp school={school} />
      </Modal>
      <div className="wrapper">
        <div className="content">
          <div className="columns">{children}</div>
        </div>
      </div>
    </Container>
  );
}
