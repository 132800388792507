import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import api from '~/services/http';
import { parseChartData } from '~/utils';

const TYPE = 'courses';

export const createCourse = createAsyncThunk(`${TYPE}/create`, async (data) => {
  const response = await api.post('/courses', { ...data });
  return { ...data, id: response.id };
});

export const fetchCourses = createAsyncThunk(`${TYPE}/fetch`, async () => {
  const response = await api.get(`/courses`);
  return response.data;
});

export const fetchCourseMetrics = createAsyncThunk(`${TYPE}/fetchCourseMetrics`, async (id) => {
  const { metrics } = await api.get(`/metrics?course=${id}`);
  return {
    id,
    ...metrics,
    chart_churn: parseChartData(metrics.chart_churn, 'users'),
    chart_users: parseChartData(metrics.chart_users, 'users'),
    chart_comments: parseChartData(metrics.chart_comments, 'comments'),
  };
});

export const removeCourse = createAsyncThunk(`${TYPE}/remove`, async (id) => {
  const response = await api.delete(`/courses?id=${id}`);
  return response;
});

export const updateCourses = createAsyncThunk(`${TYPE}/update`, async (data) => {
  const response = await api.put(`/courses`, { ...data });
  toast('Curso atualizado!', {
    autoClose: 3000,
    type: 'info',
  });
  return { ...data, id: response.id };
});

export const reorderCourses = createAsyncThunk(`${TYPE}/reorder`, async (order) => {
  api.post(`/reorder`, {
    courses: order,
  });
  return order;
});

const coursesAdapter = createEntityAdapter({
  selectId: (c) => c.id,
  sortComparer: (a, b) => a.position - b.position,
});

export const coursesSelector = coursesAdapter.getSelectors((state) => state.courses);

const courseSlice = createSlice({
  name: TYPE,
  initialState: coursesAdapter.getInitialState({ loading: true }),
  reducers: {
    changeCourseCategory(state, { payload }) {
      state.entities[payload.courseId].category = payload.categoryId;
      api.put('/courses', state.entities[payload.courseId])
    },
  },
  extraReducers: {
    [createCourse.fulfilled]: (state, { payload }) => {
      coursesAdapter.addOne(state, payload);
    },
    [fetchCourses.fulfilled]: (state, { payload }) => {
      coursesAdapter.setAll(state, payload || []);
      state.loading = false;
    },
    [fetchCourseMetrics.fulfilled]: (state, { payload }) => {
      state.entities[payload.id].metrics = payload;
    },
    [updateCourses.fulfilled]: (state, { payload }) => {
      const { properties, tags, ...rest } = payload;
      if (properties) {
        state.entities[payload.id].properties = {
          ...state.entities[payload.id].properties,
          ...properties,
        };
      }
      if (tags) {
        state.entities[payload.id].tags = tags.filter((t) => Boolean(t.active));
      }
      state.entities[payload.id] = { ...state.entities[payload.id], ...rest };
      state.loading = false;
    },
    [removeCourse.fulfilled]: (state, { payload }) => {
      coursesAdapter.removeOne(state, payload.id);
    },
    [reorderCourses.fulfilled]: (state, action) => {
      action.payload.forEach((id, index) => {
        state.entities[id].position = index + 1;
      });
    },
  },
});

export default courseSlice;
