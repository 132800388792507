import React, { useState, useMemo, useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSadTear, faLock } from '@fortawesome/free-solid-svg-icons';

import api from '~/services/api';
import useEventListener from '~/hooks/useEventListener';

import { Container, OptionList, Option } from './styles';

function SchoolStatusOptionsCell({ school, isDropup }) {
  const dropdownRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(school.status.name);

  const handleToggleDropdown = useCallback(() => {
    setIsDropdownVisible((prevIsDropdownVisible) => !prevIsDropdownVisible);
  }, []);

  const handleCloseDropdown = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    },
    [setIsDropdownVisible],
  );

  const handleSubmit = useCallback(
    async (status) => {
      try {
        setIsLoading(true);

        await api.put(`/colleges/${school.id}/status`, { statusId: status.id });

        setSelectedStatus(status.name);
        setIsDropdownVisible(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [school],
  );

  useEventListener('click', handleCloseDropdown, {
    enabled: isDropdownVisible,
  });

  const options = useMemo(
    () => [
      { id: 1, name: 'Ativo', color: '#00C853', icon: faCheck },
      { id: 0, name: 'Bloqueado', color: '#FF5252', icon: faLock },
      { id: 2, name: 'Cancelado', color: '#546E7A', icon: faSadTear },
    ],
    [],
  );

  const selectedColor = useMemo(
    () => options.find((schoolPlan) => schoolPlan.name === selectedStatus).color,
    [options, selectedStatus],
  );

  const selectedIcon = useMemo(
    () => options.find((schoolPlan) => schoolPlan.name === selectedStatus).icon,
    [options, selectedStatus],
  );

  return (
    <Container ref={dropdownRef} color={selectedColor}>
      <button type="button" onClick={handleToggleDropdown}>
        {isLoading ? (
          'Atualizando..'
        ) : (
          <>
            <FontAwesomeIcon icon={selectedIcon} />
            {selectedStatus}
          </>
        )}
      </button>

      <OptionList visible={isDropdownVisible} isDropup={isDropup}>
        {options.map((option) => (
          <Option key={option.id} color={option.color} onClick={() => handleSubmit(option)}>
            <span>{option.name}</span>
          </Option>
        ))}
      </OptionList>
    </Container>
  );
}

export default SchoolStatusOptionsCell;
