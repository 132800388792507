import React, { useMemo } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import { CustomColumnContainer } from '../styles';

export default function ShimmerTableBody() {
  const rows = useMemo(() => Array.from({ length: 25 }, (_, key) => key), []);

  return (
    <tbody>
      {rows.map((row) => (
        <tr key={row}>
          <td>
            <div>
              <CustomColumnContainer>
                <Skeleton variant="circle" width={40} height={40} />

                <div>
                  <Skeleton width={100} height={20} />
                  <Skeleton width={200} height={20} />
                </div>
              </CustomColumnContainer>
            </div>
          </td>

          <td>
            <div>
              <Skeleton variant="circle" width={24} height={24} />
            </div>
          </td>

          <td>
            <div>
              <CustomColumnContainer>
                <div>
                  <Skeleton width={100} height={20} />
                  <Skeleton width={200} height={20} />
                </div>
              </CustomColumnContainer>
            </div>
          </td>

          <td>
            <Skeleton width={100} height={20} />
          </td>

          <td>
            <div style={{ justifyContent: 'center' }}>
              <Skeleton width={250} height={20} />
            </div>
          </td>

          <td>
            <div style={{ justifyContent: 'center' }}>
              <Skeleton width={250} height={20} />
            </div>
          </td>

          <td>
            <div>
              <Skeleton variant="circle" width={30} height={30} />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
}
