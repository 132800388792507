/* eslint-disable react/forbid-prop-types */
import React, { useState, useCallback, useRef, useMemo } from 'react';

import { Scope as UnFormScope } from '@unform/core';
import * as Yup from 'yup';

import { toast } from 'react-toastify';

import api from '~/services/api';
import countries from '~/utils/ddi';
import getValidationErrors from '~/utils/getValidationErrors';

import { Button, Input } from '~/components';
import Select from '~/components/unform/Select';

import { Form, Scope, ButtonsContainer } from './styles';

function SchoolFormModal({ data: school, onSubmit, onClose }) {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [subDomain, setSubDomain] = useState('');

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          user: Yup.object({
            name: Yup.string().required('O nome do gestor é obrigatório.'),
            email: Yup.string()
              .email('O e-mail do gestor deve ser um e-mail válido.')
              .required('O e-mail do gestor é obrigatório'),
          }),
          slug: Yup.string()
            .matches(/[^a-z0-9]/gi, 'O slug inválido')
            .test('slug', 'Slug não disponível', (value) => {
              return new Promise((resolve) =>
                api.get('/slug', { params: { slug: value } }).then((response) => {
                  return response.data.status === 200 ? resolve(false) : resolve(true);
                }),
              );
            })
            .required('O subdomío é obrigatório'),
          title: Yup.string().required('O título é obrigatório.'),
          phone: Yup.string().length(11, 'O número de telefone deve conter 11 digitos'),
          status: Yup.object({
            id: Yup.number('O status deve ser um valor númerico.').required(
              'O status é obrigatório.',
            ),
          }),
          plan: Yup.object({
            id: Yup.number('O plano deve ser um valor númerico.').required(
              'O plano é obrigatório.',
            ),
          }),
        });

        await schema.validate(data, { abortEarly: false });

        const payload = school?.id
          ? {
              ...school,
              ...data,
              ...(data.phone && { phone: `${data.ddi.dial_code}${data.phone}` }),
            }
          : data;

        if (school && school.id) {
          await api.put(`/colleges/${school.id}`, payload);
        } else {
          await api.post('/colleges', payload);
        }

        if (onSubmit) {
          onSubmit(payload);
        }

        onClose();

        const message = school?.id ? 'Escola editada com sucesso!' : 'Escola criada com sucesso!';

        toast(message, { autoClose: 3000, type: 'success' });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current.setErrors(errors);

          return;
        }

        const message =
          error?.response && error.response.status !== 500
            ? error.response.data.message
            : 'Desculpe, mas ocorreu algum erro ao se conectar com o servidor.';

        toast(message, { autoClose: 3000, type: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [school, toast],
  );

  const handleInputChange = useCallback((event) => {
    const value = event.target.value || '';
    setSubDomain(value.replace(/[^a-z0-9]/gi, ''));
  }, []);

  const defaultValue = useMemo(() => {
    if (school) {
      return {
        ...school,
        phone:
          school.phone?.length > 11
            ? school.phone.slice(school.phone.length - 11, school.phone.length)
            : school.phone,
      };
    }

    return {
      plan: {
        id: 1,
        name: 'Trial',
      },
      status: {
        id: 1,
        name: 'Ativo',
      },
    };
  }, [school]);

  const schoolsPlans = useMemo(
    () => [
      { id: 1, name: 'Trial' },
      { id: 2, name: 'Essencial' },
      { id: 3, name: 'Pro' },
      { id: 4, name: 'Escala' },
      { id: 5, name: 'Custom' },
    ],
    [],
  );

  const schoolsStatus = useMemo(
    () => [
      { id: 1, name: 'Ativo' },
      { id: 0, name: 'Bloqueado' },
      { id: 2, name: 'Cancelado' },
    ],
    [],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={defaultValue}>
      <UnFormScope path="user">
        {school && <Input type="hidden" name="id" style={{ display: 'none' }} />}

        <Input name="name" placeholder="Nome do cliente" />
        <Input type="email" name="email" placeholder="Email do gestor" />
      </UnFormScope>

      <Scope>
        <div style={{ flex: 1, marginRight: 16 }}>
          <Select
            name="plan"
            placeholder="Qualquer plan"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            options={schoolsPlans}
            noOptionsMessage={() => 'Nenhum plano cadastrado.'}
          />
        </div>

        <div style={{ flex: 1, marginLeft: 16 }}>
          <Select
            name="status"
            placeholder="Qualquer status"
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            options={schoolsStatus}
            noOptionsMessage={() => 'Nenhum status cadastrado.'}
          />
        </div>
      </Scope>

      <Select
        name="ddi"
        placeholder="DDI"
        getOptionValue={(option) => option.dial_code}
        getOptionLabel={(option) => `${option.flag} ${option.name}`}
        options={countries}
        noOptionsMessage={() => 'Nenhum DDI cadastrado.'}
      />

      <Input type="tel" name="phone" placeholder="Telefone com DDD" />

      <Input name="title" placeholder="Nome da escola" onChange={handleInputChange} />

      <Scope>
        <Input name="slug" placeholder="Subdomnio" onChange={handleInputChange} value={subDomain} />
        <p>.aluno.vc</p>
      </Scope>

      <br />

      <ButtonsContainer>
        <Button type="submit" color="primary" size="sm">
          {loading ? 'Salvando..' : 'Salvar'}
        </Button>

        <Button color="white" size="sm" onClick={onClose}>
          Cancelar
        </Button>
      </ButtonsContainer>
    </Form>
  );
}

export default SchoolFormModal;
