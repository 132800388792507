function createCourseService(http) {
  const create = async (course) => http.post(`/courses`, course);
  const update = async (course) => http.put(`/courses`, course);
  const createCard = async (card) => http.post(`/cards`, card);
  const updateCard = async (card) => http.put(`/cards`, card);
  const deleteCard = async (id) => http.post(`/cards?id=${id}`);
  const createOnboarding = async (onboarding) => http.post(`/onboarding`, onboarding);
  const updateOnboarding = async (onboarding) => http.put(`/onboarding`, onboarding);
  const getOnboarding = async (id) => {
    const result = await http.get(`/onboarding?course=${id}`);
    return result.data ? result.data[0] : {};
  };
  const getAll = async () => http.get(`/courses?start=1`);
  const getContentById = async (id) => http.get(`/content?course=${id}`);

  return {
    create,
    update,
    createCard,
    updateCard,
    deleteCard,
    createOnboarding,
    updateOnboarding,
    getOnboarding,
    getAll,
    getContentById,
  };
}

export default createCourseService;
