import styled from 'styled-components';
import { Form as Unform } from '@unform/web';

import { getCloudinaryPath } from '~/utils';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background-image: url(${(props) => getCloudinaryPath(props.bgImg)});
  background-color: ${(props) => props.bgColor || '#fafafa'};
  background-repeat: no-repeat;
  background-size: cover;

  .login__error {
    margin-bottom: 8px;
    padding: 8px 0;
    border-radius: 4px;
    background-color: #fff;
  }
`;

export const BrandContainer = styled.div`
  text-align: center;
`;

export const FormContainer = styled(Unform)`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 300px;

  img {
    width: 180px;
    margin-bottom: 26px;
  }

  h1 + p {
    line-height: 23px;
  }

  div[type='email'] {
    margin-bottom: 18px;
  }

  div[type='email'] {
    margin-bottom: 10px;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    margin-top: 36px;
  }
`;
