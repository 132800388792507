import styled from 'styled-components';

import BaseAvatar from '~/components/Avatar';

export const Container = styled.article`
  display: flex;

  form {
    flex: 1;
    display: flex;
  }

  :not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const Content = styled.div`
  width: calc(100% - 56px);

  font-family: 'Open Sans', sans-serif;

  hr {
    height: 1px;

    margin-top: 16px;
    margin-bottom: 8px;

    background: #eceff1;
  }

  strong {
    margin-bottom: 8px;

    line-height: 19px;

    color: #000000;

    font-size: 14px;
    font-weight: bold;

    time {
      margin-left: 8px;

      color: #78909c;

      font-size: 12px;
      font-weight: 400;
    }
  }

  p {
    margin-bottom: 8px;

    color: #000000;

    font-size: 14px;
    font-weight: 400;

    white-space: pre-wrap;

    word-wrap: break-word;
    word-break: break-all;
  }

  button {
    color: #607d8b;
    background: transparent;

    font-size: 12px;
    font-weight: 400;

    :hover {
      cursor: pointer;

      opacity: 0.9;
    }

    svg {
      margin-right: 4px;
    }
  }
`;

export const Avatar = styled(BaseAvatar)`
  margin-right: 16px;
`;
