import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export const Container = styled.div`
  .MuiButton-label{
    svg{
      margin: 8px;
    }
  }
`;

const getOptions = (options = [], onClick) => {
  return options.map(el => {
    return(
      <MenuItem
        key={el.label}
        onClick={() => {
        onClick();
        el.function();
      }}
      >
        {el.label}

      </MenuItem>
    );
  });
}

export default function MenuContext({
  label,
  icon,
  options,
  ...props
}){
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return(
    <Container className="menu-context" label={label} icon={icon} options={options} {...props}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {label}
        {icon &&
          <FontAwesomeIcon icon={faChevronDown} />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getOptions(options, handleClose)}
      </Menu>
    </Container>
  );
}
