import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.th`
  padding: 16px;

  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;

  button {
    display: flex;
    align-items: center;

    border: 0;

    outline: none;

    color: #546e7a;
    background: transparent;

    font-size: 0.875rem;

    svg {
      margin-left: 8px;
    }

    :hover {
      cursor: pointer;

      svg {
        color: ${transparentize(0.2, '#546E7A')};
      }
    }
  }
`;
