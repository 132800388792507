/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

const Label = styled.label`
  position: absolute;
  bottom: 26px;
  font-size: 14px;
  /* margin-bottom: 5px; */
  color: ${({ theme }) => theme.palette.g7};
  &.empty{
    transition: 0.2s ease all;
  }
`;

const Input = styled.input`
  color: ${(props) => props.theme.palette.text.g10};
  outline: none;
  font-size: ${(props) => props.size || 17}px;
  font-weight: 300;
  padding: 8px 0;
  background: transparent;
  border-radius: 0;
  border-bottom: 1.2px solid ${({ theme }) => theme.palette.g2};
  margin-bottom: 10px;
  &:focus ~ ${Label} {
    bottom: 45px;
  }
  &[type="date"] {
    & ~ ${Label} {
        bottom: 45px;
    }
  }
  ${(props) =>
    props.filled &&
    css`
      & ~ ${Label} {
        bottom: 45px;
      }
    `}
  ${(props) =>
    props.upper &&
    css`
      text-transform: uppercase;
    `}
    ${(props) =>
      props.simple &&
      css`
        border-bottom: none;
      `}
    ${(props) =>
      props.disabled &&
      css`
        color: rgba(100, 100, 100, 0.5);
      `}
`;
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
  ${Input} {
    &:focus {
      color: ${(props) => props.theme.palette.text.primary};
      border-bottom-color: ${(props) => props.theme.palette.primary};
      & ~ ${Label} {
        color: ${(props) => props.theme.palette.primary};
      }
    }
  }
  ${(props) =>
    props.noMargin &&
    css`
      margin-bottom: 0;
    `}
`;

export default React.forwardRef(({ label, ...props }, ref) => {
  const [filled, setFilled] = useState(false);
  const labelClass = (filled === false ? 'empty' : '');

  useEffect(() => {
    if (ref?.current.value !== '') setFilled(true);
  }, [ref]);

  function onBlur(e) {
    e.currentTarget.value === '' ? setFilled(false) : setFilled(true);
  }

  function focus() {
    ref.current.focus();
  }

  return (
    <Wrapper {...props}>
      <Input ref={ref} filled={filled} onBlur={onBlur} {...props} />
      <Label onClick={focus} className={labelClass}>{label}</Label>
    </Wrapper>
  );
})
