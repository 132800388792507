import React, { useState, useCallback, useRef, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, OptionList, Option } from './styles';

export default function EditableOptions({ icon, title, position, children }) {
  const dropdownRef = useRef(null);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleToggleDropdown = useCallback(() => {
    setIsDropdownVisible((prevState) => !prevState);
  }, []);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Container ref={dropdownRef}>
      {icon ? (
        <Option type="button" onClick={handleToggleDropdown}>
          <FontAwesomeIcon icon={icon} />
        </Option>
      ) : (
        <button type="button" onClick={handleToggleDropdown}>
          {title ?? 'Opções'}
        </button>
      )}

      <OptionList visible={isDropdownVisible} position={position}>
        {children}
      </OptionList>
    </Container>
  );
}
