import React, { useRef, useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft, faEye, faShare } from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-toastify';

import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '~/contexts/auth';

import api from '~/services/api';
import apiService from '~/services/http';

import getValidationErrors from '~/utils/getValidationErrors';
import { getCloudinaryPath } from '~/utils';

import { Input, Typography, Button, PageLoading } from '~/components';
import SubscribeOptions from './SubscribeOptions';

import { Container, FormContainer } from './styles';

export default function Subscribe() {
  const formRef = useRef(null);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const school = useSelector((state) => state.school.data);

  const { courseId } = useParams();
  const { user } = useAuth();

  const history = useHistory();

  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const [title, setTitle] = useState('');
  const [studentName, setStudentName] = useState('');
  const [courseProperties, setCourseProperties] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [successfullyCreated, setSuccessfullyCreated] = useState(false);
  const [pageEditable, setPageEditable] = useState(false);

  useEffect(() => {
    if (!courseId) {
      history.push('/');

      toast('Desculpe, mas não foi possível acessar a inscrição do curso desejado', {
        autoClose: 3000,
        type: 'error',
      });
    }
  }, [courseId]);

  useEffect(() => {
    if (selectedCourse) {
      const registrationAvailable = selectedCourse.options
        .map((option) =>
          option.option_key === 'public_subscribe'
            ? { ...option, option_value: option.option_value === 'true' }
            : option,
        )
        .find((option) => option.option_key === 'public_subscribe');

      if (!registrationAvailable || registrationAvailable.option_value === false) {
        history.push('/');

        toast('Desculpe, mas as inscrições para este curso estão desabilitadas', {
          autoClose: 3000,
          type: 'error',
        });
      }
    }
  }, [selectedCourse]);

  useEffect(() => {
    async function loadSelectedCourse() {
      try {
        setLoading(true);

        const response = await api.get(`/courses_free/${courseId}`);

        const properties = (response.data.options || []).map((property) => ({
          prop_key: property.option_key,
          prop_value: property.option_value,
        }));

        const linkPropertyRequired = properties.find(
          (property) =>
            property.prop_key === 'page_redirect_required' && property.prop_value === 'true',
        );

        setIsButtonVisible(linkPropertyRequired||false);
        setSelectedCourse(response.data);
        setCourseProperties(properties);
      } catch (error) {
        history.push('/');

        toast('Desculpe, mas não foi possível acessar a inscrição do curso desejado', {
          autoClose: 3000,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    loadSelectedCourse();
  }, [history]);

  useEffect(() => {
    if (user && !user.is_student) setPageEditable(true);
  }, [user]);

  const handleSubmit = useCallback(async () => {
    const properties = courseProperties.reduce((accu, property) => {
      if (accu[property.prop_key]) {
        accu[property.prop_key] = property.prop_value;
      }

      return { ...accu, [property.prop_key]: property.prop_value };
    }, {});

    Object.assign(
      properties,
      { page_title: titleRef.current.value },
      { page_description: descriptionRef.current.value },
      !!properties?.page_redirect && { page_redirect_required: isButtonVisible },
    );

    const options = courseProperties.map((option) => ({
      option_key: option.prop_key,
      option_value: option.prop_value,
    }));

    if (titleRef.current.value === '') {
      toast('O título é obrigatório', {
        autoClose: 3000,
        type: 'error',
      });
    }

    apiService.put(`/courses`, { id: courseId, properties });

    setSelectedCourse((prevState) => ({ ...(prevState || {}), options }));

    toast('Opções de inscrições públicas do curso atualizado!', {
      autoClose: 3000,
      type: 'info',
    });
  }, [isButtonVisible, courseProperties, courseId]);

  const handleSubscribe = useCallback(
    async (data) => {
      try {
        setSubscribeLoading(true);

        formRef.current.setErrors({});

        const phoneIsRequired = (selectedCourse.options || []).find(
          (option) =>
            option.option_key === 'phone_required' && Boolean(option.option_value) === true,
        );

        const dataWithPhone = {
          ...data,
          isPhone: !!phoneIsRequired,
        };

        const schema = Yup.object().shape({
          name: Yup.string().required('O Nome é obrigatório'),
          email: Yup.string()
            .email('E-mail deve ser um e-mail válido')
            .required('O E-mail é obrigatório'),
          isPhone: Yup.boolean(),
          phone: Yup.string().when('isPhone', {
            is: true,
            then: Yup.string().length(11, 'Seu telefone deve ter o DDD, no total 11 números'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(dataWithPhone, { abortEarly: false });

        await api.post(`/courses_free/${courseId}`, data);

        setStudentName(data.name.split(' ')[0]);
        setSuccessfullyCreated(true);

        toast('Seu cadastro foi feito com sucesso', {
          autoClose: 3000,
          type: 'success',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current.setErrors(errors);

          return;
        }

        const message =
          error?.response && error.response.status !== 500
            ? error.response.data.messages
            : 'Desculpe, mas não foi possível realizar seu cadastro neste momento.';

        toast(message, {
          autoClose: 3000,
          type: 'error',
        });
      } finally {
        setSubscribeLoading(false);
      }
    },
    [selectedCourse, courseId],
  );

  const handleBackClick = useCallback(() => {
    history.push(`/course/${courseId}/settings/inscriptions`);
  }, [courseId]);

  const handleMoreLinkClick = useCallback(() => {
    const defaultLink = (selectedCourse?.options || []).find(
      (option) => option.option_key === 'page_redirect',
    )?.option_value;

    window.location = defaultLink;
  }, [selectedCourse]);

  const handlePageEditChange = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      setPageEditable(false);
      setLoading(false);
    }, 1000);
  }, []);

  const handleCopyChange = useCallback((event) => {
    event.preventDefault();

    const { origin, pathname } = window.location;

    const pathName = pathname.replace('/settings/inscriptions', '/subscribe');

    const element = document.createElement('textarea');
    element.value = origin + pathName;

    document.body.appendChild(element);
    element.select();

    document.execCommand('copy');
    document.body.removeChild(element);

    setTitle('Copiado!');

    setTimeout(() => {
      setTitle('');
    }, 500);
  }, []);

  const hiddenComponent = useMemo(() => subscribeLoading || successfullyCreated, [
    subscribeLoading,
    successfullyCreated,
  ]);

  const defaultSchoolLogo = useMemo(() => {
    const logotipo = school?.brand.menu;

    if (!logotipo) {
      return undefined;
    }

    return getCloudinaryPath(logotipo);
  }, [school]);

  const defaultSchoolTitle = useMemo(() => {
    const schoolTitle = school?.title;

    if (!schoolTitle) {
      return undefined;
    }

    return schoolTitle;
  }, [school]);

  const courseOptions = useMemo(() => {
    if (!selectedCourse) return [];

    return selectedCourse.options;
  }, [selectedCourse]);

  const defaultBgColor = useMemo(() => {
    const bgColor = courseOptions.find((option) => {
      return option.option_key === 'color_primary';
    });

    return bgColor ? bgColor.option_value : '#29B6F6';
  }, [courseOptions]);

  const defaultBgImage = useMemo(() => {
    const bgCover = courseOptions.find((option) => {
      return option.option_key === 'cover';
    });

    if (process.env.NODE_ENV === 'development') {
      return undefined;
    }

    return bgCover && getCloudinaryPath(bgCover.option_value);
  }, [courseOptions]);

  const defaultPageTitle = useMemo(() => {
    const pageTitle = courseOptions.find((option) => {
      return option.option_key === 'page_title';
    });

    if (pageTitle && pageTitle.option_value !== '') {
      return pageTitle.option_value;
    }

    const courseTitle = selectedCourse?.name;

    return courseTitle;
  }, [selectedCourse, courseOptions]);

  const defaultPageDescription = useMemo(() => {
    const pageDescription = courseOptions.find(
      (option) => option.option_key === 'page_description',
    );

    if (pageDescription && pageDescription.option_value !== '') {
      return pageDescription.option_value;
    }

    const courseDescription = courseOptions.find(
      (option) => option.option_key === 'text_description',
    );

    return courseDescription && courseDescription.option_value;
  }, [courseOptions]);

  const redirectRequired = useMemo(() => {
    if (!isButtonVisible) return false;

    const pageRedirectRequired = courseOptions.find(
      (option) => option.option_key === 'page_redirect_required',
    );

    return pageRedirectRequired && pageRedirectRequired.option_value === 'true';
  }, [courseOptions]);

  const phoneIsRequired = useMemo(() => {
    const phoneRequired = courseOptions
      .map((option) =>
        option.option_key === 'phone_required'
          ? { ...option, option_value: option.option_value === 'true' }
          : option,
      )
      .find((option) => option.option_key === 'phone_required');

    if (!phoneRequired) {
      return false;
    }

    return phoneRequired.option_value;
  }, [courseOptions]);

  const copyText = useMemo(() => {
    if (title === '') return undefined;

    return title;
  }, [title]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Container>
      {pageEditable && (
        <header>
          <button type="button" onClick={handleBackClick}>
            <FontAwesomeIcon icon={faArrowLeft} size="2x" />
          </button>

          <aside>
            <button type="button" onClick={handlePageEditChange}>
              <FontAwesomeIcon icon={faEye} size="1x" />
            </button>

            <button type="button" onClick={handleCopyChange}>
              {copyText ?? <FontAwesomeIcon icon={faShare} size="1x" />}
            </button>

            <SubscribeOptions
              isButtonVisible={isButtonVisible}
              setIsButtonVisible={setIsButtonVisible}
              setCourseProperties={setCourseProperties}
              courseProperties={courseProperties}
            />

            <button type="button" className="is-primary" onClick={handleSubmit}>
              Salvar
            </button>
          </aside>
        </header>
      )}

      <section>
        <div className="column" style={{ backgroundColor: defaultBgColor }}>
          <div>
            {redirectRequired && (
              <div className="course-link" style={{ marginTop: pageEditable ? 92 : 32 }}>
                <Button outline size="sm" color="white" onClick={handleMoreLinkClick}>
                  <FontAwesomeIcon icon={faLongArrowAltLeft} />
                  Saiba mais
                </Button>
              </div>
            )}

            <div className="course-infos">
              {defaultBgImage && (
                <div>
                  <img src={defaultBgImage} alt={defaultPageTitle} />
                </div>
              )}

              {pageEditable ? (
                <div className="course-textareas">
                  <TextareaAutosize
                    ref={titleRef}
                    className="course-textarea"
                    defaultValue={defaultPageTitle}
                    placeholder="Título do curso"
                  />
                  <TextareaAutosize
                    ref={descriptionRef}
                    className="course-textarea course-input"
                    defaultValue={defaultPageDescription}
                    placeholder="Descrição do curso"
                  />
                </div>
              ) : (
                <div>
                  <Typography variant="h1" gutterBottom="16px">
                    {defaultPageTitle}
                  </Typography>

                  <Typography variant="body1">{defaultPageDescription}</Typography>
                </div>
              )}
            </div>

            <div className="course-background" />

            <div className="course-copyright">
              {defaultSchoolLogo && (
                <div>
                  <img src={defaultSchoolLogo} alt={defaultSchoolTitle} />
                </div>
              )}

              {defaultSchoolTitle && (
                <div>
                  <p>
                    Desenvolvido por
                    {' '}
                    {defaultSchoolTitle}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="column">
          <div>
            <div className="course-subscribe">
              {!successfullyCreated ? (
                <Typography variant="h2" gutterBottom="5px">
                  {subscribeLoading ? 'Aguarde..' : 'Inscreva-se'}
                </Typography>
              ) : (
                <>
                  <Typography variant="h2" gutterBottom="5px">
                    {`Parabéns ${studentName},`}
                  </Typography>

                  <Typography variant="body1">
                    Em instantes você receberá em seu email os dados de acesso ao curso
                    {' '}
                    <strong>
                      {selectedCourse?.name ?? 'Como criar um blog do zero (100% grátis)'}
                    </strong>
                    .
                  </Typography>
                </>
              )}

              {!hiddenComponent && (
                <Typography variant="body1">
                  Crie sua conta gratuitamente e assista este curso.
                </Typography>
              )}

              <div
                className="course-subscribe-form"
                style={{ display: !hiddenComponent ? 'block' : 'none' }}
              >
                <FormContainer>
                  <Form ref={formRef} onSubmit={handleSubscribe}>
                    <Input name="name" label="Nome" />
                    <Input type="email" name="email" label="E-mail" />

                    {phoneIsRequired && <Input type="tel" name="phone" label="Telefone" />}

                    <Button shadow>
                      Avançar
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    </Button>
                  </Form>
                </FormContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
}
