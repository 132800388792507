import styled from 'styled-components';



export const Container = styled.form`
  display: flex;
  flex-direction: column;

  > div {
    :first-of-type {
      display: flex;

      textarea {
        flex: 1;
        min-height: 75px;

        padding: 12px;
        border: 1px solid #536DFE;
        border-radius: 4px;

        resize: none;
        outline: none;

        background-color: #536DFE0D;

        line-height: 22px;

        font-size: 16px;

        ::-webkit-input-placeholder {
          color: #526cfe99 !important;
        }

        :-moz-placeholder {
          color: #526cfe99 !important;
        }

        ::-moz-placeholder {
          color: #526cfe99 !important;
        }

        :-ms-input-placeholder {
          color: #526cfe99 !important;
        }
      }
    }

    :last-of-type {
      display: flex;
      justify-content: space-between;

      margin-top: 16px;

      span {
        display: flex;
        align-items: center;

        color: #536DFE;
        font-size: 14px;

        svg {
          margin-right: 8px;
        }
      }

      button {
        width: 100px;
        height: 32px;

        padding: 0 20px;

        border: 1px solid #2BB0EE;
        border-radius: 4px;

        outline: none;

        color: #ffffff;
        background-color: #2BB0EE;

        font-size: 12px;

        box-shadow: 0px 0px 3px #00000021;

        :hover,
        :disabled {
          filter: brightness(0.9);
        }

        :hover {
          cursor: pointer;
        }

        :disabled {
          cursor: not-allowed;
        }
      }
    }
  }
`;
