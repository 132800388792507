import React from 'react';
import styled from 'styled-components';
// import { darken } from 'polished'

import Typography from '../Typography';

const Container = styled.div`
  .section-title{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 0;
    > div{
      flex: 1;
    }
    &.hasBorder{
      border-bottom: 1px solid ${(props) => props.borderColor || '#CFD8DC'};
    }
    .section-title-column-title{}
    .section-title-column-actions{
      .section-title-column-actions-content{
        display: table;
        float: right;
        button{
          margin-right: 15px;
          &:last-of-type{
            margin-right: 0px;
          }
        }
      }
    }
  }

  @media (max-width: 768px){
    .section-title{
      display: block;
      .section-title-column-title{
        display: block;
        margin-bottom: 5px;
      }
      .section-title-column-actions{
        display: block;
        margin-top: 20px;
        .section-title-column-actions-content{
          float: initial;
        }
      }
    }
  }
`;

export default function SectionTitle({title,border,borderColor,children,...props}){

  let classes = 'section-title ';
  if(border === true){
    classes += ' hasBorder ';
  }

  return (
    <Container title={title} border={border} borderColor={borderColor} {...props}>
      <div className={classes}>
        <div className="section-title-column-title">
          <Typography variant="h2">{title}</Typography>
        </div>
        <div className="section-title-column-actions">
          <div className="section-title-column-actions-content">
            {children}
          </div>
        </div>
      </div>
    </Container>
  );
}
