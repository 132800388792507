import React from 'react';
import styled from 'styled-components';

import Typography from '../Typography';

const Container = styled.div`
  width: 100%;
  min-height: 150px;
  position: relative;

  h2 {
    position: relative;
    z-index: 10;
    margin-bottom: 5px;
  }

  p {
    position: relative;
    z-index: 10;
    font-size: 14px;
  }

  .metric-tool {
    position: relative;
    z-index: 10;
    margin-top: 10px;
  }

  .metric-graphic {
    bottom: 0;
    width: 100%;
    position: absolute;
    opacity: 0.8;
    z-index: 0;
  }
`;

export default function CardMetric({ children, title, subtitle }) {
  return (
    <Container>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body1">
        {subtitle}
      </Typography>
      {children}
    </Container>
  );
}
