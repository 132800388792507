import React from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faMicrophone } from '@fortawesome/free-solid-svg-icons';

import Typography from '../Typography';

export const Container = styled.div`
  display: flex;
  font-size: 14px;
  padding: 16px 26px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  ${this}:hover {
    background-color: ${(props) => transparentize(0.8, props.theme.palette.primary)};
    cursor: pointer;
  }
  ${(props) =>
    props.separator &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.g2};
    `}
    .audio_icon {
      font-size: 22px;
    }
  ${(props) =>
    props.hovercontentRight &&
    css`
      button {
        display: none;
      }
      &:hover {
        .audio_icon {
          display: none;
        }
        button {
          display: block;
        }
      }
    `}
  ${(props) =>
    props.hovercontentRight &&
    css`
      ${this}:hover {
        background-color: transparent;
        cursor: pointer;
      }
    `}
    ${(props) =>
      props.noMargin &&
      css`
        padding: 16px 0px;
      `}
      ${(props) =>
        props.active &&
        css`
          background-color: ${transparentize(0.8, props.theme.palette.primary)};
        `}
`;

const ItemContent = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.g10};
  align-items: center;
  justify-content: center;
  p {
    margin-left: 16px;
  }
  span {
    margin-right: 16px;
  }
  button {
    transition: all 0.5s;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function Item({
  audio,
  primaryText,
  subTitle,
  secondaryText,
  textColor,
  contentLeft,
  contentRight,
  ...props
}) {
  return (
    <Container {...props}>
      <ItemContent>
        {contentLeft}
        <TextContainer>
          <Typography color={textColor} variant="body2">
            {primaryText}
          </Typography>
          {subTitle && (
            <Typography variant="body2" color="g5">
              {subTitle}
            </Typography>
          )}
        </TextContainer>
      </ItemContent>
      <ItemContent>
        <span>{secondaryText}</span>
        <>
          {audio && (
            <FontAwesomeIcon
              className="audio_icon"
              icon={faMicrophone}
              color="#00C853"
              size="1.5x"
            />
          )}
        </>
        {contentRight}
      </ItemContent>
    </Container>
  );
}
