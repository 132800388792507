/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { isSafari } from 'react-device-detect';

import styled, { css } from 'styled-components';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import IconButton from '../IconButton';
import Typography from '../Typography';
import SliderContext from './context';
import useSliding from './useSliding';
import useSizeElement from './useSizeElement';

export const Container = styled.div`
  .header {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;
  }
  .slider-wrapper {
    overflow: auto;
    position: relative;
    .slider {
      display: flex;
      position: relative;
      max-width: 266px;
    }
    .container {
      display: flex;
      transition: transform 300ms ease 100ms;
      z-index: 3;
      width: 100%;
      min-height: 385px;
    }
    .item {
      margin-right: 16px;
      transition: transform 300ms ease 100ms;
    }
    .controls {
      cursor: pointer;
      color: #90a4ae;
    }
    @media (max-width: 768px) {
      .slider-wrapper {
        overflow: auto;

        ${isSafari &&
        css`
          overflow-x: scroll;
        `}
      }
      .controls {
        display: none;
      }
    }
  }
`;

export default function CardSlider({ children, title, subTitle, activeSlide = true }) {
  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const { width, elementRef } = useSizeElement();

  const { handlePrev, handleNext, slideProps, containerRef, hasNext, hasPrev } = useSliding(
    width,
    React.Children.count(children),
  );

  const handleSelect = (movie) => {
    setCurrentSlide(movie);
  };

  const handleClose = () => {
    setCurrentSlide(null);
  };

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    elementRef,
    currentSlide,
  };

  return (
    <Container>
      {title && (
        <div className="header">
          <div>
            <Typography variant="h3" gutterBottom="16px">
              {title}
            </Typography>
            <Typography variant="body1">{subTitle}</Typography>
          </div>
          <span />
          {(hasPrev || hasNext) && (
            <div className="controls">
              <IconButton onClick={handlePrev} icon={faAngleLeft} disabled={!hasPrev} />
              <IconButton onClick={handleNext} icon={faAngleRight} disabled={!hasNext} />
            </div>
          )}
        </div>
      )}

      <div className="slider-wrapper">
        <SliderContext.Provider value={contextValue}>
          <div className="slider">
            <div ref={containerRef} {...slideProps} className="container">
              {children.map((item) => (
                <div key={item.id} ref={elementRef} className="item">
                  {item}
                </div>
              ))}
            </div>
          </div>
        </SliderContext.Provider>
      </div>
    </Container>
  );
}
