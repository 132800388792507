/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
// docs https://github.com/CookPete/react-player

const PlayerWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  background-color: ${(props) => props.theme.palette.g1};
  .react-player,
  iframe,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;

const config = {
  vimeo: {
    playerOptions: { transparent: false, controls: true },
  },
};

export default function VideoPlayer({ url = '', ...props }) {
  // console.log('Passei aqui')
  // console.log(url)
  // console.log(config)
  // console.log(props)

  let srcUrl = ''
  let {onEnded} = props

  // console.log(!url?.startsWith('http'))

  let myElement = new DOMParser().parseFromString(url, "text/html");

  // console.log(myElement)

  if(myElement){
    let iframeComponent = myElement.getElementsByTagName('iframe')[0]

    // console.log(url)
    // console.log(props)
    // console.log(iframeComponent)

    if(iframeComponent){

      // console.log(iframeComponent.src)

      if(iframeComponent.src.includes("wistia")){
        srcUrl = iframeComponent.src.split("?")[0]
      }else{
        srcUrl = iframeComponent.src
      }

      if(!iframeComponent.src.includes("wistia") && !iframeComponent.src.includes("vimeo")){
        srcUrl = ''
      }
      if(iframeComponent.src.includes("pandavideo")){
        srcUrl = ''
      }

    }
  }
  // console.log(iframeComponent.attributes)
  // props.onEnded ? props.onEnded = () => {
  //   console.log('VideoPlayer Ended');
  //   console.log('VideoPlayer Ended');
  //   console.log('VideoPlayer Ended');
  //   console.log('VideoPlayer Ended');
  // }: null;
  // props.onProgress ? props.onProgress = () => {
  //   console.log('VideoPlayer Progress');
  //   console.log('VideoPlayer Progress');
  //   console.log('VideoPlayer Progress');
  //   console.log('VideoPlayer Progress');
  // }: null;
  // console.log(props)
  return (
    <PlayerWrapper>
      {!url?.startsWith('http') ? (
        srcUrl != '' ? (
          <ReactPlayer
          url={srcUrl}
          config={config}
          width="100%"
          height="100%"
          className="react-player player1.1"
          {...props}
        />
        ):(<div className="react-player player1.2"  dangerouslySetInnerHTML={{ __html: url }} />)
      ) : (
        <ReactPlayer
          url={url}
          config={config}
          width="100%"
          height="100%"
          className="react-player player2"
          {...props}
        />
      )}
    </PlayerWrapper>
  );
}
