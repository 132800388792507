import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  min-height: 100vh;

  .course-infos {
    & :only-child {
      max-width: 400px;
      padding-left: 0 !important;

      .course-textarea {
        max-width: 400px;
      }
    }
  }

  .course-textareas {
    display: flex;
    flex-direction: column;
  }

  .course-textarea {
    overflow: hidden;

    width: 100%;
    max-width: 375px;

    margin-bottom: 16px;
    padding: 8px;
    border-radius: 4px;

    resize: none;
    outline: none;

    color: white;
    background: transparent;

    font-size: 33px;
    font-weight: bold;
    transition: background 0.3s ease-in-out;

    :hover,
    :active,
    :focus {
      background: rgba(0, 0, 0, 0.2);
    }

    ::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    &.course-input {
      min-height: 100px;

      font-size: 20px;
      font-weight: 400;
    }
  }

  > header {
    z-index: 9;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 14px 32px;
    border-bottom: 1px solid #cfd8dc;

    background-color: #ffffff;

    button {
      padding: 8px 16px;

      transition: background 0.2s ease 0s, color 0.2s ease 0s, box-shadow 0.2s ease 0s;

      :hover {
        cursor: pointer;
      }
    }

    > button:first-of-type {
      border: 0;
      padding: 0;
      padding-left: 24px;

      background-color: transparent;
    }

    aside {
      display: flex;

      div button {
        min-width: 120px;
      }

      button {
        margin-left: 18px;
        border-radius: 4px;

        color: #78909c;
        background-color: #ffffff;

        box-shadow: rgb(0 0 0 / 13%) 0px 0px 3px;

        :hover {
          background-color: ${darken(0.1, '#ffffff')};
        }

        &.is-primary {
          min-width: 90px;

          color: #ffffff;
          background-color: #29b6f6;

          :hover {
            background-color: ${darken(0.2, '#29b6f6')};
          }
        }
      }
    }
  }

  > section {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    align-items: center;

    height: 100vh;

    .column {
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      align-items: center;
      padding: 40px 63px;
      &:first-of-type {
        background: #41b9ca;
        h1,
        p {
          color: white;
        }
      }
      > div {
        width: 100%;
      }
    }
    .course-infos {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      z-index: 100;
      position: relative;
      align-items: center;
      > div {
        flex: 40%;
        &:last-of-type {
          flex: 60%;
          padding-left: 30px;
        }
        h1 {
          font-size: 33px;
          font-weight: bold;
        }
        p {
          font-size: 20px;
        }
        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          overflow: hidden;
          box-shadow: 0px 0px 30px #00000029;
        }
      }
    }
    .course-link {
      top: 0;
      margin-top: 32px;
      position: absolute;
      z-index: 10;
      button {
        color: white;
        svg {
          margin-right: 6px;
        }
      }
    }
    .course-background {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.13;
      z-index: 1;
      /* background: url('https://i.imgur.com/VDFTarRl.png'); */
      /* background: url('https://img.freepik.com/free-vector/wavy-smooth-lines-pattern-background_1017-14206.jpg?size=626&ext=jpg'); */
      /* background: url('https://i.pinimg.com/474x/3b/2b/e7/3b2be74c44a34d3886cbd1360bd266a8.jpg'); */
      background-position: center center;
      &.contain {
        background-size: contain;
      }
      &.cover {
        background-size: cover;
      }
      &.no-repeat {
        background-repeat: no-repeat;
      }
    }
    .course-copyright {
      bottom: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 32px;
      position: absolute;
      z-index: 10;
      > div {
        &:first-of-type {
          flex: 18px;
          img {
            max-width: 100%;
            height: auto;
          }
        }
        &:last-of-type {
          padding-left: 14px;
          flex: calc(100% - 32px);
        }
      }
      p {
        font-size: 14px;
        margin-top: -10px;
      }
    }
    .course-subscribe {
      display: table;
      margin: auto;
      min-width: 450px;
      max-width: 450px;
      h2 {
        font-size: 33px;
        font-weight: bold;
      }
    }
    .course-subscribe-form {
      margin-top: 40px;
    }

    //RESPONSIVIDADE

    @media (max-width: 414px) {
      .column {
        height: auto;
        &:first-of-type {
          padding-bottom: 120px;
        }
        &:last-of-type {
          padding-top: 250px;
        }
      }
      .course-link {
        left: 0px;
        width: 100%;
        button {
          display: table;
          margin: auto;
          border: none;
        }
      }

      .course-infos {
        margin-top: 80px;
        flex-direction: column-reverse;

        h1 {
          font-size: 25px !important;
        }

        p {
          font-size: 15px !important;
          margin-bottom: 60px;
        }

        > div {
          &:first-of-type {
            img {
              max-width: 220px;
              height: auto;
              left: 50%;
              transform: translateX(-50%);
              position: absolute;
            }
          }

          &:last-of-type {
            padding-left: 0px;

            h1,
            p {
              text-align: center;
            }
          }
        }
      }

      .course-copyright {
        display: none;
      }

      .course-subscribe {
        min-width: initial;
        padding-bottom: 120px;

        h2 {
          font-size: 22px !important;
        }

        .course-subscribe-form {
          margin-top: 20px;
        }
      }
    }
  }
`;

export const FormContainer = styled.div`
  button {
    margin-top: 25px;
    svg {
      margin-left: 6px;
      vertical-align: middle;
    }
  }
`;
