import React, { createContext, useContext, useState, useEffect } from 'react';

import { convertProperties } from '../utils';
import { loginMassage } from '../constants';
import { AuthService } from '../services/http';
import usePersistedState from '../hooks/usePersistedState';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [user, setUser] = usePersistedState('user', null);

  if (user) AuthService.setToken(user.token);

  async function signIn(credintials) {
    setError(null);
    try {
      const { user_data, ...data } = await AuthService.signIn(credintials);
      if (data.status === 200) {
        user_data.properties = convertProperties(user_data.properties);
        setUser({ ...data, user_data });
      } else {
        setError({ ...data, msg: loginMassage[data.status] });
      }
    } catch (e) {
      setError({ msg: loginMassage[500] });
    }
  }

  async function resetPassword(credentials) {
    setError(null);
    try {
      const data = await AuthService.resetPassword(credentials);
      if (credentials.token && data.status === 404) {
        setError({ ...data, msg: 'Token inválido!' });
      } else if (data.status === 404) {
        setError({ ...data, msg: loginMassage[data.status] });
        throw new Error(loginMassage[data.status]);
      }
    } catch (e) {
      setError({ msg: e.message });
      throw new Error(e.message);
    }
  }

  function setUserData(data) {
    setUser({ ...user, user_data: data });
  }

  async function signOut() {
    setUser(null);
    window.location = '/';
  }

  useEffect(() => {
   async function invalidateToken() {
    if (user?.token) {
      const validToken = await AuthService.validateToken(user.token);
      if (!validToken) {
        setUser(null);
        window.location = '/';
      }
    }
   }

   invalidateToken()
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        error: !!error,
        errorData: error,
        user: user?.user_data,
        token: user?.token,
        setUser: setUserData,
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
