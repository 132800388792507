function createUserService(http) {
  const create = async (user) => http.post(`/users`, user);
  const update = async (user) => http.put(`/users`, user);

  return {
    create,
    update
  };
}

export default createUserService;
