/* eslint-disable no-unused-vars */
import React from 'react';
import { Line } from 'react-chartjs-2';

import ErrorBoundary from '../ErrorBoundary';

const graphBgColor = '#DCF0F7';
const graphBorderColor = '#3BADEC';

const graphOption = {
  legend: false,
  scales: {
    xAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
          drawBorder: false,
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
          drawBorder: false,
          color: 'rgba(0, 0, 0, 0)',
        },
      },
    ],
  },
};

export default function CardMetric({ data = { labels: [], data: [] } }) {
  const graphData = {
    datasets: [
      {
        backgroundColor: graphBgColor,
        borderColor: graphBorderColor,
        data: data?.data,
      },
    ],
    labels: data?.labels,
  };
  return (
    <ErrorBoundary error={<span />}>
      <Line data={graphData} height={40} options={graphOption} />
    </ErrorBoundary>
  );
}
