import React from 'react'
import styled from 'styled-components';

import Typography from '../Typography';

const Container = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 5px;
  h3{
    margin-bottom: 0px;
  }
  h3+p{
    margin-top: 18px;
  }
`;

export default function CardTransparent(){
  return (
    <Container>
      <div>
        <Typography variant="h3" gutterBottom="20px">Titulo do card</Typography>
        <Typography variant="body1"><Typography variant="body1">Aqui fica a descrição deste card</Typography></Typography>
      </div>
    </Container>
  );
}
