import axios from 'axios';
import { getFilePath, getResourceExtension } from '~/utils';

function createFileService(cloudinary, apolloAPI) {
  const registerMedia = async (path, size) => {
    return apolloAPI.post(`/medias`, {
      path,
      size,
    });
  };

  const upload = async (file, path = '', onUploadProgress) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', path);
    formData.append('upload_preset', 'apollo_img');
    const result = await cloudinary.post(`/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
    await registerMedia(result.data.secure_url, file?.size / 8);
    return result.data.secure_url;
  };

  const getS3Signature = async (file, fileName) => {

    const filePath = `${window.apl_install}/uploads/${getFilePath(fileName)}`;
    const [signedFile] = await apolloAPI.post('/s3', {
      files: [
        {
          fileName: filePath,
          fileType: file.type,
          acl: 'private',
        },
      ],
    });
    return signedFile.data.signedUrl;
  };

  const uploadS3 = async (file, onUploadProgress) => {
    const extension  = getResourceExtension(file.name);
    const filenameText = String(file.name).replace(/\.[^/.]+$/, "");
    const fileName = `${filenameText}-${Math.random().toString(36).slice(-4)}.${extension}`;
    const signedUrl = await getS3Signature(file, fileName);
    await axios.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress
    });
    await registerMedia(getFilePath(fileName), file.size);
    return getFilePath(fileName);
  };

  return {
    upload,
    uploadS3,
  };
}

export default createFileService;
