import styled from 'styled-components';

export const Container = styled.td`
  padding: 16px;

  color: #546e7a;

  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;
`;
