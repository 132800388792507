import mime from 'mime-types';

import './yup';
import dayjs from './day';

const filesTypes = {
  png: 'image',
  jpg: 'image',
  pdf: 'raw',
  jpeg: 'image',
  svg: 'image',
  gif: 'image',
  webp: 'image',
  mp3: 'video',
  mp4: 'video',
};

export const environment =
  window.location.host.includes('staging') || window.location.host.includes('localhost')
    ? 'staging'
    : 'production';

export function substring(text = '', limit) {
  return text?.length > limit ? `${text.substring(0, limit)}...` : text;
}

export function getSiteUrl() {
  const url = window.location.host;
  return url.includes('localhost') ? 'staging' : url;
}

export function parseChartData(data, label) {
  return data.reduce(
    (chartData, item) => {
      chartData.data.push(item[label]);
      chartData.labels.push(dayjs(item.moment).format('dddd, DD MMMM YYYY'));
      return chartData;
    },
    { labels: [], data: [] },
  );
}

export function groupProperties(properties) {
  const groupedProprieties = {};
  Object.keys(properties)
    .filter((propKey) => propKey.split('_').length === 2)
    .forEach((propKey) => {
      const [group, key] = propKey.split('_');
      if (!groupedProprieties[group]) groupedProprieties[group] = {};
      groupedProprieties[group][key] = properties[propKey];
    });
  return groupedProprieties;
}

export function convertProperties(properties = []) {
  const props = {};
  properties.forEach((prop) => {
    props[prop.key] = prop.value;
  });
  return { ...props };
}

export function mapSocialLinks(links = {}, icons = {}) {
  return Object.keys(links).map((key) => ({
    href: `${links[key]}`,
    icon: icons[key],
  }));
}

export function getInitials(name = '', thirdLetter = false) {
  if (!name) return '';
  const nameNormalized = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const words = nameNormalized.split(' ');
  const [l1 = '', l2 = words[0].charAt(1), l3 = ''] = words.map((word) => word[0]);
  return `${l1 + l2}${thirdLetter ? l3 : ''}`.toUpperCase();
}

export function getNextPosition(list = []) {
  const [last] = [...list].reverse();
  return last ? last?.position + 1 : 1;
}

export function humanFileSize(size = 0) {
  if (!size) return '';
  const i = Math.floor(Math.log(size) / Math.log(1024)) || 0;
  return `${(size / 1024 ** i).toFixed(2) * 1}${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export const booleanToString = (value) => String(Number(value));
export const stringToBoolean = (value) => Boolean(Number(value));

export function isIframe(str = '') {
  return !!str?.match(/(?:<(iframe|embed)[^>]*)(?:(?:\/>)|(?:>.*?<\/(iframe|embed)>))/);
}

export function getResourceExtension(url = '') {
  return url.split('.').pop();
}

export function getCloudinaryPath(url = '', transformation) {
  const encodedUrl = encodeURI(url);
  if (!encodedUrl || encodedUrl.startsWith('http') || encodedUrl.startsWith('blob')) {
    return encodedUrl;
  }
  const extension = getResourceExtension(encodedUrl);
  const resourceType = filesTypes[extension] || 'raw';
  if (transformation) {
    return `https://res.cloudinary.com/vdb/${resourceType}/upload/${transformation}/${environment}/${window.apl_install}/uploads/${encodedUrl}`;
  }
  return `https://res.cloudinary.com/vdb/${resourceType}/upload/${environment}/${window.apl_install}/uploads/${encodedUrl}`;
}

export function getFilePath(fileName) {
  return `${dayjs().format('YYYY/MM')}/${fileName}`;
}

export function getFileName(path = '') {
  return path.split('/').pop();
}

export async function blobUrlToBlob(url = '') {
  return fetch(url).then(async (r) => {
    const blob = await r.blob();
    blob.name = `${getFileName(url)}.${mime.extension(blob.type)}`;
    console.log(blob);
    return blob;
  });
}

export function mapYupObjectErrors(err) {
  const errorMessages = {};
  err.inner.forEach((error) => {
    errorMessages[error.path] = error.message;
  });
  return errorMessages;
}

export function currency(number) {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
}

export function formatNumber(number) {
  return new Intl.NumberFormat('pt-BR').format(number);
}

const entitiesMap = {
  '&amp;': '&',
  '&gt;': '>',
  '&lt;': '<',
  '&quot;': '"',
  '&#39;': "'",
};

export function decodeHTMLEntities(str = '') {
  return str.replace(/&#?[\w]+;/g, (s) => {
    return entitiesMap[s] || s;
  });
}

export function updateObjectProperty(object, path, value) {
  const [head, ...rest] = path.split('.');

  if (!rest.length) {
    object[head] = value;
  } else {
    updateObjectProperty(object[head], rest.join('.'), value);
  }

  return object;
}

export function orderByPosition(array) {
  return array.sort((a, b) => {
    const itemA = a.position || a.id;
    const itemB = b.position || b.id;
    return itemA - itemB;
  });
}

export const sanitizeString = (str = '') => {
  return String(str?.replace(/[^A-Za-z0-9]/g, ''));
};
