import axios from 'axios';
import { convertProperties } from '~/utils';

function checkApiResponseError({status = 200, details, message}) {
  if(status !== 200 && status !== 404)
  throw new Error(`${details || message}`);
}

function parseProperties(responseData) {
  if (responseData.properties)
    return { ...responseData, properties: convertProperties(responseData.properties || []) };
  if (responseData.data && responseData.data?.length) {
    const parseData = responseData.data.map((item) => ({
      ...item,
      properties: convertProperties(item.properties),
    }));
    return { ...responseData, data: parseData };
  }
  return responseData;
}

const apollo = axios.create({
  baseURL: process.env.REACT_APP_APOLLO_API || 'https://core-staging.membrosapollo.com',
});

apollo.interceptors.response.use(
  (response) => {
    const { data } = response;
    checkApiResponseError(data);
    return parseProperties(data);
  },
  (error) => {
    throw new Error(`[API] Apollo API ${error}`);
  },
);

apollo.interceptors.request.use((request) => {
  const req = { ...request };
  if ((req.method === 'put' || req.method === 'post') && req.data.properties) {
    const properties = Object.keys(req.data.properties).map((key) => ({
      key,
      value: req.data.properties[key],
    })).filter((item) => 'value' in item);
    req.data.properties = properties;
  }
  return req;
});

export function setToken(token) {
  apollo.defaults.headers.common.token = token;
}

apollo.setToken = setToken;

export default apollo;
