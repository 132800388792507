import React from 'react';
import ReactMaskedInput from 'react-text-mask';

export default function MaskedInput(props) {
  const { inputRef, ...other } = props;

  return (
    <ReactMaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}
