import styled from 'styled-components';

import { Form as Unform } from '@unform/web';

export const Form = styled(Unform)`
  padding: 1rem 2rem;
`;

export const Scope = styled.div`
  display: flex;
  align-items: center;

  > p {
    height: 45px;
    margin-left: 16px;
    color: #aaa;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  button:last-of-type {
    margin-left: 24px;
  }
`;
