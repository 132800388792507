import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import './init';
import Routes from './routes';
import ErrorBoundary from './layouts/Error';
import GlobalStyle from './styles/global';
import ApolloThemeProvider from './components/ApolloThemeProvider';

import { store, persistor } from './store';
import { AuthProvider } from './contexts/auth';
import { AdminProvider } from './contexts/admin';
import { SignInStyleProvider } from './contexts/signIn';

import 'react-toastify/dist/ReactToastify.css';

window.apl_install = localStorage.getItem('apl');

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ToastContainer />
      <GlobalStyle />
      <AdminProvider>
        <AuthProvider>
          <ApolloThemeProvider>
            <SignInStyleProvider>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </SignInStyleProvider>
          </ApolloThemeProvider>
        </AuthProvider>
      </AdminProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
