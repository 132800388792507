import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  min-height: calc(100vh - 64px);

  @media (min-width: 992px) {
    grid-template-columns: 340px 1fr;
  }

  > aside {
    display: none;

    padding: 2rem 1.5rem;
    background-color: #f5f7f8;

    transition: all 300ms ease-in-out;

    @media (min-width: 992px) {
      display: block;
    }

    @media (min-width: 1440px) {
      padding: 2rem;
    }

    h5 {
      margin-bottom: 1.2rem;
    }
  }

  > section {
    display:flex;
    flex-direction: column;

    overflow: hidden;

    padding: 1.5rem;

    @media (min-width: 1440px) {
      padding: 2rem;
    }
  }
`;

export const ContentHeader = styled.header`
  display: block;

  margin-bottom: 1rem;

  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ContentBody = styled.article`
  overflow: hidden;
  overflow-x: auto;

  height: 100%;

  @media (min-width: 1440px) {
    overflow: unset;
  }
`;

export const ContentFooter = styled.footer`
  padding: 1rem;
  padding-bottom: 0;
`;

export const ContainerButtons = styled.div`
  button:last-of-type {
    margin-left: 1rem;
    padding: 0 1rem;

    outline: none;

    color: #78909c;
    background-color: transparent;

    :hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
`;

export const CustomColumnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;

  > div {
    :not(:only-child) {
      margin-left: 16px;
    }

    :last-of-type {
      ${(props) =>
        props.noMargin &&
        css`
          margin-left: 0;
        `}

      span {
        display: flex;
        align-items: center;

        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }
`;

export const CustomName = styled.div`
  display: flex;
  align-items: center;

  line-height: 1.43;

  font-size: 0.875rem;
  font-weight: 400;
  word-break: break-word;
`;

export const ModalHeader = styled.div`
  display: flex;
  padding-top: 26px;
  align-items: center;

  & > * {
    margin-right: 8px;
  }
`;
