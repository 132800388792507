import React from 'react';
import styled from 'styled-components';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import Link from '../Link';
import ItemClass from '../ItemClass';
import SectionTitle from '../SectionTitle';

import { decodeHTMLEntities } from '~/utils';

export const Container = styled.div`
  .list-items-headers {
    width: 100%;
    display: grid;
    grid-template-columns: calc(100% - 225px) 120px 120px;
    gap: 5px;
    margin: 15px 0px 15px 0px;
    border-bottom: 1px solid #eceff1;
    > div {
      color: #607d8b;
      font-size: 12px;
      padding-bottom: 13px;
    }
  }
  .list-items-items {
  }
  .list-items-link {
    margin-top: 20px;
  }
`;

export default function ListClasses({ title, items = [], onClickClass, onClickAddClass, onClickViewAll, ...props }) {
  return (
    <Container title={title} items={items} {...props}>
      <SectionTitle title={title}>
        {/* <Button onClick={onClickAddClass} color="white" shadow size="xs">
          Criar aula
        </Button> */}
      </SectionTitle>
      <div className="list-items-headers">
        <div>Aula</div>
        <div />
        <div>Nota</div>
      </div>
      <div className="list-items-items">
        {items
          .slice(0, 5)
          .map((item) => {
            return (
              <ItemClass
                key={item.id}
                icon={faPlay}
                id={item.id}
                number={item.number}
                title={decodeHTMLEntities(item.name)}
                rating={item.rating}
                onClick={onClickClass}
              />
            );
          })}
      </div>
      <div className="list-items-link">
        <Link onClick={onClickViewAll}>Ver todas as aulas</Link>
      </div>
    </Container>
  );
}
