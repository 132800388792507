import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IconButton = styled(({ component, ...props }) =>
  React.createElement(component, props),
)`
  color: ${(props) => props.theme.palette[props.color] || props.color};
  background: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: ${(props) => props.padding}px;
  font-size: ${(props) => props.theme.pxToRem(22)};
  border-radius: 4px;
  border: ${props => props.bordered ?  `1px solid ${props.theme.palette[props.color] || props.color}` : 'none'};
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5 !important;
  }
  ${this}:disabled:hover {
    opacity: 1;
    cursor: not-allowed;
  }
  ${(props) =>
    props.active &&
    css`
      background: rgba(250, 250, 250, 0.2);
    `};
`;

export default ({ icon, href, active, padding = 8, color = 'g2', children, ...props }) => {
  return (
    <IconButton
      component={href ? 'a' : 'button'}
      color={color}
      active={active ? 1 : 0}
      href={href}
      padding={padding}
      {...props}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : children}
    </IconButton>
  );
};
