/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import Rating from '../Rating';

export const Container = styled.div`
  .item-class-row{
    cursor: pointer;
    width: 100%;
    display: grid;
    grid-template-columns: 25px calc(100% - 286px) 148px 95px;
    gap: 5px;
    padding: 10px 0px;
    .item-class-column-icon{
      *[data-icon]{
        color: #455A64;
      }
    }
    .item-class-column-class{
      color: #455A64;
      font-size: 14px;
      font-weight: bold;
      &:after{
        width: 14px;
        height: 14px;
        content:  ' ';
        margin-top: -1px;
        margin-left: 4px;
        transform: rotate(-90deg);
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='angle-down' class='svg-inline--fa fa-angle-down fa-w-10 ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#78909C' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'%3E%3C/path%3E%3C/svg%3E");
      }
    }
    .item-class-column-title{
      color: #455A64;
      font-size: 14px;
    }
    .item-class-column-progress{
      > div{
        margin-top: 6px;
      }
    }
    .item-class-column-rating{}

    @media (max-width: 768px){
      grid-template-columns: 25px calc(100% - 136px) 0px 95px;
      .item-class-column-title{
        font-size: 0.8rem;
      }
    }
  }
`;

export default function ItemClass({
  id,
  icon,
  number,
  title,
  progress,
  rating,
  onClick,
  ...props
}){
  return(
    <Container icon={icon} number={number} title={title} progress={progress} rating={rating} {...props}>
      <div onClick={() => onClick(id)} className="item-class-row">
        <div className="item-class-column-icon"><FontAwesomeIcon icon={icon} size="sm"  /></div>
        {/* <div className="item-class-column-class">{number}</div> */}
        <div className="item-class-column-title">
          {title}
        </div>
        <div className="item-class-column-progress" />
        <div className="item-class-column-rating">
          <Tooltip title={rating} placement="top" arrow>
            <Rating initialRating={rating} readonly />
          </Tooltip>
        </div>
      </div>
    </Container>
  );
}
