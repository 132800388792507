import styled from 'styled-components';

import { Form as UnForm } from '@unform/web';

export const Container = styled.div``;

export const Content = styled.div`
  background: #fff;
`;

export const Menu = styled.div`
  padding: 16px;
  width: 280px;
`;

export const Main = styled(UnForm)`
  width: 100%;
  padding: 16px;
  display: grid;
  grid-gap: 26px;
  max-width: 800px;
`;

export const FormGrid = styled(UnForm)`
  display: grid;
  grid-gap: 26px;
  grid-template-columns: 1fr 1fr;
`;

export const Buttons = styled.div`
  & > * {
    margin-right: 16px;
  }
`;

export const StyleBrand = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-size: 16px;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
`;

export const BredCrumb = styled.div`
  color: ${(props) => props.color};
  display: flex;
  flex: 1;
  align-items: center;

  span {
    margin: 0 8px;
  }

  h6 {
    color: ${(props) => props.color};
  }
`;

export const NavRight = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-flow: row-reverse;

  & > * {
    margin: 0 8px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
