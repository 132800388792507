import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';

import api from '~/services/http';
import { orderByPosition } from '~/utils';
import { coursesSelector } from './courses';

const TYPE = 'categories';

export const createCategory = createAsyncThunk(`${TYPE}/create`, async (category) => {
  const response = await api.post(`/categories`, category);
  return { id: response.id, ...category };
});

export const removeCategory = createAsyncThunk(`${TYPE}/remove`, async (id) => {
  const response = await api.delete(`/categories?id=${id}`);
  return { id: response.id };
});

export const fetchCategories = createAsyncThunk(`${TYPE}/fetch`, async () => {
  const response = await api.get(`/categories`);
  return response.data;
});

export const updateCategory = createAsyncThunk(`${TYPE}/update`, async (category) => {
  const response = await api.put(`/categories`, category);
  return { id: response.id, ...category };
});

export const reorderCategories = createAsyncThunk(`${TYPE}/reorder`, async (order) => {
  api.post(`/massive`, {
    categories: order,
  });
  return order;
});

const categoriesAdapter = createEntityAdapter({
  selectId: (c) => c.id,
  sortComparer: (a, b) => a.position - b.position,
});

export const categoriesSelector = categoriesAdapter.getSelectors((state) => state.categories);

const categorySlice = createSlice({
  name: TYPE,
  initialState: categoriesAdapter.getInitialState({ loading: false }),
  reducers: {},
  extraReducers: {
    [fetchCategories.pending]: (state) => {
      state.loading = true;
    },
    [fetchCategories.fulfilled]: (state, { payload }) => {
      categoriesAdapter.setAll(state, payload || []);
      state.loading = false;
    },
    [createCategory.fulfilled]: (state, { payload }) => {
      categoriesAdapter.addOne(state, payload);
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      categoriesAdapter.updateOne(state, payload);
    },
    [removeCategory.fulfilled]: (state, { payload }) => {
      categoriesAdapter.removeOne(state, payload.id);
    },
    [reorderCategories.fulfilled]: (state, { payload }) => {
      payload.forEach((id, index) => {
        state.entities[id].position = index + 1;
      });
    },
  },
});

export const categoriesAndCoursesSelector = createSelector(
  categoriesSelector.selectAll,
  coursesSelector.selectAll,
  (categories, courses) => {
    const data = categories.map((category, index) => ({
      ...category,
      courses: orderByPosition(
        courses.filter(
          (course) => course.category === category.id || (index === 0 && !course.category),
        ),
      ),
    }));
    if (!data[0]) {
      return [
        {
          id: 1,
          name: '',
          inMemory: true,
          courses: orderByPosition(courses),
        },
      ];
    }
    return orderByPosition(data);
  },
);

const PUBLIC_COURSE_STATUS = 1;

export const categoriesAndActiveCoursesSelector = createSelector(
  categoriesAndCoursesSelector,
  (categories) => {
    categories.forEach((category) => {
      category.courses = category.courses.filter((course) => course.status === PUBLIC_COURSE_STATUS);
    });
    return categories;
  },
);

export const studentHomeSelector = createSelector(
  state => {
    return state.subscriptions.entities.courses.filter(course => course.status === PUBLIC_COURSE_STATUS || course.joined);
  },
  categoriesSelector.selectAll,
  (courses, categories) => {
    if(categories.length === 0) {
      return [{
        id: 1,
        name: 'Cursos',
        description: 'Conheça todos os nossos cursos.',
        courses: orderByPosition([...courses]),
      }]
    }
    const data = categories.map((category, index) => ({
      ...category,
      courses: orderByPosition(
        courses.filter(
          (course) => course.category === category.id || (index === 0 && !course.category),
        ),
      ),
    }));
    return orderByPosition(data);
  }
);

export default categorySlice;
