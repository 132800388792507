import React, { useState, useMemo, useCallback, useRef } from 'react';

import api from '~/services/api';
import useEventListener from '~/hooks/useEventListener';

import { Container, OptionList, Option } from './styles';

function SchoolPlanOptionsCell({ school, isDropup }) {
  const dropdownRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(school.plan.name);

  const handleToggleDropdown = useCallback(() => {
    setIsDropdownVisible((prevIsDropdownVisible) => !prevIsDropdownVisible);
  }, []);

  const handleCloseDropdown = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    },
    [setIsDropdownVisible],
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setIsLoading(true);

        await api.put(`/colleges/${school.id}/plan`, { planId: data.id });

        setSelectedPlan(data.name);
        setIsDropdownVisible(false);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [school],
  );

  useEventListener('click', handleCloseDropdown, {
    enabled: isDropdownVisible,
  });

  const options = useMemo(
    () => [
      { id: 1, name: 'Trial', color: '#455A64' },
      { id: 2, name: 'Essencial', color: '#3D5AFE' },
      { id: 3, name: 'Pro', color: '#D81B60' },
      { id: 4, name: 'Escala', color: '#795548' },
      { id: 5, name: 'Custom', color: '#F4511E' },
    ],
    [],
  );

  const selectedColor = useMemo(() => {
    const findColor = options.find((schoolPlan) => schoolPlan.name === selectedPlan);

    if (findColor) {
      return findColor.color;
    }

    return '#7159c1';
  }, [options, selectedPlan]);

  return (
    <Container ref={dropdownRef} color={selectedColor}>
      <button type="button" onClick={handleToggleDropdown}>
        {isLoading ? 'Atualizando..' : selectedPlan}
      </button>

      <OptionList visible={isDropdownVisible} isDropup={isDropup}>
        {options.map((option) => (
          <Option key={option.id} color={option.color} onClick={() => handleSubmit(option)}>
            <span>{option.name}</span>
          </Option>
        ))}
      </OptionList>
    </Container>
  );
}

export default SchoolPlanOptionsCell;
