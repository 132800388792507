import styled from 'styled-components';

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: ${props => props.theme.pxToRem(props.margin)} 0px;
  background-color: rgba(0, 0, 0, 0.1);
`;

export default Divider;
