import React from 'react';
import styled, { css } from 'styled-components';

import Cover from '../Cover';
import Typography from '../Typography';
import Progress from '../Progress';
import LabelInfo from '../LabelInfo';
import Paper from '../Paper';
import Button from '../Button';
import BackDrop from '../BackDrop';

import { stringToBoolean } from '../../utils';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  z-index: 9998;
  width: 320px;
  min-height: 100vh;
  background: #eceff1;
  padding: 26px 36px;
  .cover {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  @media (max-width: 768px) {
    position: fixed;
    transform: translateX(-320px);
    ${(props) =>
      props.show &&
      css`
        transform: none;
      `}
  }
`;

export const CardCertificate = styled.div`
  display: flex;
  img {
    padding: 0.5rem;
    width: 50px;
  }
`;

export default ({ course = {}, show, onClose, onGenerateCertificate }) => {
  function formatDate(d) {
    const date = new Date(d);
    return date.toLocaleDateString('pt-BR', { month: 'short', day: 'numeric' });
  }

  return (
    <>
      <BackDrop show={show} onClick={onClose} />
      <Container show={show}>
        <div className="cover">
          <Cover
            width={180}
            title={course.name}
            img={course.properties?.cover}
            color={course.properties?.color_primary}
            coverPosition={course.properties?.cover_position}
          />
          <br />
          {/* <Rating readonly initialRating={course.rating} /> */}
        </div>
        <div>
          <Typography variant="h4" gutterBottom="16px">
            {course.name}
          </Typography>
          <Typography variant="body2" gutterBottom="16px">
            {course.properties?.description}
          </Typography>
          {course.progress > 74 &&
          stringToBoolean(course.properties?.certification_enabled)
           ? (
             <Paper padding={12} radius={16}>
               <Progress
                 color="success"
                 height={8}
                 width={200}
                 progress={course.progress}
                 background="#fff"
               />
               <CardCertificate>
                 <img src="/img/medal.svg" alt="medalha" />
                 <div>
                   <Typography variant="body2" gutterTop="8px" gutterBottom="6px" color="g7">
                     O certificado para este curso já está disponível!
                   </Typography>
                   <Button onClick={onGenerateCertificate} size="sm" outline color="white">
                     Emitir certificado
                   </Button>
                 </div>
               </CardCertificate>
             </Paper>
          ) : (
            <Progress
              color="success"
              height={8}
              width={200}
              progress={course.progress}
              background="#fff"
            />
          )}
        </div>
        <br />
        <br />
        <div>
          <LabelInfo label="DATA DE INÍCIO">{formatDate(course.joined)}</LabelInfo>
          <LabelInfo label="AULAS COMPLETAS">
            {`${course.classesCount.completed} de ${course.classesCount.total} (${((course.classesCount.completed*100)/course.classesCount.total).toFixed(2)}%)`}
          </LabelInfo>
        </div>
      </Container>
    </>
  );
};
