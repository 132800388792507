import React from 'react';
import styled from 'styled-components';

import Typography from '../Typography';
import Switch from '../Switch';
import Button from '../Button';

const Container = styled.div`
  background-color: white;
  border: 1px solid ${(props) => props.theme.palette.g2};
  border-radius: 5px;
  width: 100%;
  .section-form-title {
    padding: 30px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.palette.g2};
    h3,
    h4 {
      margin-bottom: 6px;
    }
    > .section-form-title-title {
      flex: 1;
    }
  }
  .section-form-content {
    padding: ${props => props.theme.pxToRem(props.padding)};
  }
  form {
    width: 50%;
  }
`;

export default function SectionForm({
  title,
  description,
  toggle,
  button,
  buttonColor = 'success',
  buttonLabel= '',
  buttonHandle= '',
  checked = true,
  onToggle,
  titleVariant = 'h3',
  padding = 30,
  children,
  ...props
}) {
  return (
    <Container title={title} toggle={toggle} padding={padding} {...props}>
      <div className="section-form">
        {title && (
          <div className="section-form-title">
            <div className="section-form-title-title">
              <Typography variant={titleVariant}>{title}</Typography>
              <Typography variant="body2">{description}</Typography>
            </div>
            {toggle && (
              <div className="section-form-title-actions">
                <Switch checked={checked} onChange={onToggle} />
              </div>
            )}
            {button && (
              <Button size="sm" color={buttonColor} onClick={buttonHandle} outline>
                {buttonLabel}
              </Button>
            )}
          </div>
        )}
        {children && checked && <div className="section-form-content">{children}</div>}
      </div>
    </Container>
  );
}
