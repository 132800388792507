import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20px;
    height: 20px;

    outline: none;

    border-radius: 30px;
    border: none;

    background: transparent;

    font-size: 14px;
    font-weight: 600;

    cursor: pointer;

    :hover {
      background: ${darken(0.05, '#fff')};
    }

    svg {
      transition: fill 0.2s ease 0s;
    }
  }
`;

export const OptionList = styled.div`
  z-index: 2;

  position: absolute;
  top: calc(100% + 16px);
  right: 0;

  width: 150px;
  border-radius: 5px;

  background: #fff;

  opacity: 0;
  visibility: hidden;

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;

  ::before {
    position: absolute;
    top: -8px;
    right: 4px;

    content: '';

    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent #ffffff;
  }

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  ${(props) =>
    props.isDropup &&
    css`
      top: unset;
      bottom: calc(100% + 16px);

      ::before {
        top: unset;
        bottom: -8px;
        transform: rotate(180deg);
      }
    `}
`;

export const Option = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 5px;

  cursor: pointer;

  font-size: 14px;
  font-weight: 600;

  :hover {
    background: ${darken(0.02, '#fff')};
  }

  :not(:last-of-type) {
    margin-bottom: 14px;
  }

  span {
    width: 100%;

    text-align: center;
  }
`;
