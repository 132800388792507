import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SuperLayout from '../layouts/SuperLayout';

export default function AdminRoutes() {
  return (
    <Switch>
      <Route path="/super">
        <SuperLayout />
      </Route>

      <Redirect path="*" to="/super" />
    </Switch>
  );
}
