import React, { useState, useMemo } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Form as UnForm } from '@unform/web';
import { toast } from 'react-toastify';

import { useAuth } from '~/contexts/auth';
import { useStyle } from '~/contexts/signIn';

import Promotion from '../SignIn/Promotion';

import Brand from '~/layouts/Brand';
import { Can, Input, Button, PageLoading } from '~/components';

import { Container, Column, BrandContainer, FormContainer, Header, Footer } from './styles';

export default function ResetPassword() {
  const history = useHistory();
  const { token, create } = useParams();

  const [loading, setLoading] = useState(false);

  const { resetPassword } = useAuth();
  const { school, styles } = useStyle();

  async function handleForgotPassword({ email }, { reset }) {
    try {
      setLoading(true);

      await resetPassword({ school: school.id, mail: email });

      toast('Um e-mail de recuperação foi enviado', {
        autoClose: 3000,
        type: 'info',
      });
    } catch {
      reset();
    } finally {
      setLoading(false);
    }
  }

  async function handleResetPassword({ password }, { reset }) {
    try {
      setLoading(true);

      await resetPassword({ school: school.id, new_pwd: password, token });

      toast('Senha resetada com sucesso', {
        autoClose: 3000,
        type: 'info',
      });

      history.push('/');
    } catch {
      reset();
    } finally {
      setLoading(false);
    }
  }

  const isResetPassword = useMemo(() => !!token, [token]);

  const { title, subtitle, submit } = useMemo(() => {
    return isResetPassword
      ? {
          title: create ? 'Criar' : 'Recuperação',
          subtitle: 'Defina sua nova senha de acesso',
          submit: 'Definir',
        }
      : {
          title: 'Recupere sua senha.',
          subtitle: 'Confirme seu endereço de email',
          submit: 'Recuperar minha senha',
        };
  }, [isResetPassword, create]);

  if (styles.loading) {
    return <PageLoading />;
  }

  return (
    <UnForm onSubmit={isResetPassword ? handleResetPassword : handleForgotPassword}>
      <Container columns={styles?.rightColumn.visible ? '1fr 1fr' : '1fr'}>
        <Column styles={styles?.leftColumn.styles}>
          <BrandContainer styles={styles?.leftColumn.logo.styles}>
            <Can renderable={!!styles?.leftColumn.logo.styles.bgImage}>
              <img src={styles?.leftColumn.logo.styles.bgImage} loading="lazy" alt="logo" />
            </Can>

            <Can renderable={!styles?.leftColumn.logo.styles.bgImage}>
              <Brand color={styles?.leftColumn.logo.styles.fontColor} />
            </Can>
          </BrandContainer>

          <FormContainer styles={styles?.leftColumn.form.styles}>
            <Header styles={styles?.leftColumn.form.styles}>
              <h1>{title}</h1>
              <p>{subtitle}</p>
            </Header>

            <Can renderable={!token && !isResetPassword}>
              <Input type="email" name="email" label="E-mail" required fullWidth />
            </Can>

            <Can renderable={isResetPassword}>
              <Input type="password" name="password" label="Senha" required fullWidth />
            </Can>

            <Button
              type="submit"
              color={styles?.leftColumn.form.styles.fontColor}
              loading={loading}
              disabled={loading}
              contrast
              block
            >
              {submit}
            </Button>

            <Can renderable={!token}>
              <Footer styles={styles?.leftColumn.form.styles}>
                <Link to="/">Me lembrei!</Link>
              </Footer>
            </Can>
          </FormContainer>
        </Column>

        <Can renderable={styles?.rightColumn.visible}>
          <Promotion editable={false} />
        </Can>
      </Container>
    </UnForm>
  );
}
