import React from 'react';
import styled from 'styled-components';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

import ButtonBase from '../Button';
import Paper from '../Paper';

const Container = styled.div`
  display: flex;
  position: relative;
  width: max-content;
`;

const Button = styled(ButtonBase)`
  border-radius: 4px 0px 0px 4px;
`;

const RightAction = styled(ButtonBase)`
  min-width: 28px;
  width: 28px;
  padding: 0;
  border-left: 1px solid #fff;
  border-radius: 0px 4px 4px 0px;
`;

const Menu = styled(Paper)`
  right: 0px;
  top: 28.8px;
  padding: 0px;
  z-index: 9998;
  position: absolute;
  background: #fff;
  width: 100%;
  max-width: 200px;
  border-radius: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const ButtonMenuOption = styled.div`
  cursor: pointer;
  padding: 8px;
  font-size: 14.5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:hover{
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

export default function ButtonMenu({ children, color, label, ...props }) {
  const [show, setShow] = React.useState(false);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Container>
        <Button color={color} size="sm" {...props}>
          {label}
        </Button>
        <RightAction type="button" color={color} size="sm" onClick={handleShow}>
          <FontAwesomeIcon icon={faAngleDown} />
        </RightAction>
        {show && (
          <Menu onClick={handleClose}>
            {children}
          </Menu>
        )}
      </Container>
    </ClickAwayListener>
  );
}
