import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    background: #fff;
    color: #000;
  }
  textarea, input[type="date"] {
    font-family: 'Open Sans',sans-serif;
  }
  ul {
    list-style-type: none;
  }
  .tox-tinymce {
    border: none !important;
  }
  a {
    text-decoration: none;
  }

  .tox-notification {display: none !important;}

  .hidden-xs{
    @media (max-width: 767px){
      display: none !important;
    }
  }
  .hidden-sm{
    @media (min-width: 768px) and (max-width: 834px){
      display: none !important;
    }
  }
  .hidden-md{
    @media (min-width: 768px) {
      display: none !important;
    }
  }
  .hidden-lg{
    @media (min-width: 835px){
      display: none !important;
    }
  }

  .push-right{
    float: right;
  }
  .push-left{
    float: left;
  }
  .no-push{
    float: initial;
  }
  .no-push-xs{
    @media (max-width: 768px){
      float: initial;
    }
  }
  @media (max-width: 600px){
    html {
      font-size: 14px !important;
    }
  }

  .hidden-beacon {
    #beacon-container {
      display: none !important;
    }
  }

  .w-25 {
        width: 25% !important;
      }

      .w-50 {
        width: 50% !important;
      }

      .w-75 {
        width: 75% !important;
      }

      .w-100 {
        width: 100% !important;
      }

      .w-auto {
        width: auto !important;
      }

      .h-25 {
        height: 25% !important;
      }

      .h-50 {
        height: 50% !important;
      }

      .h-75 {
        height: 75% !important;
      }

      .h-100 {
        height: 100% !important;
      }

      .h-auto {
        height: auto !important;
      }

      .mw-100 {
        max-width: 100% !important;
      }

      .mh-100 {
        max-height: 100% !important;
      }

      .m-0 {
        margin: 0 !important;
      }

      .mt-0,
      .my-0 {
        margin-top: 0 !important;
      }

      .mr-0,
      .mx-0 {
        margin-right: 0 !important;
      }

      .mb-0,
      .my-0 {
        margin-bottom: 0 !important;
      }

      .ml-0,
      .mx-0 {
        margin-left: 0 !important;
      }

      .m-1 {
        margin: 0.25rem !important;
      }

      .mt-1,
      .my-1 {
        margin-top: 0.25rem !important;
      }

      .mr-1,
      .mx-1 {
        margin-right: 0.25rem !important;
      }

      .mb-1,
      .my-1 {
        margin-bottom: 0.25rem !important;
      }

      .ml-1,
      .mx-1 {
        margin-left: 0.25rem !important;
      }

      .m-2 {
        margin: 0.5rem !important;
      }

      .mt-2,
      .my-2 {
        margin-top: 0.5rem !important;
      }

      .mr-2,
      .mx-2 {
        margin-right: 0.5rem !important;
      }

      .mb-2,
      .my-2 {
        margin-bottom: 0.5rem !important;
      }

      .ml-2,
      .mx-2 {
        margin-left: 0.5rem !important;
      }

      .m-3 {
        margin: 1rem !important;
      }

      .mt-3,
      .my-3 {
        margin-top: 1rem !important;
      }

      .mr-3,
      .mx-3 {
        margin-right: 1rem !important;
      }

      .mb-3,
      .my-3 {
        margin-bottom: 1rem !important;
      }

      .ml-3,
      .mx-3 {
        margin-left: 1rem !important;
      }

      .m-4 {
        margin: 1.5rem !important;
      }

      .mt-4,
      .my-4 {
        margin-top: 1.5rem !important;
      }

      .mr-4,
      .mx-4 {
        margin-right: 1.5rem !important;
      }

      .mb-4,
      .my-4 {
        margin-bottom: 1.5rem !important;
      }

      .ml-4,
      .mx-4 {
        margin-left: 1.5rem !important;
      }

      .m-5 {
        margin: 3rem !important;
      }

      .mt-5,
      .my-5 {
        margin-top: 3rem !important;
      }

      .mr-5,
      .mx-5 {
        margin-right: 3rem !important;
      }

      .mb-5,
      .my-5 {
        margin-bottom: 3rem !important;
      }

      .ml-5,
      .mx-5 {
        margin-left: 3rem !important;
      }

      .m-6 {
        margin: 4.5rem !important;
      }

      .mt-6,
      .my-6 {
        margin-top: 4.5rem !important;
      }

      .mr-6,
      .mx-6 {
        margin-right: 4.5rem !important;
      }

      .mb-6,
      .my-6 {
        margin-bottom: 4.5rem !important;
      }

      .ml-6,
      .mx-6 {
        margin-left: 4.5rem !important;
      }

      .m-7 {
        margin: 6rem !important;
      }

      .mt-7,
      .my-7 {
        margin-top: 6rem !important;
      }

      .mr-7,
      .mx-7 {
        margin-right: 6rem !important;
      }

      .mb-7,
      .my-7 {
        margin-bottom: 6rem !important;
      }

      .ml-7,
      .mx-7 {
        margin-left: 6rem !important;
      }

      .m-8 {
        margin: 9rem !important;
      }

      .mt-8,
      .my-8 {
        margin-top: 9rem !important;
      }

      .mr-8,
      .mx-8 {
        margin-right: 9rem !important;
      }

      .mb-8,
      .my-8 {
        margin-bottom: 9rem !important;
      }

      .ml-8,
      .mx-8 {
        margin-left: 9rem !important;
      }

      .m-9 {
        margin: 12rem !important;
      }

      .mt-9,
      .my-9 {
        margin-top: 12rem !important;
      }

      .mr-9,
      .mx-9 {
        margin-right: 12rem !important;
      }

      .mb-9,
      .my-9 {
        margin-bottom: 12rem !important;
      }

      .ml-9,
      .mx-9 {
        margin-left: 12rem !important;
      }

      .m-10 {
        margin: 18rem !important;
      }

      .mt-10,
      .my-10 {
        margin-top: 18rem !important;
      }

      .mr-10,
      .mx-10 {
        margin-right: 18rem !important;
      }

      .mb-10,
      .my-10 {
        margin-bottom: 18rem !important;
      }

      .ml-10,
      .mx-10 {
        margin-left: 18rem !important;
      }

      .p-0 {
        padding: 0 !important;
      }

      .pt-0,
      .py-0 {
        padding-top: 0 !important;
      }

      .pr-0,
      .px-0 {
        padding-right: 0 !important;
      }

      .pb-0,
      .py-0 {
        padding-bottom: 0 !important;
      }

      .pl-0,
      .px-0 {
        padding-left: 0 !important;
      }

      .p-1 {
        padding: 0.25rem !important;
      }

      .pt-1,
      .py-1 {
        padding-top: 0.25rem !important;
      }

      .pr-1,
      .px-1 {
        padding-right: 0.25rem !important;
      }

      .pb-1,
      .py-1 {
        padding-bottom: 0.25rem !important;
      }

      .pl-1,
      .px-1 {
        padding-left: 0.25rem !important;
      }

      .p-2 {
        padding: 0.5rem !important;
      }

      .pt-2,
      .py-2 {
        padding-top: 0.5rem !important;
      }

      .pr-2,
      .px-2 {
        padding-right: 0.5rem !important;
      }

      .pb-2,
      .py-2 {
        padding-bottom: 0.5rem !important;
      }

      .pl-2,
      .px-2 {
        padding-left: 0.5rem !important;
      }

      .p-3 {
        padding: 1rem !important;
      }

      .pt-3,
      .py-3 {
        padding-top: 1rem !important;
      }

      .pr-3,
      .px-3 {
        padding-right: 1rem !important;
      }

      .pb-3,
      .py-3 {
        padding-bottom: 1rem !important;
      }

      .pl-3,
      .px-3 {
        padding-left: 1rem !important;
      }

      .p-4 {
        padding: 1.5rem !important;
      }

      .pt-4,
      .py-4 {
        padding-top: 1.5rem !important;
      }

      .pr-4,
      .px-4 {
        padding-right: 1.5rem !important;
      }

      .pb-4,
      .py-4 {
        padding-bottom: 1.5rem !important;
      }

      .pl-4,
      .px-4 {
        padding-left: 1.5rem !important;
      }

      .p-5 {
        padding: 3rem !important;
      }

      .pt-5,
      .py-5 {
        padding-top: 3rem !important;
      }

      .pr-5,
      .px-5 {
        padding-right: 3rem !important;
      }

      .pb-5,
      .py-5 {
        padding-bottom: 3rem !important;
      }

      .pl-5,
      .px-5 {
        padding-left: 3rem !important;
      }

      .p-6 {
        padding: 4.5rem !important;
      }

      .pt-6,
      .py-6 {
        padding-top: 4.5rem !important;
      }

      .pr-6,
      .px-6 {
        padding-right: 4.5rem !important;
      }

      .pb-6,
      .py-6 {
        padding-bottom: 4.5rem !important;
      }

      .pl-6,
      .px-6 {
        padding-left: 4.5rem !important;
      }

      .p-7 {
        padding: 6rem !important;
      }

      .pt-7,
      .py-7 {
        padding-top: 6rem !important;
      }

      .pr-7,
      .px-7 {
        padding-right: 6rem !important;
      }

      .pb-7,
      .py-7 {
        padding-bottom: 6rem !important;
      }

      .pl-7,
      .px-7 {
        padding-left: 6rem !important;
      }

      .p-8 {
        padding: 9rem !important;
      }

      .pt-8,
      .py-8 {
        padding-top: 9rem !important;
      }

      .pr-8,
      .px-8 {
        padding-right: 9rem !important;
      }

      .pb-8,
      .py-8 {
        padding-bottom: 9rem !important;
      }

      .pl-8,
      .px-8 {
        padding-left: 9rem !important;
      }

      .p-9 {
        padding: 12rem !important;
      }

      .pt-9,
      .py-9 {
        padding-top: 12rem !important;
      }

      .pr-9,
      .px-9 {
        padding-right: 12rem !important;
      }

      .pb-9,
      .py-9 {
        padding-bottom: 12rem !important;
      }

      .pl-9,
      .px-9 {
        padding-left: 12rem !important;
      }

      .p-10 {
        padding: 18rem !important;
      }

      .pt-10,
      .py-10 {
        padding-top: 18rem !important;
      }

      .pr-10,
      .px-10 {
        padding-right: 18rem !important;
      }

      .pb-10,
      .py-10 {
        padding-bottom: 18rem !important;
      }

      .pl-10,
      .px-10 {
        padding-left: 18rem !important;
      }

      .m-auto {
        margin: auto !important;
      }

      .mt-auto,
      .my-auto {
        margin-top: auto !important;
      }

      .mr-auto,
      .mx-auto {
        margin-right: auto !important;
      }

      .mb-auto,
      .my-auto {
        margin-bottom: auto !important;
      }

      .ml-auto,
      .mx-auto {
        margin-left: auto !important;
      }

      @media (min-width: 320px) and (max-width: 767px) {
        .m-xs-0 {
          margin: 0 !important;
        }
        .mt-xs-0,
        .my-xs-0 {
          margin-top: 0 !important;
        }
        .mr-xs-0,
        .mx-xs-0 {
          margin-right: 0 !important;
        }
        .mb-xs-0,
        .my-xs-0 {
          margin-bottom: 0 !important;
        }
        .ml-xs-0,
        .mx-xs-0 {
          margin-left: 0 !important;
        }
        .m-xs-1 {
          margin: 0.25rem !important;
        }
        .mt-xs-1,
        .my-xs-1 {
          margin-top: 0.25rem !important;
        }
        .mr-xs-1,
        .mx-xs-1 {
          margin-right: 0.25rem !important;
        }
        .mb-xs-1,
        .my-xs-1 {
          margin-bottom: 0.25rem !important;
        }
        .ml-xs-1,
        .mx-xs-1 {
          margin-left: 0.25rem !important;
        }
        .m-xs-2 {
          margin: 0.5rem !important;
        }
        .mt-xs-2,
        .my-xs-2 {
          margin-top: 0.5rem !important;
        }
        .mr-xs-2,
        .mx-xs-2 {
          margin-right: 0.5rem !important;
        }
        .mb-xs-2,
        .my-xs-2 {
          margin-bottom: 0.5rem !important;
        }
        .ml-xs-2,
        .mx-xs-2 {
          margin-left: 0.5rem !important;
        }
        .m-xs-3 {
          margin: 1rem !important;
        }
        .mt-xs-3,
        .my-xs-3 {
          margin-top: 1rem !important;
        }
        .mr-xs-3,
        .mx-xs-3 {
          margin-right: 1rem !important;
        }
        .mb-xs-3,
        .my-xs-3 {
          margin-bottom: 1rem !important;
        }
        .ml-xs-3,
        .mx-xs-3 {
          margin-left: 1rem !important;
        }
        .m-xs-4 {
          margin: 1.5rem !important;
        }
        .mt-xs-4,
        .my-xs-4 {
          margin-top: 1.5rem !important;
        }
        .mr-xs-4,
        .mx-xs-4 {
          margin-right: 1.5rem !important;
        }
        .mb-xs-4,
        .my-xs-4 {
          margin-bottom: 1.5rem !important;
        }
        .ml-xs-4,
        .mx-xs-4 {
          margin-left: 1.5rem !important;
        }
        .m-xs-5 {
          margin: 3rem !important;
        }
        .mt-xs-5,
        .my-xs-5 {
          margin-top: 3rem !important;
        }
        .mr-xs-5,
        .mx-xs-5 {
          margin-right: 3rem !important;
        }
        .mb-xs-5,
        .my-xs-5 {
          margin-bottom: 3rem !important;
        }
        .ml-xs-5,
        .mx-xs-5 {
          margin-left: 3rem !important;
        }
        .m-xs-6 {
          margin: 4.5rem !important;
        }
        .mt-xs-6,
        .my-xs-6 {
          margin-top: 4.5rem !important;
        }
        .mr-xs-6,
        .mx-xs-6 {
          margin-right: 4.5rem !important;
        }
        .mb-xs-6,
        .my-xs-6 {
          margin-bottom: 4.5rem !important;
        }
        .ml-xs-6,
        .mx-xs-6 {
          margin-left: 4.5rem !important;
        }
        .m-xs-7 {
          margin: 6rem !important;
        }
        .mt-xs-7,
        .my-xs-7 {
          margin-top: 6rem !important;
        }
        .mr-xs-7,
        .mx-xs-7 {
          margin-right: 6rem !important;
        }
        .mb-xs-7,
        .my-xs-7 {
          margin-bottom: 6rem !important;
        }
        .ml-xs-7,
        .mx-xs-7 {
          margin-left: 6rem !important;
        }
        .m-xs-8 {
          margin: 9rem !important;
        }
        .mt-xs-8,
        .my-xs-8 {
          margin-top: 9rem !important;
        }
        .mr-xs-8,
        .mx-xs-8 {
          margin-right: 9rem !important;
        }
        .mb-xs-8,
        .my-xs-8 {
          margin-bottom: 9rem !important;
        }
        .ml-xs-8,
        .mx-xs-8 {
          margin-left: 9rem !important;
        }
        .m-xs-9 {
          margin: 12rem !important;
        }
        .mt-xs-9,
        .my-xs-9 {
          margin-top: 12rem !important;
        }
        .mr-xs-9,
        .mx-xs-9 {
          margin-right: 12rem !important;
        }
        .mb-xs-9,
        .my-xs-9 {
          margin-bottom: 12rem !important;
        }
        .ml-xs-9,
        .mx-xs-9 {
          margin-left: 12rem !important;
        }
        .m-xs-10 {
          margin: 18rem !important;
        }
        .mt-xs-10,
        .my-xs-10 {
          margin-top: 18rem !important;
        }
        .mr-xs-10,
        .mx-xs-10 {
          margin-right: 18rem !important;
        }
        .mb-xs-10,
        .my-xs-10 {
          margin-bottom: 18rem !important;
        }
        .ml-xs-10,
        .mx-xs-10 {
          margin-left: 18rem !important;
        }
        .p-xs-0 {
          padding: 0 !important;
        }
        .pt-xs-0,
        .py-xs-0 {
          padding-top: 0 !important;
        }
        .pr-xs-0,
        .px-xs-0 {
          padding-right: 0 !important;
        }
        .pb-xs-0,
        .py-xs-0 {
          padding-bottom: 0 !important;
        }
        .pl-xs-0,
        .px-xs-0 {
          padding-left: 0 !important;
        }
        .p-xs-1 {
          padding: 0.25rem !important;
        }
        .pt-xs-1,
        .py-xs-1 {
          padding-top: 0.25rem !important;
        }
        .pr-xs-1,
        .px-xs-1 {
          padding-right: 0.25rem !important;
        }
        .pb-xs-1,
        .py-xs-1 {
          padding-bottom: 0.25rem !important;
        }
        .pl-xs-1,
        .px-xs-1 {
          padding-left: 0.25rem !important;
        }
        .p-xs-2 {
          padding: 0.5rem !important;
        }
        .pt-xs-2,
        .py-xs-2 {
          padding-top: 0.5rem !important;
        }
        .pr-xs-2,
        .px-xs-2 {
          padding-right: 0.5rem !important;
        }
        .pb-xs-2,
        .py-xs-2 {
          padding-bottom: 0.5rem !important;
        }
        .pl-xs-2,
        .px-xs-2 {
          padding-left: 0.5rem !important;
        }
        .p-xs-3 {
          padding: 1rem !important;
        }
        .pt-xs-3,
        .py-xs-3 {
          padding-top: 1rem !important;
        }
        .pr-xs-3,
        .px-xs-3 {
          padding-right: 1rem !important;
        }
        .pb-xs-3,
        .py-xs-3 {
          padding-bottom: 1rem !important;
        }
        .pl-xs-3,
        .px-xs-3 {
          padding-left: 1rem !important;
        }
        .p-xs-4 {
          padding: 1.5rem !important;
        }
        .pt-xs-4,
        .py-xs-4 {
          padding-top: 1.5rem !important;
        }
        .pr-xs-4,
        .px-xs-4 {
          padding-right: 1.5rem !important;
        }
        .pb-xs-4,
        .py-xs-4 {
          padding-bottom: 1.5rem !important;
        }
        .pl-xs-4,
        .px-xs-4 {
          padding-left: 1.5rem !important;
        }
        .p-xs-5 {
          padding: 3rem !important;
        }
        .pt-xs-5,
        .py-xs-5 {
          padding-top: 3rem !important;
        }
        .pr-xs-5,
        .px-xs-5 {
          padding-right: 3rem !important;
        }
        .pb-xs-5,
        .py-xs-5 {
          padding-bottom: 3rem !important;
        }
        .pl-xs-5,
        .px-xs-5 {
          padding-left: 3rem !important;
        }
        .p-xs-6 {
          padding: 4.5rem !important;
        }
        .pt-xs-6,
        .py-xs-6 {
          padding-top: 4.5rem !important;
        }
        .pr-xs-6,
        .px-xs-6 {
          padding-right: 4.5rem !important;
        }
        .pb-xs-6,
        .py-xs-6 {
          padding-bottom: 4.5rem !important;
        }
        .pl-xs-6,
        .px-xs-6 {
          padding-left: 4.5rem !important;
        }
        .p-xs-7 {
          padding: 6rem !important;
        }
        .pt-xs-7,
        .py-xs-7 {
          padding-top: 6rem !important;
        }
        .pr-xs-7,
        .px-xs-7 {
          padding-right: 6rem !important;
        }
        .pb-xs-7,
        .py-xs-7 {
          padding-bottom: 6rem !important;
        }
        .pl-xs-7,
        .px-xs-7 {
          padding-left: 6rem !important;
        }
        .p-xs-8 {
          padding: 9rem !important;
        }
        .pt-xs-8,
        .py-xs-8 {
          padding-top: 9rem !important;
        }
        .pr-xs-8,
        .px-xs-8 {
          padding-right: 9rem !important;
        }
        .pb-xs-8,
        .py-xs-8 {
          padding-bottom: 9rem !important;
        }
        .pl-xs-8,
        .px-xs-8 {
          padding-left: 9rem !important;
        }
        .p-xs-9 {
          padding: 12rem !important;
        }
        .pt-xs-9,
        .py-xs-9 {
          padding-top: 12rem !important;
        }
        .pr-xs-9,
        .px-xs-9 {
          padding-right: 12rem !important;
        }
        .pb-xs-9,
        .py-xs-9 {
          padding-bottom: 12rem !important;
        }
        .pl-xs-9,
        .px-xs-9 {
          padding-left: 12rem !important;
        }
        .p-xs-10 {
          padding: 18rem !important;
        }
        .pt-xs-10,
        .py-xs-10 {
          padding-top: 18rem !important;
        }
        .pr-xs-10,
        .px-xs-10 {
          padding-right: 18rem !important;
        }
        .pb-xs-10,
        .py-xs-10 {
          padding-bottom: 18rem !important;
        }
        .pl-xs-10,
        .px-xs-10 {
          padding-left: 18rem !important;
        }
        .m-xs-auto {
          margin: auto !important;
        }
        .mt-xs-auto,
        .my-xs-auto {
          margin-top: auto !important;
        }
        .mr-xs-auto,
        .mx-xs-auto {
          margin-right: auto !important;
        }
        .mb-xs-auto,
        .my-xs-auto {
          margin-bottom: auto !important;
        }
        .ml-xs-auto,
        .mx-xs-auto {
          margin-left: auto !important;
        }
      }

      @media (min-width: 768px) {
        .m-sm-0 {
          margin: 0 !important;
        }
        .mt-sm-0,
        .my-sm-0 {
          margin-top: 0 !important;
        }
        .mr-sm-0,
        .mx-sm-0 {
          margin-right: 0 !important;
        }
        .mb-sm-0,
        .my-sm-0 {
          margin-bottom: 0 !important;
        }
        .ml-sm-0,
        .mx-sm-0 {
          margin-left: 0 !important;
        }
        .m-sm-1 {
          margin: 0.25rem !important;
        }
        .mt-sm-1,
        .my-sm-1 {
          margin-top: 0.25rem !important;
        }
        .mr-sm-1,
        .mx-sm-1 {
          margin-right: 0.25rem !important;
        }
        .mb-sm-1,
        .my-sm-1 {
          margin-bottom: 0.25rem !important;
        }
        .ml-sm-1,
        .mx-sm-1 {
          margin-left: 0.25rem !important;
        }
        .m-sm-2 {
          margin: 0.5rem !important;
        }
        .mt-sm-2,
        .my-sm-2 {
          margin-top: 0.5rem !important;
        }
        .mr-sm-2,
        .mx-sm-2 {
          margin-right: 0.5rem !important;
        }
        .mb-sm-2,
        .my-sm-2 {
          margin-bottom: 0.5rem !important;
        }
        .ml-sm-2,
        .mx-sm-2 {
          margin-left: 0.5rem !important;
        }
        .m-sm-3 {
          margin: 1rem !important;
        }
        .mt-sm-3,
        .my-sm-3 {
          margin-top: 1rem !important;
        }
        .mr-sm-3,
        .mx-sm-3 {
          margin-right: 1rem !important;
        }
        .mb-sm-3,
        .my-sm-3 {
          margin-bottom: 1rem !important;
        }
        .ml-sm-3,
        .mx-sm-3 {
          margin-left: 1rem !important;
        }
        .m-sm-4 {
          margin: 1.5rem !important;
        }
        .mt-sm-4,
        .my-sm-4 {
          margin-top: 1.5rem !important;
        }
        .mr-sm-4,
        .mx-sm-4 {
          margin-right: 1.5rem !important;
        }
        .mb-sm-4,
        .my-sm-4 {
          margin-bottom: 1.5rem !important;
        }
        .ml-sm-4,
        .mx-sm-4 {
          margin-left: 1.5rem !important;
        }
        .m-sm-5 {
          margin: 3rem !important;
        }
        .mt-sm-5,
        .my-sm-5 {
          margin-top: 3rem !important;
        }
        .mr-sm-5,
        .mx-sm-5 {
          margin-right: 3rem !important;
        }
        .mb-sm-5,
        .my-sm-5 {
          margin-bottom: 3rem !important;
        }
        .ml-sm-5,
        .mx-sm-5 {
          margin-left: 3rem !important;
        }
        .m-sm-6 {
          margin: 4.5rem !important;
        }
        .mt-sm-6,
        .my-sm-6 {
          margin-top: 4.5rem !important;
        }
        .mr-sm-6,
        .mx-sm-6 {
          margin-right: 4.5rem !important;
        }
        .mb-sm-6,
        .my-sm-6 {
          margin-bottom: 4.5rem !important;
        }
        .ml-sm-6,
        .mx-sm-6 {
          margin-left: 4.5rem !important;
        }
        .m-sm-7 {
          margin: 6rem !important;
        }
        .mt-sm-7,
        .my-sm-7 {
          margin-top: 6rem !important;
        }
        .mr-sm-7,
        .mx-sm-7 {
          margin-right: 6rem !important;
        }
        .mb-sm-7,
        .my-sm-7 {
          margin-bottom: 6rem !important;
        }
        .ml-sm-7,
        .mx-sm-7 {
          margin-left: 6rem !important;
        }
        .m-sm-8 {
          margin: 9rem !important;
        }
        .mt-sm-8,
        .my-sm-8 {
          margin-top: 9rem !important;
        }
        .mr-sm-8,
        .mx-sm-8 {
          margin-right: 9rem !important;
        }
        .mb-sm-8,
        .my-sm-8 {
          margin-bottom: 9rem !important;
        }
        .ml-sm-8,
        .mx-sm-8 {
          margin-left: 9rem !important;
        }
        .m-sm-9 {
          margin: 12rem !important;
        }
        .mt-sm-9,
        .my-sm-9 {
          margin-top: 12rem !important;
        }
        .mr-sm-9,
        .mx-sm-9 {
          margin-right: 12rem !important;
        }
        .mb-sm-9,
        .my-sm-9 {
          margin-bottom: 12rem !important;
        }
        .ml-sm-9,
        .mx-sm-9 {
          margin-left: 12rem !important;
        }
        .m-sm-10 {
          margin: 18rem !important;
        }
        .mt-sm-10,
        .my-sm-10 {
          margin-top: 18rem !important;
        }
        .mr-sm-10,
        .mx-sm-10 {
          margin-right: 18rem !important;
        }
        .mb-sm-10,
        .my-sm-10 {
          margin-bottom: 18rem !important;
        }
        .ml-sm-10,
        .mx-sm-10 {
          margin-left: 18rem !important;
        }
        .p-sm-0 {
          padding: 0 !important;
        }
        .pt-sm-0,
        .py-sm-0 {
          padding-top: 0 !important;
        }
        .pr-sm-0,
        .px-sm-0 {
          padding-right: 0 !important;
        }
        .pb-sm-0,
        .py-sm-0 {
          padding-bottom: 0 !important;
        }
        .pl-sm-0,
        .px-sm-0 {
          padding-left: 0 !important;
        }
        .p-sm-1 {
          padding: 0.25rem !important;
        }
        .pt-sm-1,
        .py-sm-1 {
          padding-top: 0.25rem !important;
        }
        .pr-sm-1,
        .px-sm-1 {
          padding-right: 0.25rem !important;
        }
        .pb-sm-1,
        .py-sm-1 {
          padding-bottom: 0.25rem !important;
        }
        .pl-sm-1,
        .px-sm-1 {
          padding-left: 0.25rem !important;
        }
        .p-sm-2 {
          padding: 0.5rem !important;
        }
        .pt-sm-2,
        .py-sm-2 {
          padding-top: 0.5rem !important;
        }
        .pr-sm-2,
        .px-sm-2 {
          padding-right: 0.5rem !important;
        }
        .pb-sm-2,
        .py-sm-2 {
          padding-bottom: 0.5rem !important;
        }
        .pl-sm-2,
        .px-sm-2 {
          padding-left: 0.5rem !important;
        }
        .p-sm-3 {
          padding: 1rem !important;
        }
        .pt-sm-3,
        .py-sm-3 {
          padding-top: 1rem !important;
        }
        .pr-sm-3,
        .px-sm-3 {
          padding-right: 1rem !important;
        }
        .pb-sm-3,
        .py-sm-3 {
          padding-bottom: 1rem !important;
        }
        .pl-sm-3,
        .px-sm-3 {
          padding-left: 1rem !important;
        }
        .p-sm-4 {
          padding: 1.5rem !important;
        }
        .pt-sm-4,
        .py-sm-4 {
          padding-top: 1.5rem !important;
        }
        .pr-sm-4,
        .px-sm-4 {
          padding-right: 1.5rem !important;
        }
        .pb-sm-4,
        .py-sm-4 {
          padding-bottom: 1.5rem !important;
        }
        .pl-sm-4,
        .px-sm-4 {
          padding-left: 1.5rem !important;
        }
        .p-sm-5 {
          padding: 3rem !important;
        }
        .pt-sm-5,
        .py-sm-5 {
          padding-top: 3rem !important;
        }
        .pr-sm-5,
        .px-sm-5 {
          padding-right: 3rem !important;
        }
        .pb-sm-5,
        .py-sm-5 {
          padding-bottom: 3rem !important;
        }
        .pl-sm-5,
        .px-sm-5 {
          padding-left: 3rem !important;
        }
        .p-sm-6 {
          padding: 4.5rem !important;
        }
        .pt-sm-6,
        .py-sm-6 {
          padding-top: 4.5rem !important;
        }
        .pr-sm-6,
        .px-sm-6 {
          padding-right: 4.5rem !important;
        }
        .pb-sm-6,
        .py-sm-6 {
          padding-bottom: 4.5rem !important;
        }
        .pl-sm-6,
        .px-sm-6 {
          padding-left: 4.5rem !important;
        }
        .p-sm-7 {
          padding: 6rem !important;
        }
        .pt-sm-7,
        .py-sm-7 {
          padding-top: 6rem !important;
        }
        .pr-sm-7,
        .px-sm-7 {
          padding-right: 6rem !important;
        }
        .pb-sm-7,
        .py-sm-7 {
          padding-bottom: 6rem !important;
        }
        .pl-sm-7,
        .px-sm-7 {
          padding-left: 6rem !important;
        }
        .p-sm-8 {
          padding: 9rem !important;
        }
        .pt-sm-8,
        .py-sm-8 {
          padding-top: 9rem !important;
        }
        .pr-sm-8,
        .px-sm-8 {
          padding-right: 9rem !important;
        }
        .pb-sm-8,
        .py-sm-8 {
          padding-bottom: 9rem !important;
        }
        .pl-sm-8,
        .px-sm-8 {
          padding-left: 9rem !important;
        }
        .p-sm-9 {
          padding: 12rem !important;
        }
        .pt-sm-9,
        .py-sm-9 {
          padding-top: 12rem !important;
        }
        .pr-sm-9,
        .px-sm-9 {
          padding-right: 12rem !important;
        }
        .pb-sm-9,
        .py-sm-9 {
          padding-bottom: 12rem !important;
        }
        .pl-sm-9,
        .px-sm-9 {
          padding-left: 12rem !important;
        }
        .p-sm-10 {
          padding: 18rem !important;
        }
        .pt-sm-10,
        .py-sm-10 {
          padding-top: 18rem !important;
        }
        .pr-sm-10,
        .px-sm-10 {
          padding-right: 18rem !important;
        }
        .pb-sm-10,
        .py-sm-10 {
          padding-bottom: 18rem !important;
        }
        .pl-sm-10,
        .px-sm-10 {
          padding-left: 18rem !important;
        }
        .m-sm-auto {
          margin: auto !important;
        }
        .mt-sm-auto,
        .my-sm-auto {
          margin-top: auto !important;
        }
        .mr-sm-auto,
        .mx-sm-auto {
          margin-right: auto !important;
        }
        .mb-sm-auto,
        .my-sm-auto {
          margin-bottom: auto !important;
        }
        .ml-sm-auto,
        .mx-sm-auto {
          margin-left: auto !important;
        }
      }

      @media (min-width: 992px) {
        .m-lg-0 {
          margin: 0 !important;
        }
        .mt-lg-0,
        .my-lg-0 {
          margin-top: 0 !important;
        }
        .mr-lg-0,
        .mx-lg-0 {
          margin-right: 0 !important;
        }
        .mb-lg-0,
        .my-lg-0 {
          margin-bottom: 0 !important;
        }
        .ml-lg-0,
        .mx-lg-0 {
          margin-left: 0 !important;
        }
        .m-lg-1 {
          margin: 0.25rem !important;
        }
        .mt-lg-1,
        .my-lg-1 {
          margin-top: 0.25rem !important;
        }
        .mr-lg-1,
        .mx-lg-1 {
          margin-right: 0.25rem !important;
        }
        .mb-lg-1,
        .my-lg-1 {
          margin-bottom: 0.25rem !important;
        }
        .ml-lg-1,
        .mx-lg-1 {
          margin-left: 0.25rem !important;
        }
        .m-lg-2 {
          margin: 0.5rem !important;
        }
        .mt-lg-2,
        .my-lg-2 {
          margin-top: 0.5rem !important;
        }
        .mr-lg-2,
        .mx-lg-2 {
          margin-right: 0.5rem !important;
        }
        .mb-lg-2,
        .my-lg-2 {
          margin-bottom: 0.5rem !important;
        }
        .ml-lg-2,
        .mx-lg-2 {
          margin-left: 0.5rem !important;
        }
        .m-lg-3 {
          margin: 1rem !important;
        }
        .mt-lg-3,
        .my-lg-3 {
          margin-top: 1rem !important;
        }
        .mr-lg-3,
        .mx-lg-3 {
          margin-right: 1rem !important;
        }
        .mb-lg-3,
        .my-lg-3 {
          margin-bottom: 1rem !important;
        }
        .ml-lg-3,
        .mx-lg-3 {
          margin-left: 1rem !important;
        }
        .m-lg-4 {
          margin: 1.5rem !important;
        }
        .mt-lg-4,
        .my-lg-4 {
          margin-top: 1.5rem !important;
        }
        .mr-lg-4,
        .mx-lg-4 {
          margin-right: 1.5rem !important;
        }
        .mb-lg-4,
        .my-lg-4 {
          margin-bottom: 1.5rem !important;
        }
        .ml-lg-4,
        .mx-lg-4 {
          margin-left: 1.5rem !important;
        }
        .m-lg-5 {
          margin: 3rem !important;
        }
        .mt-lg-5,
        .my-lg-5 {
          margin-top: 3rem !important;
        }
        .mr-lg-5,
        .mx-lg-5 {
          margin-right: 3rem !important;
        }
        .mb-lg-5,
        .my-lg-5 {
          margin-bottom: 3rem !important;
        }
        .ml-lg-5,
        .mx-lg-5 {
          margin-left: 3rem !important;
        }
        .m-lg-6 {
          margin: 4.5rem !important;
        }
        .mt-lg-6,
        .my-lg-6 {
          margin-top: 4.5rem !important;
        }
        .mr-lg-6,
        .mx-lg-6 {
          margin-right: 4.5rem !important;
        }
        .mb-lg-6,
        .my-lg-6 {
          margin-bottom: 4.5rem !important;
        }
        .ml-lg-6,
        .mx-lg-6 {
          margin-left: 4.5rem !important;
        }
        .m-lg-7 {
          margin: 6rem !important;
        }
        .mt-lg-7,
        .my-lg-7 {
          margin-top: 6rem !important;
        }
        .mr-lg-7,
        .mx-lg-7 {
          margin-right: 6rem !important;
        }
        .mb-lg-7,
        .my-lg-7 {
          margin-bottom: 6rem !important;
        }
        .ml-lg-7,
        .mx-lg-7 {
          margin-left: 6rem !important;
        }
        .m-lg-8 {
          margin: 9rem !important;
        }
        .mt-lg-8,
        .my-lg-8 {
          margin-top: 9rem !important;
        }
        .mr-lg-8,
        .mx-lg-8 {
          margin-right: 9rem !important;
        }
        .mb-lg-8,
        .my-lg-8 {
          margin-bottom: 9rem !important;
        }
        .ml-lg-8,
        .mx-lg-8 {
          margin-left: 9rem !important;
        }
        .m-lg-9 {
          margin: 12rem !important;
        }
        .mt-lg-9,
        .my-lg-9 {
          margin-top: 12rem !important;
        }
        .mr-lg-9,
        .mx-lg-9 {
          margin-right: 12rem !important;
        }
        .mb-lg-9,
        .my-lg-9 {
          margin-bottom: 12rem !important;
        }
        .ml-lg-9,
        .mx-lg-9 {
          margin-left: 12rem !important;
        }
        .m-lg-10 {
          margin: 18rem !important;
        }
        .mt-lg-10,
        .my-lg-10 {
          margin-top: 18rem !important;
        }
        .mr-lg-10,
        .mx-lg-10 {
          margin-right: 18rem !important;
        }
        .mb-lg-10,
        .my-lg-10 {
          margin-bottom: 18rem !important;
        }
        .ml-lg-10,
        .mx-lg-10 {
          margin-left: 18rem !important;
        }
        .p-lg-0 {
          padding: 0 !important;
        }
        .pt-lg-0,
        .py-lg-0 {
          padding-top: 0 !important;
        }
        .pr-lg-0,
        .px-lg-0 {
          padding-right: 0 !important;
        }
        .pb-lg-0,
        .py-lg-0 {
          padding-bottom: 0 !important;
        }
        .pl-lg-0,
        .px-lg-0 {
          padding-left: 0 !important;
        }
        .p-lg-1 {
          padding: 0.25rem !important;
        }
        .pt-lg-1,
        .py-lg-1 {
          padding-top: 0.25rem !important;
        }
        .pr-lg-1,
        .px-lg-1 {
          padding-right: 0.25rem !important;
        }
        .pb-lg-1,
        .py-lg-1 {
          padding-bottom: 0.25rem !important;
        }
        .pl-lg-1,
        .px-lg-1 {
          padding-left: 0.25rem !important;
        }
        .p-lg-2 {
          padding: 0.5rem !important;
        }
        .pt-lg-2,
        .py-lg-2 {
          padding-top: 0.5rem !important;
        }
        .pr-lg-2,
        .px-lg-2 {
          padding-right: 0.5rem !important;
        }
        .pb-lg-2,
        .py-lg-2 {
          padding-bottom: 0.5rem !important;
        }
        .pl-lg-2,
        .px-lg-2 {
          padding-left: 0.5rem !important;
        }
        .p-lg-3 {
          padding: 1rem !important;
        }
        .pt-lg-3,
        .py-lg-3 {
          padding-top: 1rem !important;
        }
        .pr-lg-3,
        .px-lg-3 {
          padding-right: 1rem !important;
        }
        .pb-lg-3,
        .py-lg-3 {
          padding-bottom: 1rem !important;
        }
        .pl-lg-3,
        .px-lg-3 {
          padding-left: 1rem !important;
        }
        .p-lg-4 {
          padding: 1.5rem !important;
        }
        .pt-lg-4,
        .py-lg-4 {
          padding-top: 1.5rem !important;
        }
        .pr-lg-4,
        .px-lg-4 {
          padding-right: 1.5rem !important;
        }
        .pb-lg-4,
        .py-lg-4 {
          padding-bottom: 1.5rem !important;
        }
        .pl-lg-4,
        .px-lg-4 {
          padding-left: 1.5rem !important;
        }
        .p-lg-5 {
          padding: 3rem !important;
        }
        .pt-lg-5,
        .py-lg-5 {
          padding-top: 3rem !important;
        }
        .pr-lg-5,
        .px-lg-5 {
          padding-right: 3rem !important;
        }
        .pb-lg-5,
        .py-lg-5 {
          padding-bottom: 3rem !important;
        }
        .pl-lg-5,
        .px-lg-5 {
          padding-left: 3rem !important;
        }
        .p-lg-6 {
          padding: 4.5rem !important;
        }
        .pt-lg-6,
        .py-lg-6 {
          padding-top: 4.5rem !important;
        }
        .pr-lg-6,
        .px-lg-6 {
          padding-right: 4.5rem !important;
        }
        .pb-lg-6,
        .py-lg-6 {
          padding-bottom: 4.5rem !important;
        }
        .pl-lg-6,
        .px-lg-6 {
          padding-left: 4.5rem !important;
        }
        .p-lg-7 {
          padding: 6rem !important;
        }
        .pt-lg-7,
        .py-lg-7 {
          padding-top: 6rem !important;
        }
        .pr-lg-7,
        .px-lg-7 {
          padding-right: 6rem !important;
        }
        .pb-lg-7,
        .py-lg-7 {
          padding-bottom: 6rem !important;
        }
        .pl-lg-7,
        .px-lg-7 {
          padding-left: 6rem !important;
        }
        .p-lg-8 {
          padding: 9rem !important;
        }
        .pt-lg-8,
        .py-lg-8 {
          padding-top: 9rem !important;
        }
        .pr-lg-8,
        .px-lg-8 {
          padding-right: 9rem !important;
        }
        .pb-lg-8,
        .py-lg-8 {
          padding-bottom: 9rem !important;
        }
        .pl-lg-8,
        .px-lg-8 {
          padding-left: 9rem !important;
        }
        .p-lg-9 {
          padding: 12rem !important;
        }
        .pt-lg-9,
        .py-lg-9 {
          padding-top: 12rem !important;
        }
        .pr-lg-9,
        .px-lg-9 {
          padding-right: 12rem !important;
        }
        .pb-lg-9,
        .py-lg-9 {
          padding-bottom: 12rem !important;
        }
        .pl-lg-9,
        .px-lg-9 {
          padding-left: 12rem !important;
        }
        .p-lg-10 {
          padding: 18rem !important;
        }
        .pt-lg-10,
        .py-lg-10 {
          padding-top: 18rem !important;
        }
        .pr-lg-10,
        .px-lg-10 {
          padding-right: 18rem !important;
        }
        .pb-lg-10,
        .py-lg-10 {
          padding-bottom: 18rem !important;
        }
        .pl-lg-10,
        .px-lg-10 {
          padding-left: 18rem !important;
        }
        .m-lg-auto {
          margin: auto !important;
        }
        .mt-lg-auto,
        .my-lg-auto {
          margin-top: auto !important;
        }
        .mr-lg-auto,
        .mx-lg-auto {
          margin-right: auto !important;
        }
        .mb-lg-auto,
        .my-lg-auto {
          margin-bottom: auto !important;
        }
        .ml-lg-auto,
        .mx-lg-auto {
          margin-left: auto !important;
        }
      }

      @media (min-width: 1200px) {
        .m-xl-0 {
          margin: 0 !important;
        }
        .mt-xl-0,
        .my-xl-0 {
          margin-top: 0 !important;
        }
        .mr-xl-0,
        .mx-xl-0 {
          margin-right: 0 !important;
        }
        .mb-xl-0,
        .my-xl-0 {
          margin-bottom: 0 !important;
        }
        .ml-xl-0,
        .mx-xl-0 {
          margin-left: 0 !important;
        }
        .m-xl-1 {
          margin: 0.25rem !important;
        }
        .mt-xl-1,
        .my-xl-1 {
          margin-top: 0.25rem !important;
        }
        .mr-xl-1,
        .mx-xl-1 {
          margin-right: 0.25rem !important;
        }
        .mb-xl-1,
        .my-xl-1 {
          margin-bottom: 0.25rem !important;
        }
        .ml-xl-1,
        .mx-xl-1 {
          margin-left: 0.25rem !important;
        }
        .m-xl-2 {
          margin: 0.5rem !important;
        }
        .mt-xl-2,
        .my-xl-2 {
          margin-top: 0.5rem !important;
        }
        .mr-xl-2,
        .mx-xl-2 {
          margin-right: 0.5rem !important;
        }
        .mb-xl-2,
        .my-xl-2 {
          margin-bottom: 0.5rem !important;
        }
        .ml-xl-2,
        .mx-xl-2 {
          margin-left: 0.5rem !important;
        }
        .m-xl-3 {
          margin: 1rem !important;
        }
        .mt-xl-3,
        .my-xl-3 {
          margin-top: 1rem !important;
        }
        .mr-xl-3,
        .mx-xl-3 {
          margin-right: 1rem !important;
        }
        .mb-xl-3,
        .my-xl-3 {
          margin-bottom: 1rem !important;
        }
        .ml-xl-3,
        .mx-xl-3 {
          margin-left: 1rem !important;
        }
        .m-xl-4 {
          margin: 1.5rem !important;
        }
        .mt-xl-4,
        .my-xl-4 {
          margin-top: 1.5rem !important;
        }
        .mr-xl-4,
        .mx-xl-4 {
          margin-right: 1.5rem !important;
        }
        .mb-xl-4,
        .my-xl-4 {
          margin-bottom: 1.5rem !important;
        }
        .ml-xl-4,
        .mx-xl-4 {
          margin-left: 1.5rem !important;
        }
        .m-xl-5 {
          margin: 3rem !important;
        }
        .mt-xl-5,
        .my-xl-5 {
          margin-top: 3rem !important;
        }
        .mr-xl-5,
        .mx-xl-5 {
          margin-right: 3rem !important;
        }
        .mb-xl-5,
        .my-xl-5 {
          margin-bottom: 3rem !important;
        }
        .ml-xl-5,
        .mx-xl-5 {
          margin-left: 3rem !important;
        }
        .m-xl-6 {
          margin: 4.5rem !important;
        }
        .mt-xl-6,
        .my-xl-6 {
          margin-top: 4.5rem !important;
        }
        .mr-xl-6,
        .mx-xl-6 {
          margin-right: 4.5rem !important;
        }
        .mb-xl-6,
        .my-xl-6 {
          margin-bottom: 4.5rem !important;
        }
        .ml-xl-6,
        .mx-xl-6 {
          margin-left: 4.5rem !important;
        }
        .m-xl-7 {
          margin: 6rem !important;
        }
        .mt-xl-7,
        .my-xl-7 {
          margin-top: 6rem !important;
        }
        .mr-xl-7,
        .mx-xl-7 {
          margin-right: 6rem !important;
        }
        .mb-xl-7,
        .my-xl-7 {
          margin-bottom: 6rem !important;
        }
        .ml-xl-7,
        .mx-xl-7 {
          margin-left: 6rem !important;
        }
        .m-xl-8 {
          margin: 9rem !important;
        }
        .mt-xl-8,
        .my-xl-8 {
          margin-top: 9rem !important;
        }
        .mr-xl-8,
        .mx-xl-8 {
          margin-right: 9rem !important;
        }
        .mb-xl-8,
        .my-xl-8 {
          margin-bottom: 9rem !important;
        }
        .ml-xl-8,
        .mx-xl-8 {
          margin-left: 9rem !important;
        }
        .m-xl-9 {
          margin: 12rem !important;
        }
        .mt-xl-9,
        .my-xl-9 {
          margin-top: 12rem !important;
        }
        .mr-xl-9,
        .mx-xl-9 {
          margin-right: 12rem !important;
        }
        .mb-xl-9,
        .my-xl-9 {
          margin-bottom: 12rem !important;
        }
        .ml-xl-9,
        .mx-xl-9 {
          margin-left: 12rem !important;
        }
        .m-xl-10 {
          margin: 18rem !important;
        }
        .mt-xl-10,
        .my-xl-10 {
          margin-top: 18rem !important;
        }
        .mr-xl-10,
        .mx-xl-10 {
          margin-right: 18rem !important;
        }
        .mb-xl-10,
        .my-xl-10 {
          margin-bottom: 18rem !important;
        }
        .ml-xl-10,
        .mx-xl-10 {
          margin-left: 18rem !important;
        }
        .p-xl-0 {
          padding: 0 !important;
        }
        .pt-xl-0,
        .py-xl-0 {
          padding-top: 0 !important;
        }
        .pr-xl-0,
        .px-xl-0 {
          padding-right: 0 !important;
        }
        .pb-xl-0,
        .py-xl-0 {
          padding-bottom: 0 !important;
        }
        .pl-xl-0,
        .px-xl-0 {
          padding-left: 0 !important;
        }
        .p-xl-1 {
          padding: 0.25rem !important;
        }
        .pt-xl-1,
        .py-xl-1 {
          padding-top: 0.25rem !important;
        }
        .pr-xl-1,
        .px-xl-1 {
          padding-right: 0.25rem !important;
        }
        .pb-xl-1,
        .py-xl-1 {
          padding-bottom: 0.25rem !important;
        }
        .pl-xl-1,
        .px-xl-1 {
          padding-left: 0.25rem !important;
        }
        .p-xl-2 {
          padding: 0.5rem !important;
        }
        .pt-xl-2,
        .py-xl-2 {
          padding-top: 0.5rem !important;
        }
        .pr-xl-2,
        .px-xl-2 {
          padding-right: 0.5rem !important;
        }
        .pb-xl-2,
        .py-xl-2 {
          padding-bottom: 0.5rem !important;
        }
        .pl-xl-2,
        .px-xl-2 {
          padding-left: 0.5rem !important;
        }
        .p-xl-3 {
          padding: 1rem !important;
        }
        .pt-xl-3,
        .py-xl-3 {
          padding-top: 1rem !important;
        }
        .pr-xl-3,
        .px-xl-3 {
          padding-right: 1rem !important;
        }
        .pb-xl-3,
        .py-xl-3 {
          padding-bottom: 1rem !important;
        }
        .pl-xl-3,
        .px-xl-3 {
          padding-left: 1rem !important;
        }
        .p-xl-4 {
          padding: 1.5rem !important;
        }
        .pt-xl-4,
        .py-xl-4 {
          padding-top: 1.5rem !important;
        }
        .pr-xl-4,
        .px-xl-4 {
          padding-right: 1.5rem !important;
        }
        .pb-xl-4,
        .py-xl-4 {
          padding-bottom: 1.5rem !important;
        }
        .pl-xl-4,
        .px-xl-4 {
          padding-left: 1.5rem !important;
        }
        .p-xl-5 {
          padding: 3rem !important;
        }
        .pt-xl-5,
        .py-xl-5 {
          padding-top: 3rem !important;
        }
        .pr-xl-5,
        .px-xl-5 {
          padding-right: 3rem !important;
        }
        .pb-xl-5,
        .py-xl-5 {
          padding-bottom: 3rem !important;
        }
        .pl-xl-5,
        .px-xl-5 {
          padding-left: 3rem !important;
        }
        .p-xl-6 {
          padding: 4.5rem !important;
        }
        .pt-xl-6,
        .py-xl-6 {
          padding-top: 4.5rem !important;
        }
        .pr-xl-6,
        .px-xl-6 {
          padding-right: 4.5rem !important;
        }
        .pb-xl-6,
        .py-xl-6 {
          padding-bottom: 4.5rem !important;
        }
        .pl-xl-6,
        .px-xl-6 {
          padding-left: 4.5rem !important;
        }
        .p-xl-7 {
          padding: 6rem !important;
        }
        .pt-xl-7,
        .py-xl-7 {
          padding-top: 6rem !important;
        }
        .pr-xl-7,
        .px-xl-7 {
          padding-right: 6rem !important;
        }
        .pb-xl-7,
        .py-xl-7 {
          padding-bottom: 6rem !important;
        }
        .pl-xl-7,
        .px-xl-7 {
          padding-left: 6rem !important;
        }
        .p-xl-8 {
          padding: 9rem !important;
        }
        .pt-xl-8,
        .py-xl-8 {
          padding-top: 9rem !important;
        }
        .pr-xl-8,
        .px-xl-8 {
          padding-right: 9rem !important;
        }
        .pb-xl-8,
        .py-xl-8 {
          padding-bottom: 9rem !important;
        }
        .pl-xl-8,
        .px-xl-8 {
          padding-left: 9rem !important;
        }
        .p-xl-9 {
          padding: 12rem !important;
        }
        .pt-xl-9,
        .py-xl-9 {
          padding-top: 12rem !important;
        }
        .pr-xl-9,
        .px-xl-9 {
          padding-right: 12rem !important;
        }
        .pb-xl-9,
        .py-xl-9 {
          padding-bottom: 12rem !important;
        }
        .pl-xl-9,
        .px-xl-9 {
          padding-left: 12rem !important;
        }
        .p-xl-10 {
          padding: 18rem !important;
        }
        .pt-xl-10,
        .py-xl-10 {
          padding-top: 18rem !important;
        }
        .pr-xl-10,
        .px-xl-10 {
          padding-right: 18rem !important;
        }
        .pb-xl-10,
        .py-xl-10 {
          padding-bottom: 18rem !important;
        }
        .pl-xl-10,
        .px-xl-10 {
          padding-left: 18rem !important;
        }
        .m-xl-auto {
          margin: auto !important;
        }
        .mt-xl-auto,
        .my-xl-auto {
          margin-top: auto !important;
        }
        .mr-xl-auto,
        .mx-xl-auto {
          margin-right: auto !important;
        }
        .mb-xl-auto,
        .my-xl-auto {
          margin-bottom: auto !important;
        }
        .ml-xl-auto,
        .mx-xl-auto {
          margin-left: auto !important;
        }
      }


`
