import React from 'react';
import styled, { css } from 'styled-components';
import Gravatar from 'react-gravatar';

import { getInitials } from '../../utils';

const Container = styled.div`
  color: ${(props) => props.theme.palette[props.color] || props.color};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  img {
    width: 100%;
    height: auto;
  }
  &,
  img {
    border-radius: ${(props) => (props.square ? `none` : '50%')};
  }
  ${(props) =>
    props.border &&
    css`
      border: 1px solid ${props.color};
    `}
`;

const Initials = styled.div`
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.size}px;
`;

export default function Avatar({
  email,
  name,
  color = '#000',
  initials,
  size = 38,
  alt = 'avatar',
  ...props
}) {
  const [noImageAvailable, setNotImageAvailable] = React.useState(!email);
  return (
    <Container color={color} border={noImageAvailable} size={size} {...props}>
      {!noImageAvailable ? (
        <Gravatar
          email={email}
          default="404"
          onError={() => setNotImageAvailable(true)}
          alt={alt}
        />
      ) : (
        <Initials size={size}>{initials || getInitials(name)}</Initials>
      )}
    </Container>
  );
}
