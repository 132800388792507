import React, { useRef, useState } from 'react';

import { toast } from 'react-toastify';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faParachuteBox } from '@fortawesome/free-solid-svg-icons';

import Loading from './Loading';

import Button from '../Button';
import FileIcon from '../FileIcon';
import Typography from '../Typography';

import { humanFileSize } from '../../utils';

export const Container = styled.div`
  input {
    width: 0;
    height: 0;
  }
`;

export const DragArea = styled.div`
  height: ${(props) => props.height || '220'}px;
  display: flex;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  border: 3px dashed #cfd8dc;
  ${(props) =>
    props.drag &&
    css`
      color: ${props.theme.palette.primary};
      border-color: ${props.theme.palette.primary};
      background-color: ${transparentize(0.9, props.theme.palette.primary)};
    `};
  ${(props) =>
    props.fullHeight &&
    css`
      height: 85vh;
    `};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  input {
    width: 0;
    height: 0;
  }
`;

export const ButtonFile = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
    font-size: 16px;
  }
`;

export default function ImageUpload({
  file,
  onFile,
  onCancel,
  button,
  label,
  height,
  fullHeight,
  loading = false,
  loadingValue = 0,
  accept = '*',
  title = 'Carregar imagem',
  limitSize = 5242880, // 5MB
}) {
  const fileRef = useRef();
  const [drag, setDrag] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    fileRef.current.click();
  }

  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function handleDragIn(e) {
    e.preventDefault();
    e.stopPropagation();
    setDrag(true);
  }

  function handleDragOut(e) {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
  }

  function checkFileSize(files) {
    const totalSize = [...files].reduce((total, f) => total + f.size, 0);
    if (totalSize > limitSize) {
      toast(`Arquivo execede o tamanho máximo de ${humanFileSize(limitSize)}.`, {
        autoClose: 3000,
        type: 'error',
      });
      return;
    }
    onFile(files);
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      checkFileSize(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  }

  function handleChangeFile(e) {
    e.preventDefault();
    e.stopPropagation();
    if (fileRef.current.files && fileRef.current.files.length > 0) {
      checkFileSize(fileRef.current.files);
    }
  }

  return (
    <Container
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {!button ? (
        <DragArea height={height} drag={drag} fullHeight={fullHeight}>
          <Content>
            {drag ? (
              <>
                <FontAwesomeIcon color="primary" size="4x" icon={faParachuteBox} />
                <Typography color="primary" variant="body2" gutterBottom="16px">
                  Solte o arquivo aqui
                </Typography>
              </>
            ) : (
              <>
                {!file ? (
                  <>
                    <FontAwesomeIcon color="#CFD8DC" size="4x" icon={faCloudUploadAlt} />
                    <Typography color="g5" variant="body2" gutterBottom="20px">
                      Arraste um arquivo ou
                    </Typography>
                    {loading ? (
                      <Loading value={loadingValue} />
                    ) : (
                      <Button onClick={handleClick} color="default" size="sm" outline>
                        Pesquise
                      </Button>
                    )}
                    <br />
                    <Typography color="g5" variant="body3" gutterBottom="16px">
                      Máximo de 
                      {' '}
                      {humanFileSize(limitSize)}
                      {' '}
                      por arquivo.
                    </Typography>
                  </>
                ) : (
                  <>
                    <FileIcon file={file.name} />
                    <Typography color="g5" variant="body2" gutterBottom="20px">
                      {file.name}
                    </Typography>
                    <Button onClick={onCancel} color="error" size="sm" outline>
                      Cancelar
                    </Button>
                    <br />
                    <Typography color="g5" variant="body3" gutterBottom="16px">
                      Seu aquivo possuí 
                      {' '}
                      {humanFileSize(file.size)}
                      .
                    </Typography>
                  </>
                )}
              </>
            )}
          </Content>
        </DragArea>
      ) : (
        <ButtonFile>
          {label && <span>{label}</span>}
          <Button type="button" onClick={handleClick} color="g5" size="sm" outline>
            {title}
          </Button>
        </ButtonFile>
      )}
      <input onChange={handleChangeFile} ref={fileRef} type="file" accept={accept} />
    </Container>
  );
}
