/* eslint-disable import/no-named-default */
import React from 'react';
import styled, { ThemeConsumer } from 'styled-components'
import { default as SwitchReact } from 'react-switch';

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.g7};
  span {
    margin: 8px;
  }
`;

export default function Switch({label, ...props}) {
  return (
    <ThemeConsumer>
      {theme => (
        <Container>
          <SwitchReact
            height={22}
            width={42}
            onColor={theme.palette.primary}
            offColor={theme.palette.default}
            uncheckedIcon={false}
            checkedIcon={false}
            {...props}
          />
          {label && <span>{label}</span>}
        </Container>
      )}
    </ThemeConsumer>
  );
}
