import React, { useMemo } from 'react';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTint, faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import { useStyle } from '~/contexts/signIn';
import { updateObjectProperty } from '~/utils';

import { Can, Switch, Colors } from '~/components';

import Upload from '../UploadOption';
import Editable from '../EditableOptions';

import { Container, Content, Options, Option, Button } from './styles';

export default function Promotion({ editable }) {
  const { styles, setStyles } = useStyle();

  const optionContentPosition = useMemo(
    () => (styles.rightColumn.promo.styles.position === 'flex-end' ? 'top' : 'bottom'),
    [styles.rightColumn.promo.styles.position],
  );

  function handleBackgroundColorChange(color) {
    const newStyles = updateObjectProperty(styles.rightColumn.styles, 'bgColor', color);

    setStyles({ type: 'RIGHT_COLUMN_STYLES', payload: newStyles });
  }

  function handleBackgroundImageChange(image) {
    const newStyles = updateObjectProperty(styles.rightColumn.styles, 'bgImage', image);

    setStyles({ type: 'RIGHT_COLUMN_STYLES', payload: newStyles });
  }

  function handleBackgroundFileChange(file) {
    const newStyles = updateObjectProperty(styles.rightColumn.styles, 'bgFile', file);

    setStyles({ type: 'RIGHT_COLUMN_STYLES', payload: newStyles });
  }

  function handleVisilityChange(visibility) {
    const newStyles = updateObjectProperty(styles.rightColumn.promo, 'visible', visibility);

    setStyles({ type: 'RIGHT_COLUMN_PROMO_STYLES', payload: newStyles });
  }

  function handleColorChange(color) {
    const newStyles = updateObjectProperty(styles.rightColumn.promo, 'styles.fontColor', color);

    setStyles({ type: 'RIGHT_COLUMN_PROMO_STYLES', payload: newStyles });
  }

  function handlePositionUpChange() {
    const position =
      styles.rightColumn.promo.styles.position === 'flex-end' ? 'center' : 'flex-start';
    const newStyles = updateObjectProperty(styles.rightColumn.promo, 'styles.position', position);

    setStyles({ type: 'RIGHT_COLUMN_PROMO_STYLES', payload: newStyles });
  }

  function handlePositionDownChange() {
    const position =
      styles.rightColumn.promo.styles.position === 'flex-start' ? 'center' : 'flex-end';
    const newStyles = updateObjectProperty(styles.rightColumn.promo, 'styles.position', position);

    setStyles({ type: 'RIGHT_COLUMN_PROMO_STYLES', payload: newStyles });
  }

  function handleTextareaChange(event) {
    const { name, value } = event.target;
    const newStyles = updateObjectProperty(styles.rightColumn.promo, name, value);

    setStyles({ type: 'RIGHT_COLUMN_PROMO_STYLES', payload: newStyles });
  }

  return (
    <Container
      styles={{ ...styles.rightColumn.styles, position: styles.rightColumn.promo.styles.position }}
    >
      <Can renderable={editable}>
        <Options style={{ top: 80, right: 24 }}>
          <Editable icon={faTint}>
            <Colors
              label="Fundo"
              name="background"
              color={styles.rightColumn.styles.bgColor}
              onSelect={handleBackgroundColorChange}
              disableUseEffect
              required
            />
          </Editable>

          <Upload
            onChange={handleBackgroundFileChange}
            onPreviewChange={handleBackgroundImageChange}
            defaultValue={styles.rightColumn.styles.bgImage}
            style={{ marginLeft: 8 }}
          />
        </Options>
      </Can>

      <div>
        <Content styles={{ color: styles.rightColumn.promo.styles.fontColor }}>
          <Can renderable={editable}>
            <Options style={{ top: -48, right: 'calc(50% - 24px)' }}>
              <Editable icon={faCog} position={optionContentPosition}>
                <Option>
                  <span>Exibir texto</span>

                  <Switch
                    checked={styles.rightColumn.promo.visible}
                    onChange={handleVisilityChange}
                  />
                </Option>

                <Option>
                  <Colors
                    name="color"
                    color={styles.rightColumn.promo.styles.fontColor}
                    onSelect={handleColorChange}
                    disableUseEffect
                    required
                  />
                </Option>

                <Option>
                  <span>Posição</span>

                  <div>
                    <Button
                      onClick={handlePositionDownChange}
                      disabled={styles.rightColumn.promo.styles.position === 'flex-end'}
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </Button>

                    <Button
                      onClick={handlePositionUpChange}
                      disabled={styles.rightColumn.promo.styles.position === 'flex-start'}
                    >
                      <FontAwesomeIcon icon={faChevronUp} />
                    </Button>
                  </div>
                </Option>
              </Editable>
            </Options>
          </Can>

          <Can renderable={styles.rightColumn.promo.visible && !editable}>
            <>
              <h1>{styles.rightColumn.promo.title}</h1>
              <p>{styles.rightColumn.promo.subtitle}</p>
            </>
          </Can>

          <Can renderable={styles.rightColumn.promo.visible && editable}>
            <>
              <TextareaAutosize
                name="title"
                className="school-textarea"
                onChange={handleTextareaChange}
                defaultValue={styles.rightColumn.promo.title}
                placeholder="Título da promoção"
              />

              <TextareaAutosize
                name="subtitle"
                onChange={handleTextareaChange}
                className="school-textarea school-input"
                defaultValue={styles.rightColumn.promo.subtitle}
                placeholder="Descrição da promoção"
              />
            </>
          </Can>

          <Can renderable={styles.rightColumn.promo.visible}>
            <a href={styles.rightColumn.promo.anchor.content} target="_blank" rel="noreferrer">
              Saiba mais →
            </a>
          </Can>
        </Content>
      </div>
    </Container>
  );
}
