import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import IconButton from '../IconButton';

const color = '#607D8B';
const colorBorder = '#CFD8DC';

const Container = styled.div`
  > div{
    display: relative;
    height: 23px;
    &:after{
      content: " ";
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      z-index: 0;
      position: absolute;
      transform: translateY(-50%);
      background-color: ${colorBorder};
    }
    input{
      background: transparent;
      font-size: 14px;
      color: #607D8B;
      outline: none;
      padding: 5px 0px;
      background-color: ${(props) => props.bgColor};
      position: absolute;
      z-index: 10;
      &:disabled{
        color: ${darken(0.3,color)} ;
      }
    }
    button{
      z-index: 10;
      position: absolute;
      right: 0;
      top: 0;
      padding: 3px 8px;
      background-color: ${(props) => props.bgColor};
    }
  }
`;

export default function SectionTitleMini({bgColor,edit=false,...props}){
  const disabled = edit === false ? 'disabled' : '';
  return (
    <Container bgColor={bgColor} {...props}>
      <div>
        <input type="text" value="Nome da seção" disabled={disabled} />
        {edit && (
          <IconButton icon={faTimes} />
        )}
      </div>
    </Container>
  );
}
