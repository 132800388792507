import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';

import CreateReplyForm from '../CreateReplyForm';

import { Container, Content, Avatar } from './styles';

export default function CommentItem({ children, user, data, onSubmit }) {
  const [selected, setSelected] = useState(false);

  return (
    <Container>
      <Avatar name={user.name} email={user.email} />

      <Content>
        <strong>
          {user.name}
          <time>{data.created_at}</time>
        </strong>

        <p>{data.comment}</p>

        {selected ? (
          <CreateReplyForm user={user} comment={data} onSubmit={onSubmit} />
        ) : (
          <button type="button" onClick={() => setSelected(true)}>
            <FontAwesomeIcon icon={faReply} />
            Responder
          </button>
        )}

        <hr />

        {children}
      </Content>
    </Container>
  );
}
