import { combineReducers } from 'redux';


import ui from './slices/ui';
import tags from './slices/tags';
import school from './slices/school';
import course from './slices/course';
import classroom from './slices/classroom';
import comments from './slices/comments';

import courses from './slices/courses';
import modules from './slices/modules';
import classes from './slices/classes';
import categories from './slices/categories';
import subscriptions from './slices/subscriptions';

export default combineReducers({
  ui: ui.reducer,
  tags: tags.reducer,
  school: school.reducer,
  course: course.reducer,
  classroom: classroom.reducer,
  comments: comments.reducer,
  courses: courses.reducer,
  modules: modules.reducer,
  classes: classes.reducer,
  categories: categories.reducer,
  subscriptions: subscriptions.reducer,
});
