import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => props.theme.palette[props.color] || props.color}
`;


export default function Icon({...rest}) {
  return (
    <StyledIcon {...rest} />
  )
}
