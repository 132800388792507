import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container } from './styles';

export default function Button({ loading, children, ...props }) {
  return (
    <Container {...props}>
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
    </Container>
  );
}
