import React from 'react'
import styled, { css } from 'styled-components';

const NumberCard = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 28px;
  border-radius: 50%;
  color: ${(props) => props.theme.palette.g10};
  border: 1px solid ${(props) => props.theme.palette.g10};
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  height: 240px;
  background: #fff;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 5px;
  padding: 16px;
  text-align: center;
  cursor: move;
  ${(props) =>
    props.dashed &&
    css`
      box-shadow: none;
      border: 3px dashed #90a4ae;
      cursor: pointer;
    `}
`;

export default function CardOnboarding({number, children, ...props}) {
  return (
    <Card {...props}>
      {number && (<NumberCard>{number}</NumberCard>)}
      {children}
    </Card>
  )
}
