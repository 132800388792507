import React, { useRef, useState, useCallback } from 'react';

import * as Yup from 'yup';

import Brand from '~/layouts/Brand';
import { Input, Button, Paper } from '~/components';

import useAuth from '~/hooks/useAuth';
import getValidationErrors from '~/utils/getValidationErrors';

import { Container, BrandContainer, FormContainer } from './styles';

function SignIn() {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('E-mail deve ser um e-mail válido')
            .required('O E-mail é obrigatório'),
          password: Yup.string()
            .min(8, 'A senha deve conter no mínimo 8 caracteres')
            .required('A senha é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current.setErrors(errors);

          return;
        }

        const message =
          error?.response && error.response.status !== 500
            ? error.response.data.message
            : 'Desculpe, mas ocorreu algum erro ao se conectar com o servidor.';

        console.log(message);
      } finally {
        setLoading(false);
      }
    },
    [signIn],
  );

  return (
    <Container>
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <BrandContainer>
          <Brand />
        </BrandContainer>

        <Paper radius={10}>
          <Input type="email" name="email" label="E-mail" placeholder="" fullWidth />
          <Input type="Password" name="password" label="Senha" placeholder="" fullWidth />

          <Button block>{loading ? 'Acessando..' : 'Acessar'}</Button>
        </Paper>
      </FormContainer>
    </Container>
  );
}

export default SignIn;
