import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme, styles }) => css`
    position: relative;

    display: flex;
    flex-direction: column;

    justify-content: ${styles.position || 'center'};
    align-items: center;

    height: 100%;

    padding: 140px 0;

    background-color: ${styles.bgColor || theme.palette.primary};

    transition: justify-content 0.2s linear, background-image 0.2s linear,
      background-color 0.2s linear;

    ${styles.bgImage &&
    css`
      background-image: url("${styles.bgImage}");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    `}
  `}
`;

export const Content = styled.div`
  ${({ styles }) => css`
    position: relative;

    display: flex;
    flex-direction: column;

    justify-content: center;

    min-width: 300px;

    text-align: center;

    h1,
    p {
      color: #ffffff;
      font-family: 'Open Sans', sans-serif;
    }

    h1,
    .school-textarea {
      margin-bottom: 4px;

      font-weight: bold;

      white-space: pre-wrap;
    }

    p,
    .school-input {
      align-self: center;

      max-width: 60%;
      margin-bottom: 12px;

      font-weight: 400;

      white-space: pre-wrap;
    }

    .school-input,
    .school-textarea {
      align-self: stretch;
      max-width: 100%;

      text-align: center;
    }

    .school-textarea {
      width: 100%;

      padding: 8px;
      border-radius: 4px;

      resize: none;
      outline: none;

      color: ${styles.color || '#ffffff'};
      background-color: transparent;

      font-size: 28px;

      :hover,
      :active,
      :focus {
        background-color: #6c6c6c33;
      }

      ${styles.color &&
      css`
        ::-webkit-input-placeholder {
          color: ${styles.color}80;
        }
      `}
    }

    .school-input {
      font-size: 20px;
    }

    a {
      align-self: center;

      padding: 8px 16px;
      border: 1px solid #ffffff;
      border-radius: 4px;

      color: #ffffff;
      background-color: transparent;

      text-decoration: none;

      :hover {
        opacity: 0.8;

        cursor: pointer;
      }
    }

    ${!!styles.color &&
    css`
      h1,
      p {
        color: ${styles.color};
      }

      a {
        border-color: ${styles.color};

        color: ${styles.color};
      }
    `}
  `}
`;

export const Options = styled.div`
  display: flex;

  position: absolute;

  top: 0;
  right: -48px;
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  :not(:last-child) {
    margin-bottom: 1.8rem;
  }

  .flex-column {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    min-width: 388px;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }
`;

export const Button = styled.button.attrs({ type: 'button' })`
  width: 40px;
  height: 40px;

  background-color: #ffffff;
  border-radius: 4px;

  box-shadow: 0 1px 6px rgb(0 0 0 / 10%);

  & + button {
    margin-left: 8px;
  }

  svg {
    color: #78909c;
    font-size: 16px;
  }

  :hover {
    cursor: pointer;

    svg {
      opacity: 0.8;
    }
  }

  :disabled {
    cursor: not-allowed;
  }
`;
