import React from 'react';
import styled from 'styled-components';

import Select from 'react-select'

const Container = styled.div``;

const options = [
  { value: 'private', label: 'Privado' },
  { value: 'public', label: 'Público' },
  { value: 'notlisted', label: 'Não Listado' }
]

export default function CourseVisibility() {
  return (
    <Container>
      <div>
        <Select options={options} />
      </div>
    </Container>
  );
}
