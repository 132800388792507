import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
  .MuiFormControl-root {
    margin-bottom: ${props => props.noMargin ? 0 : '1.6rem'};
  }
  .MuiFormLabel-asterisk {
    display: none;
  }
`;

export default function Input({ name, label, type, ...rest }) {
  const inputRef = useRef(null);
  const materialInputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.value = value;

        materialInputRef.current
          .querySelector('label')
          .classList.add('MuiFormLabel-filled', 'MuiInputLabel-shrink');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container {...rest}>
      <TextField
        name={fieldName}
        error={!!error}
        ref={materialInputRef}
        helperText={error || null}
        inputRef={inputRef}
        defaultValue={defaultValue}
        label={label}
        data-hj-allow={type !== 'password'}
        type={type}
        {...rest}
        fullWidth
      />
    </Container>
  );
}
