import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import TagManager from '~/utils/TagManager';

import SignIn from '../pages/SignIn';
import Subscribe from '../pages/Subscribe';
import SuperSignIn from '../pages/SuperSignIn';
import ResetPassword from '../pages/ResetPassword';

export default function AuthRoutes() {
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname === 'vdb.aluno.vc' &&
    (window.location.pathname === '/' || window.location.pathname === '/subscribe')
  ) {
    TagManager.initialize({ gtmId: 'GTM-M8ZZQB4' });
  }

  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route path="/admin" component={SuperSignIn} />
      <Route path="/course/:courseId/subscribe" component={Subscribe} />
      <Route path="/password/:token?/:create?" component={ResetPassword} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
