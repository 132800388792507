import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const SkeletonAnimation = keyframes`
  0% {
    background-position: 0px;
  }
  40%, 100% {
    background-position: 250px;
  }
`;

export const Container = styled.div`
  display: block;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.theme.palette[props.color] || 'rgba(0, 0, 0, 0.04)'};
  animation: ${SkeletonAnimation} 1.6s infinite linear;
  margin-bottom: 14px;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  ${(props) =>
    props.circle &&
    css`
      border-radius: 50%;
    `};
  ${(props) =>
    props.loading &&
    css`
      cursor: progress;
    `};
  @media (max-width: 767px) {
    width: ${window.innerWidth - 100}px !important;
  }
`;

export default function Skeleton({
  variant,
  width = '100%',
  height = 0,
  circle = false,
  color,
  loading,
}) {
  return (
    <Container
      variant={variant}
      width={width}
      height={height}
      circle={circle}
      color={color}
      loading={loading}
    />
  );
}
