import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import analytics from '../services/analytics/ga';

export default function Analytics() {
  const school = useSelector((state) => state.school.data);

  useEffect(() => {
    analytics(school?.ga_id || school?.google_analytics);
  }, [school?.ga_id, school?.google_analytics]);

  return null;
}
