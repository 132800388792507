export const defaultSchool = {
  color: {
    primary: '#2BB0EE',
    secondary: '#546E7A',
  },
  brand: {
    menu: '',
    default: '',
    mono: '',
    login: '',
  },
  social: {
    whatsapp: '',
    facebook: '',
    youtube: '',
    instagram: '',
  },
  login: {
    title: 'Entrar',
    subtitle: 'Você pode entrar com seus dados para acessar a plataforma.',
    bgImg: '',
    bgColor: '#fafafa',
    textColor: '#000',
    cardColor: '#fff',
  },
};
