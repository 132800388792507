const coef = 16 / 16;
const pxToRem = ((size) => `${(size / 16) * coef}rem`);

export default {
  title: 'default',
  pxToRem,
  palette: {
    primary: '#2BB0EE',
    secondary: '#546E7A',
    error: '#FF5252',
    warning: '#FBC02D',
    info: '#64b5f6',
    success: '#00C853',
    default: '#B0BEC5',
    light: '#fff',
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      color: '#fff',
      paper: '#fff',
    },
    g1: '#ECEFF1',
    g2: '#CFD8DC',
    g3: '#B0BEC5',
    g4: '#90A4AE',
    g5: '#78909C',
    g6: '#607D8B',
    g7: '#546E7A',
    g8: '#455A64',
    g9: '#37474F',
    g10: '#263238',
  },
  brand: {
    default: '',
    mono: '',
    login: '',
    sidenav: ''
  },
  typography: {
    h1: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(28),
    },
    h2: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(24),
    },
    h3: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(20),
    },
    h4: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(18),
    },
    h5: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(16),
    },
    h6: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 700,
      fontSize: pxToRem(14),
    },
    body1: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(16),
    },
    body2: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(14),
    },
    body3: {
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      fontSize: pxToRem(12),
    }
  },
  shape: {
    borderRadius: 4,
  },
  spacing: {
    margins: {
      small: 10,
      regular: 25,
      medium: 35,
      big: 45,
      massive: 55,
    },
    paddings: {
      small: 10,
      regular: 25,
      medium: 35,
      big: 45,
      massive: 55,
    }
  }
};
