import styled, { css } from 'styled-components';

export const Container = styled.div``;

const imageModifiers = {
  default: css``,

  menu: css`
    height: 46px;
    width: 46px;
  `,

  mono: css`
    max-width: 140px;
  `,
};

export const Image = styled.img`
  ${({ variant }) => css`
    height: auto;

    ${imageModifiers[variant || 'default']}
  `}
`;

export const Logo = styled.div`
  ${({ theme, variant, color }) => css`
    display: grid;
    place-items: center;

    width: 46px;
    height: 46px;

    border: none;
    border-radius: 50%;

    color: ${color || theme.palette.primary};
    background-color: #fff;

    font-size: 1rem;
    font-weight: 700;

    ${variant !== 'menu' &&
    css`
      width: 100%;

      margin-bottom: 50px;
      border-radius: 0;

      background-color: transparent;

      font-size: 1.6rem;
    `}
  `}
`;
