import React from 'react';
import { Switch, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { faBorderAll, faPortalExit } from '@fortawesome/pro-regular-svg-icons';

import useAdmin from '~/hooks/useAuth'

import MainLayout from './MainLayout';
import IconButton from '../components/IconButton';

import SuperDashboard from '../pages/SuperDashboard';

import Brand from './Brand';
import { SideNav, Item } from '../components/SideNav';

export default function SuperLayout() {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const history = useHistory();
  const { signOut } = useAdmin()

  return (
    <MainLayout>
      <SideNav
        brand={
          <Brand name="Viver de blog" onClick={() => history.push(path)} variant="menu" />
        }
      >
        <div>
          <Item onClick={() => history.push(path)}>
            <IconButton active={pathname === '/'} color="#fff" icon={faBorderAll} />
          </Item>
        </div>

        <div className="hide__sm">
          <Item onClick={() => signOut()}>
            <IconButton color="#fff" icon={faPortalExit} />
          </Item>
        </div>
      </SideNav>

      <div className="main">
        <Switch>
          <Route exact path={`${path}`}>
            <SuperDashboard />
          </Route>
        </Switch>
      </div>
    </MainLayout>
  );
}
