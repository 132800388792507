import styled from 'styled-components';

export const Container = styled.nav`
  z-index: 9;

  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 16px 32px;
  border-bottom: 1px solid #cfd8dc;

  background-color: #ffffff;

  button {
    padding: 8px 16px;

    background-color: transparent;

    transition: background 0.2s ease 0s, color 0.2s ease 0s, box-shadow 0.2s ease 0s;

    :hover {
      opacity: 0.8;

      cursor: pointer;
    }

    &.is-back {
      padding: 0;
    }
  }

  aside {
    display: flex;

    div button {
      min-width: 120px;
    }

    button {
      margin-left: 18px;
      border-radius: 4px;

      color: #78909c;
      background-color: #ffffff;

      box-shadow: rgb(0 0 0 / 13%) 0px 0px 3px;

      :hover {
        opacity: 0.8;
      }

      &.is-options {
        min-width: 120px;
      }

      &.is-primary {
        min-width: 90px;

        color: #ffffff;
        background-color: #29b6f6;
      }
    }
  }
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  :not(:last-child) {
    margin-bottom: 1.8rem;
  }

  .flex-column {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    min-width: 388px;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }
`;
