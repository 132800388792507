import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
  nav{
    a{
      color: ${props => props.theme.palette.g6};
      display: block;
      font-size: 16px;
      padding: 5px 0px;
      text-decoration: none;
      position: relative;
      cursor: pointer;
      &.active{
        color: ${props => props.theme.palette.primary};
      }
    }
  }
`;

export default function ItemClass({
  children,
  ...props
}){
  return(
    <Container {...props}>
      <nav>
        {children}
      </nav>
    </Container>
  );
}
