import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => props.zIndex};
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const ModalHeader = styled.div`
  display: flex;
  min-height: 56px;
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${props => props.noBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.2)'};
`;

export const ModalContent = styled.div`
  padding: ${props => props.padding}px;
`;

export const ModalContainer = styled.div`
  width: 100%;
  max-width: ${props => props.width || '680'}px;
  margin: 16px;
`;
