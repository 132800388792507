/* eslint-disable no-multi-assign */
/* eslint-disable no-cond-assign */
/* eslint-disable no-sequences */
/* eslint-disable no-shadow */
/* eslint-disable no-var */
/* eslint-disable one-var */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */

const loadBeacon = () => {
  !(function (e, t, n) {
    function a() {
      var e = t.getElementsByTagName('script')[0],
        n = t.createElement('script');

      (n.type = 'text/javascript'),
        (n.async = !0),
        (n.src = 'https://beacon-v2.helpscout.net'),
        e.parentNode.insertBefore(n, e);
    }

    if (
      ((e.Beacon = n = function (t, n, a) {
        e.Beacon.readyQueue.push({ method: t, options: n, data: a });
      }),
      (n.readyQueue = []),
      t.readyState === 'complete')
    ) {
      return a();
    }

    e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
  })(window, document, window.Beacon || function () {});

  window.Beacon('init', 'fc56ebec-0f8b-4030-9d57-aab87e633203');
};

export default loadBeacon;
