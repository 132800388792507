import React from 'react';
import styled from 'styled-components';

import { getCloudinaryPath } from '~/utils';

const Container = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width * 1.33}px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'normal')};
  background-color: ${(props) => props.color || props.theme.palette.g2};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  ${(props) =>
    props.shadow &&
    `
    box-shadow: 0px 0px 20px #00000063;
  `}
`;

const CoverContent = styled.div`
  display: flex;
  color: #fff;
  padding: 16px;
  height: 100%;
  margin: 0 auto;
  width: ${props => props.coverPosition === 'center' ? '95%' : '100%'};
  position: relative;
  background-repeat: no-repeat;
  flex-direction: column-reverse;
  background-size: contain;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-image: url(${(props) => getCloudinaryPath(props.img)});
  background-position: center;
`;

const CoverBottom = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
`;

const Title = styled.span`
  color: #fff;
  position: absolute;
  top: 35%;
  left: 16px;
  font-size: 24px;
`;

export default function Cover({
  children,
  title,
  img,
  width = 250,
  coverPosition = 'filled',
  justifyContent = 'space-between',
  ...props
}) {
  return (
    <Container width={width} {...props}>
      <CoverContent img={img} coverPosition={coverPosition}>
        {!img && <Title>{title}</Title>}
        <div>
          <CoverBottom justifyContent={justifyContent}>{children}</CoverBottom>
        </div>
      </CoverContent>
    </Container>
  );
}
