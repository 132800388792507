import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100vh;
  min-height: 100vh;
  ${({ styles }) => css`
    ${styles.bgColor &&
    css`
      background-color: ${styles.bgColor};
    `}
    ${styles.bgImage &&
    css`
      background-image: url('${styles.bgImage}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    `}
  `}

  @media (min-width: 768px) {
    display: grid;

    grid-template-columns: ${({ columns }) => columns};
  }
`;

export const Column = styled.div`
  ${({ styles }) => css`
    position: relative;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    height: 100%;

    padding: 20px;

    transition: all 0.2s linear;

    ${styles.bgColor &&
    css`
      background-color: ${styles.bgColor};
    `}

    ${styles.bgImage &&
    css`
      background-image: url('${styles.bgImage}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    `}
  `}
`;

export const Options = styled.div`
  display: flex;

  position: absolute;

  top: 0;
  right: -48px;
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  :not(:last-child) {
    margin-bottom: 1.8rem;
  }

  .flex-column {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    min-width: 388px;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
  }
`;

export const BrandContainer = styled.header`
  position: relative;

  width: 100% !important;
  max-width: 400px;
  min-height: 96px;

  text-align: ${({ styles }) => styles?.textAlign};

  img {
    width: 130px;
    height: 130px;

    object-fit: contain;
  }

  span {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  ${({ styles }) => css`
    position: relative;

    width: 100%;
    max-width: 400px;

    padding: ${styles?.padding ? 32 : 0}px;
    border-radius: ${styles?.padding ? 10 : 0}px;

    background-color: #ffffff;

    transition: padding 0.2s linear, border-radius 0.2s linear, color 0.2s linear,
      background-color 0.2s linear;

    button {
      :hover {
        opacity: 0.8;

        cursor: pointer;
      }
    }

    ${styles?.shadow &&
    css`
      box-shadow: 0 1px 6px rgb(0 0 0 / 10%);
    `}

    ${styles?.fontColor &&
    css`
      h1,
      p {
        color: ${styles?.fontColor} !important;
      }

      .MuiFormLabel-root {
        color: ${styles?.fontColor}${'8a'} !important;
      }

      .MuiInputBase-input,
      .MuiFormLabel-root.Mui-focused {
        color: ${styles?.fontColor} !important;
      }

      .MuiInput-underline::before {
        border-bottom-color: ${styles?.fontColor}${'8a'} !important;
      }

      .MuiInput-underline::after {
        border-bottom-color: ${styles?.fontColor} !important;
      }
    `}

    ${styles?.bgColor &&
    css`
      background-color: ${styles?.bgColor};
    `}
  `}
`;

export const Header = styled.header`
  ${({ styles }) => css`
    h1,
    p {
      color: ${styles?.fontColor || 'rgba(0, 0, 0, 0.87)'};

      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
    }

    h1,
    .school-textarea {
      margin-bottom: 16px;

      font-size: 1.75rem;

      white-space: pre-wrap;
    }

    p,
    .school-input {
      margin-bottom: 32px;

      font-size: 0.875rem;

      white-space: pre-wrap;
    }

    .school-textarea {
      width: 100%;

      padding: 8px;
      border-radius: 4px;

      resize: none;
      outline: none;

      color: ${styles?.fontColor || 'rgba(0, 0, 0, 0.87)'};
      background-color: transparent;

      :hover,
      :active,
      :focus {
        background-color: #6c6c6c33;
      }

      ${styles?.fontColor &&
      css`
        ::-webkit-input-placeholder {
          color: ${styles?.fontColor}80;
        }
      `}
    }
  `}
`;

export const Footer = styled.footer`
  ${({ theme, styles }) => css`
    display: flex;
    justify-content: space-between;

    margin-top: 32px;

    a {
      color: ${styles.fontColor || theme.palette.primary};

      font-size: 14px;
      font-weight: 400;

      text-decoration: none;

      :hover {
        cursor: pointer;

        text-decoration: underline;
      }
    }
  `}
`;
