import axios from 'axios';

function createAuthService(http) {
  const setToken = (token) => {
    http.setToken(token);
  };
  const signIn = async (credentials) => {
    const { data } = await axios.post(
      'https://core-production-v3.membrosapollo.com/auth',
      credentials,
    );
    await http.patch('/user', {
      id: data.user_data.id,
      sessionDate: new Date(Date.now()),
      token: data.token,
      user_data: data.user_data,
    });
    if (data.token) setToken(data.token);

    return data;
  };

  const validateToken = async (token) => {
    const data = await http.post('/token', { token });
    return data.status === 200;
  };

  const resetPassword = async (credentials) => {
    return http.post('/auth', credentials);
  };

  const updatePassword = async (credentials) => {
    return http.post('/auth', credentials);
  };

  return {
    signIn,
    validateToken,
    resetPassword,
    updatePassword,
    setToken,
  };
}

export default createAuthService;
