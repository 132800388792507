import React from 'react';
import saveAs from 'file-saver';
import styled from 'styled-components';
import { faTimes, faMicrophone } from '@fortawesome/free-solid-svg-icons';

import Button from '~/components/Button';
import IconButton from '~/components/IconButton';
import List from '~/components/List';
import Item from '~/components/Item';
import FileIcon from '~/components/FileIcon';

import { getCloudinaryPath, humanFileSize, getFileName } from '~/utils';

export const Container = styled.div`
  margin-bottom: 16px;
  @media (max-width: 768px) {
    Button {
      display: initial !important;
    }
  }
`;

export default function FileList({ files = [], legacyId, onRemove, audioId, onAudioSelect }) {
  function handleDownload(item) {
    saveAs(getCloudinaryPath(item.file), getFileName(item.file));
  }

  function handleDownloadAll() {
    files.forEach((item) => {
      saveAs(getCloudinaryPath(item.file), getFileName(item.file));
    });
  }

  const courseFiles = React.useMemo(() => {
    return files?.map((item) => {
      if (item.file.indexOf('sites/') !== -1 && legacyId) {
        const newUrl = item.file.replace(/(sites?\/)(.*?)(\/)/g, `sites/${legacyId}/`);
        return { ...item, file: newUrl };
      }
      return item;
    });
  }, [files, legacyId]);

  return (
    <Container>
      {courseFiles?.length > 0 ? (
        <>
          <List>
            {courseFiles?.map((item) => (
              <Item
                key={item.id}
                primaryText={item.title}
                subTitle={humanFileSize(item.description)}
                contentLeft={<FileIcon file={item.file} />}
                audio={audioId === item.id}
                hovercontentRight
                noMargin
                separator
                contentRight={(
                  <>
                    {!onRemove ? (
                      <Button onClick={() => handleDownload(item)} color="g5" size="sm" outline>
                        Baixar
                      </Button>
                    ) : (
                      <>
                        {item.file.includes('.mp3') && (
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              onAudioSelect(item.id);
                            }}
                            color={audioId === item.id ? 'success' : 'default'}
                            icon={faMicrophone}
                          />
                        )}
                        <IconButton
                          onClick={() => onRemove(item.id)}
                          color="error"
                          icon={faTimes}
                        />
                      </>
                    )}
                  </>
                )}
              />
            ))}
          </List>
          <br />
          {!onRemove && (
            <Button onClick={handleDownloadAll} color="g5" size="sm" outline>
              Baixar todos os arquivos
            </Button>
          )}
        </>
      ) : (
        <p>Nenhum material para essa aula.</p>
      )}
    </Container>
  );
}
