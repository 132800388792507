import React from 'react';
import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';

export const SelectStyle = styled.div`
  flex: 1;
  margin-bottom: 16px;
  & > span {
    font-size: 12px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.palette.g5};
  }
  ${(props) =>
    !props.border &&
    css`
      .react-select__control {
        background-color: transparent;
        border: none;
        border-bottom: 0.2px solid #b0bec5;
        border-radius: 0px;
        font-size: 18px;
        box-shadow: none !important;
      }
      .react-select__option--is-selected {
        background-color: ${props.theme.palette.primary};
      }
      .react-select__control:hover {
        border-color: ${props.theme.palette.primary};
      }
      .react-select__control--is-focused {
        border-color: ${props.theme.palette.primary};
      }
      .react-select__indicator-separator {
        background-color: transparent;
      }
      .react-select__placeholder {
        margin: 0px;
      }
      .react-select__value-container {
        padding: 2px 0px;
      }
    `}
`;

const Select = React.forwardRef(({ label, border, ...props }, ref) => (
  <SelectStyle border={border}>
    {label && <span>{label}</span>}
    <ReactSelect
      ref={ref}
      classNamePrefix="react-select"
      noOptionsMessage={() => 'Nenhuma opção'}
      {...props}
    />
  </SelectStyle>
));

export default Select;
