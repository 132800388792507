/* eslint-disable react/no-danger */
import React, { useState, useEffect, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faTrashAlt,
  faInfoCircle,
  faCalendarAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import api from '~/services/http';
import { formatDate, formatTime, formatContent } from '~/utils/format';

import CreateAnnotationForm from './CreateAnnotationForm';

import { Container, Content, Annotation } from './styles';

export default function Annotations({ classId, playedSeconds }) {
  const [notes, setNotes] = useState([]);
  const [sortBy, setSortBy] = useState(0);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedNoteText, setSelectedNoteText] = useState('');

  const handleSelectNote = useCallback(
    (id, note) => {
      if (id !== selectedNote) {
        setSelectedNote(id);
        setSelectedNoteText(note);
      }
    },
    [selectedNote],
  );

  const handleUpdateNote = useCallback(
    async (noteId) => {
      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === noteId
            ? { ...note, text: selectedNoteText, text_formatted: formatContent(selectedNoteText) }
            : note,
        ),
      );

      setSelectedNote(null);

      await api.put('notes', { id: noteId, text: selectedNoteText });
    },
    [selectedNoteText],
  );

  const handleRemoveNote = useCallback(async (noteId) => {
    setNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));

    await api.delete(`notes?id=${noteId}`);
  }, []);

  useEffect(() => {
    setNotes([]);
  }, [classId]);

  useEffect(() => {
    api.get(`notes?class=${classId}&asc=${sortBy}`).then((response) => {
      if (response.status === 200) {
        setNotes(
          response.data.map((note) => ({
            ...note,
            text_formatted: formatContent(note.text),
            time_at: formatTime(note.seconds),
            created_at: formatDate(note.born),
          })),
        );
      }
    });
  }, [classId, sortBy]);

  return (
    <Container>
      <header>
        <CreateAnnotationForm classId={classId} playedSeconds={playedSeconds} setNotes={setNotes} />
      </header>

      <Content>
        <header>
          <button type="button" onClick={() => setSortBy((prevState) => (prevState === 1 ? 0 : 1))}>
            {sortBy === 1 ? 'Antigos primeiro' : 'Recentes primeiro'}
            <FontAwesomeIcon icon={faSort} />
          </button>

          <button type="button">
            <FontAwesomeIcon icon={faInfoCircle} />
            Dicas de formatação
            <span>
              **
              <strong>negrito</strong>
              **
              <br />
              ´
              <i>itálico</i>
              ´
              <br />
              ~
              <strike>riscado</strike>
              ~
            </span>
          </button>
        </header>

        <section>
          {notes.map((note) => (
            <Annotation
              key={`note-${note.id}`}
              onClick={() => handleSelectNote(note.id, note.text)}
              selected={selectedNote === note.id}
            >
              <div>
                <time>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  {note.created_at === 'poucos segundos'
                    ? note.created_at
                    : `${note.created_at} atrás`}
                </time>
                <time>
                  <FontAwesomeIcon icon={faClock} />
                  {note.time_at}
                </time>
              </div>

              <div>
                {selectedNote === note.id ? (
                  <TextareaAutosize
                    value={selectedNoteText}
                    onChange={(event) => setSelectedNoteText(event.target.value)}
                  />
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: note.text_formatted }} />
                )}

                {selectedNote === note.id && (
                  <footer>
                    <span>
                      <button type="button" onClick={() => handleUpdateNote(note.id)}>
                        Salvar
                      </button>
                    </span>

                    <span>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        onClick={() => handleRemoveNote(note.id)}
                      />
                    </span>
                  </footer>
                )}
              </div>
            </Annotation>
          ))}

          {notes.length === 0 && <p>Você não possui anotações nessa aula :(</p>}
        </section>
      </Content>
    </Container>
  );
}
