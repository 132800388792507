import React from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Paper from '../Paper';
import Typography from '../Typography';
import IconButton from '../IconButton';
import { Container, ModalContainer, ModalContent, ModalHeader } from './styles';

export default function Modal({
  children,
  title,
  show,
  onClose,
  width,
  contentHeader,
  noBorder,
  padding = 18,
  zIndex = 1001,
}) {
  return (
    <>
      {show && (
        <Container zIndex={zIndex}>
          <ModalContainer onClick={(event) => event.stopPropagation()} width={width}>
            <Paper padding={0}>
              <ModalHeader noBorder={noBorder}>
                {title && <Typography variant="h3">{title}</Typography>}
                {contentHeader}
                <IconButton onClick={onClose} icon={faTimes} color="g8" />
              </ModalHeader>
              <ModalContent padding={padding}>{children}</ModalContent>
            </Paper>
          </ModalContainer>
        </Container>
      )}
    </>
  );
}
