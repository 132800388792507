import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_APOLLO_API || 'https://core-staging.membrosapollo.com',
});

api.interceptors.request.use(
  config => new Promise(resolve => setTimeout(() => resolve(config), 600)),
);

export default api;
