import React from 'react'
import styled from 'styled-components';

export const Drawer = styled.div`
  display: flex;
  flex-flow: column;
  width: 300px;
  min-height: 100vh;
  background: #eceff1;
  padding: 26px 36px;
  .menu {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export default ({children}) => {
  return (
    <Drawer>
      {children}
    </Drawer>
  )
}
