import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled(({ component, ...props }) => React.createElement(component, props))`
  flex: 1;
  color: ${(props) => props.theme.palette[props.color] || props.theme.palette.text.primary};
  font-family: ${({ variant, theme }) => theme.typography[variant].fontFamily};
  font-size: ${({ variant, theme }) => theme.typography[variant].fontSize};
  font-weight: ${({ variant, theme }) => theme.typography[variant].fontWeight};
  ${(props) =>
    props.gutterBottom &&
    css`
      margin-bottom: ${props.gutterBottom ? props.gutterBottom : '16px'};
    `}
    ${(props) =>
    props.gutterTop &&
    css`
      margin-top: ${props.gutterTop ? props.gutterTop : '16px'};
    `}
  svg {
    margin-right: 8px;
  }
`;

const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'p',
  body2: 'p',
  body3: 'p',
};

export default function Typography({
  children,
  component,
  paragraph = false,
  variant = 'body1',
  variantMapping = defaultVariantMapping,
  ...props
}) {
  // const WithComponent = Container.withComponent(Component);

  return (
    <Container
      component={
        component ||
        (paragraph ? 'p' : variantMapping[variant] || defaultVariantMapping[variant]) ||
        'span'
      }
      variant={variant}
      {...props}
    >
      {children}
    </Container>
  );
}
