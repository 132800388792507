import styled from 'styled-components';

import BaseAvatar from '~/components/Avatar';

export const Container = styled.form`
  display: flex;
  flex-direction: column;

  > div {
    :first-of-type {
      display: flex;

      textarea {
        flex: 1;
        min-height: 75px;

        padding: 12px;
        border: 1px solid #b0bec5;
        border-radius: 4px;

        resize: none;
        outline: none;

        line-height: 22px;

        font-size: 16px;

        ::-webkit-input-placeholder {
          color: #90a4ae !important;
        }

        :-moz-placeholder {
          color: #90a4ae !important;
        }

        ::-moz-placeholder {
          color: #90a4ae !important;
        }

        :-ms-input-placeholder {
          color: #90a4ae !important;
        }
      }
    }

    :last-of-type {
      display: flex;
      justify-content: flex-end;

      margin-top: 16px;

      button {
        height: 32px;

        padding: 0 20px;

        border: 1px solid #2bb0ee;
        border-radius: 4px;

        outline: none;

        color: #ffffff;
        background-color: #2bb0ee;

        font-size: 12px;

        box-shadow: 0px 0px 3px #00000021;

        :hover,
        :disabled {
          filter: brightness(0.9);
        }

        :hover {
          cursor: pointer;
        }

        :disabled {
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const Avatar = styled(BaseAvatar)`
  display: block;

  width: 40px;
  height: 40px;

  margin-right: 16px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
`;
