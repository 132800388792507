import React from 'react';
import styled from 'styled-components';

const Container = styled.span`
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  background: ${(props) => props.theme.palette[props.color] || props.color};
`;

export default ({ label, color = 'primary', ...props }) => (
  <Container color={color} {...props}>
    {label}
  </Container>
);
