import styled, { css } from 'styled-components';

export const Container = styled.div`
  @media print {
    .print__hidden {
      margin: 0;
      display: none;
    }
  }
`;

export const BackgroundImage = styled.div`
  z-index: -1;
  position: absolute;
  width: 297mm;
  min-height: 210mm;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const CertificateBody = styled.div`
  color: #434a54;
  position: relative;

  & {
    width: 297mm;
    min-height: 210mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  ${(props) =>
    props.model === 'custom' &&
    css`
      color: ${props.color || '#434a54'};
      .signature img {
        display: none !important;
      }
      .time {
        display: none !important;
      }
      .footer {
        margin-top: ${props.styles.footerMarginTop}mm;
        .col.date {
          text-align: ${props.dateAlign} !important;
        }
      }
    `}

  .paper {
    width: 100%;
    height: 206mm;
    outline: 2cm white solid;
    border: 5px solid ${(props) => props.borderColor};
    position: relative;
    border-image-slice: 1;

    ${(props) =>
      props.background &&
      props.model === 'custom' &&
      css`
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
      `}

    /* MARCAS */

    .brand {
      width: 45mm;
      height: 25mm;
      display: block;
      margin: auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      margin-bottom: 20mm;
      padding-top: 5mm;

      img {
        width: auto;
        height: auto;
        margin: auto;
        display: block;
        max-width: 100%;
      }
    }

    .infos {
      padding-top: ${props => props.styles.infoMarginTop}mm;
      h1 {
        font-size: 10mm;
        text-align: center;
        font-weight: 700;
      }
      h3 {
        font-size: 5.5mm;
        font-weight: 400;
        text-align: center;
        margin: 2.5mm 0px;
      }
    }
  }

  .signature {
    width: 45mm;
    height: 48mm;
    margin: auto;
    display: grid;
    place-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }

  /* RODAPÉ */

  .footer {
    display: flex;
    grid-gap: 5%;
    font-weight: 400;
    font-size: 4mm;
    padding: 1cm;
    img {
      width: 24mm;
      height: auto;
      display: block;
      margin: auto;
    }
  }

  .footer .footer .col.time {
    text-align: left;
  }

  .footer .col.date {
    text-align: right;
  }

  .col {
    flex: 1;
  }

  /* IMPRESSÃO */

  @page {
    size: A4;
    margin: 0;
  }

  @media print {
    @page {
      size: landscape;
    }
    html,
    body {
      height: 297mm;
      width: 210mm;
    }
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
`;
