import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';

const Container = styled.div`
  color: ${(props) => (props.color === '' ? 'black' : props.color)};
  width: 100%;
  strong{
    margin-right: 8px;
  }
`;

export default function Tip({ children, title = false, text = '', ...props }) {
  return (
    <Container title={title} text={text} {...props}>
      <div>
        <Typography variant="body2">
          {title && (
            <strong>{title}</strong>
          )}
          {text}
        </Typography>
      </div>
    </Container>
  );
}
